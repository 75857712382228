import moment from "moment-timezone";
import * as React from "react";
import { createPortal } from "react-dom";
import { DatePickerBase } from "./DatePicker";
import type { Moment } from "moment-timezone";
import type { ButtonSize } from "antd/es/button";
import { IS_DEPLOYMENT_ENV_TEST } from "../../envVariables";
import { formatStrings } from "../../utils/time";
import { Eye, EyeOff } from "react-feather";
import { NoUndefinedRangeValueType } from "rc-picker/lib/PickerInput/RangePicker";

export type RangePickerPresets = { label: string; value: [moment.Moment | null, moment.Moment | null] }[];

export const RangePickerBase = DatePickerBase.RangePicker;

const STORAGE_KEY_RANGE_PICKER_DEBUG = "STORAGE_KEY_RANGE_PICKER_DEBUG";

interface Props {
  fromISO: string | null;
  setFromISO?: (value: string | null) => void;
  toISO: string | null;
  setToISO?: (value: string | null) => void;
  utc?: boolean;
  timezoneValue: string;
  // from rangePicker
  presets?: RangePickerPresets;
  style?: React.CSSProperties;
  className?: string;
  size: ButtonSize;
  showTime?: boolean;
  allowClear?: boolean;
  clearIcon?: boolean;
  showHour?: boolean;
  showMinute?: boolean;
  showSecond?: boolean;
  allowEmpty?: [boolean, boolean];
  disabled?: [boolean, boolean];
  placeholder?: [string, string];
}
export const RangePicker: React.FC<Props> = React.memo(
  function RangePicker({ fromISO, setFromISO, toISO, setToISO, utc, timezoneValue, ...rest }) {
    function getDebug() {
      return (localStorage.getItem("CPLN_DEBUG") || "") === "true";
    }
    const [_debug] = React.useState(getDebug());
    const debug = IS_DEPLOYMENT_ENV_TEST || _debug;
    const debugElRef = React.useRef(null as any);
    React.useEffect(() => {
      const el = document.getElementById("debug");
      if (el) {
        debugElRef.current = el;
      }
    }, []);

    function getDefaultShowDebug() {
      const localValue = localStorage.getItem(STORAGE_KEY_RANGE_PICKER_DEBUG);
      if (!localValue) {
        return true;
      }
      return localValue === "true" ? true : false;
    }
    const [showDebug, setShowDebug] = React.useState(getDefaultShowDebug);
    React.useEffect(() => {
      localStorage.setItem(STORAGE_KEY_RANGE_PICKER_DEBUG, String(showDebug));
    }, [showDebug]);

    const debugEl = debugElRef.current;

    function getFrom() {
      if (!fromISO) {
        return null;
      }
      return moment(fromISO);
    }
    const [from, setFrom] = React.useState<Moment | null>(getFrom());
    React.useEffect(() => {
      setFrom(getFrom());
    }, [fromISO]);
    React.useEffect(() => {
      if (from) {
        setFrom(moment(from.toISOString()));
      }
    }, [timezoneValue]);

    function getTo() {
      if (!toISO) {
        return null;
      }
      return moment(toISO);
    }
    const [to, setTo] = React.useState<Moment | null>(getTo());
    React.useEffect(() => {
      setTo(getTo());
    }, [toISO]);
    React.useEffect(() => {
      if (to) {
        setTo(moment(to.toISOString()));
      }
    }, [timezoneValue]);

    React.useEffect(() => {
      if (!setFromISO) {
        return;
      }
      if (!from) {
        setFromISO(null);
        return;
      }
      setFromISO(from.toISOString());
    }, [from]);

    React.useEffect(() => {
      if (!setToISO) {
        return;
      }
      if (!to) {
        setToISO(null);
        return;
      }
      setToISO(to.toISOString());
    }, [to]);

    function handleChange(dates: NoUndefinedRangeValueType<Moment> | null, dateStrings: [string, string]) {
      if (!dates) {
        setFrom(null);
        setTo(null);
        return;
      }
      setFrom(dates[0]);
      setTo(dates[1]);
    }

    if (debug && !debugEl) {
      console.error("Range Picker wants to show debug but there is no debug element");
    }
    const debugInfos: { label: string; value?: string | null; skip?: boolean }[] = [
      {
        label: "timezone - moment default",
        value: timezoneValue,
      },
      {
        label: "fromISO",
        value: fromISO,
      },
      {
        label: "from to iso",
        value: from?.toISOString(),
        skip: !from,
      },
      {
        label: "from default format",
        value: from?.format(),
        skip: !from,
      },
      {
        label: "from format",
        value: from?.format(formatStrings.min),
        skip: !from,
      },
      {
        label: "toISO",
        value: toISO,
      },
      {
        label: "to to iso",
        value: to?.toISOString(),
        skip: !to,
      },
      {
        label: "to default format",
        value: to?.format(),
        skip: !to,
      },
      {
        label: "to format",
        value: to?.format(formatStrings.min),
        skip: !to,
      },
    ];

    return (
      <>
        {debug && debugEl
          ? createPortal(
              <div
                style={{
                  backgroundColor: "white",
                  paddingBlock: "0.25rem",
                  paddingInline: "0.5rem",
                  border: "1px solid var(--color-border)",
                  color: "gray",
                }}
                className="flex flex-col gap-1 text-xs"
              >
                <div className="flex items-center justify-between">
                  <span>RangePicker Debug</span>
                  {showDebug ? (
                    <button onClick={() => setShowDebug(false)}>
                      <EyeOff className="h-4" />
                    </button>
                  ) : (
                    <button onClick={() => setShowDebug(true)}>
                      <Eye className="h-4" />
                    </button>
                  )}
                </div>
                {showDebug
                  ? debugInfos.map((debugInfo, index) => {
                      if (debugInfo.skip) {
                        return null;
                      }
                      const debugValue =
                        debugInfo.value === null
                          ? "null"
                          : debugInfo.value === undefined
                          ? "undefined"
                          : debugInfo.value || `""`;
                      return (
                        <div key={index}>
                          <div>{debugInfo.label}</div>
                          <div>{debugValue}</div>
                        </div>
                      );
                    })
                  : null}
              </div>,
              debugEl,
            )
          : null}
        <RangePickerBase value={[from, to]} onChange={handleChange} {...rest} />
      </>
    );
  },
  (prevProps, nextProps) => {
    const {
      fromISO: prevFromISO,
      setFromISO: prevSetFromISO,
      toISO: prevToISO,
      setToISO: prevSetToISO,
      utc: prevUtc,
      timezoneValue: prevTimezoneValue,
      presets: prevPresets,
      style: prevStyle,
      className: prevClassName,
      size: prevSize,
      showTime: prevShowTime,
      allowClear: prevAllowClear,
      clearIcon: prevClearIcon,
      showHour: prevShowHour,
      showMinute: prevShowMinute,
      showSecond: prevShowSecond,
      allowEmpty: prevAllowEmpty,
      disabled: prevDisabled,
      placeholder: prevPlaceholder,
    } = prevProps;
    const {
      fromISO: nextFromISO,
      setFromISO: nextSetFromISO,
      toISO: nextToISO,
      setToISO: nextSetToISO,
      utc: nextUtc,
      timezoneValue: nextTimezoneValue,
      presets: nextPresets,
      style: nextStyle,
      className: nextClassName,
      size: nextSize,
      showTime: nextShowTime,
      allowClear: nextAllowClear,
      clearIcon: nextClearIcon,
      showHour: nextShowHour,
      showMinute: nextShowMinute,
      showSecond: nextShowSecond,
      allowEmpty: nextAllowEmpty,
      disabled: nextDisabled,
      placeholder: nextPlaceholder,
    } = nextProps;

    if (
      prevFromISO !== nextFromISO ||
      prevToISO !== nextToISO ||
      prevUtc !== nextUtc ||
      prevTimezoneValue !== nextTimezoneValue ||
      prevStyle !== nextStyle ||
      prevClassName !== nextClassName ||
      prevSize !== nextSize ||
      prevDisabled !== nextDisabled
    ) {
      return false;
    }

    return true;
  },
);
