import { WorkloadTableItem } from "../../../../schema/types/workload/workloadTableItem";
import { TableColumn } from "../../types";

const metricDefault = { id: "metric", label: "Metric" };
export function WorkloadMetricColumn<TData extends WorkloadTableItem>(column: TableColumn<TData> = metricDefault) {
  const preset: TableColumn<TData> = {
    ...metricDefault,
    enableHide: true,
    hiddenByDefault: true,
    enableSort: true,
    size: 130,
    cell: (p) => {
      const workload = p.row.original;
      const metric = workload.metric;

      let text = "";
      switch (metric) {
        case "concurrency":
          text = "Concurrency";
          break;
        case "cpu":
          text = "CPU";
          break;
        case "memory":
          text = "Memory";
          break;
        case "disabled":
          text = "Disabled";
          break;
        case "latency":
          text = "Latency";
          break;
        case "rps":
          text = "Req. Per Sec.";
          break;
        default:
          break;
      }

      return <span>{text}</span>;
    },
  };
  return Object.assign(preset, column);
}
