import * as React from "react";
import { Route, Navigate, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Task from "./pages/task";
import UsageRoute from "./pages/usage-ng";
import OrgRoute from "./pages/org";
import BillingRoute from "./pages/billing";
import GVCRoute from "./pages/gvc";
import WorkloadRoute from "./pages/workload";
import VolumeSetRoute from "./pages/volumeset";
import IdentityRoute from "./pages/identity";
import LocationRoute from "./pages/location";
import Mk8sRoute from "./pages/mk8s";
import AgentRoute from "./pages/agent";
import SecretRoute from "./pages/secret";
import QuotaRoute from "./pages/quota";
import CloudaccountRoute from "./pages/cloudaccount";
import DomainRoute from "./pages/domain";
import IpSetRoute from "./pages/ipset";
import RepositoryRoute from "./pages/repository";
import ImageRoute from "./pages/image";
import AuditTrailRoute from "./pages/auditTrail";
import LogsRoute from "./pages/logs";
import AuditContextRoute from "./pages/auditContext";
import UserRoute from "./pages/user";
import PolicyRoute from "./pages/policy";
import ServiceaccountRoute from "./pages/serviceaccount";
import EducationalVideosRoute from "./pages/educationalVideos";
import GroupRoute from "./pages/group";
import HelmReleasesRoute from "./pages/helmReleases";
import MarketplaceRoute from "./pages/marketplace/route";
import { emitterGVC, emitterGVCContext } from "./emitters/gvc";
import { PrivateRoute } from "./privateRoute";
import { Modal, notification } from "antd";
import { observer } from "mobx-react-lite";
import { ConsoleRoute } from "./pages/console";
import { NMainHeightContext, useInitNMainHeight } from "./reactContexts/nMainHeightContext";
import { UserData } from "./mobxStores/userData/userData";
import { ConsoleContext } from "./mobxStores/consoleContext/consoleContext";
import { TokenChecker } from "./mobxStores/token/tokenChecker";
import { Theme } from "./mobxStores/uiData/theme";
import { GlobalElement } from "./globalElement";
import { Redirect } from "./redirect";
import { getTerraformSupportedKinds } from "./services/cpln";
import { UIData } from "./mobxStores/uiData/uiData";
import { CPLNApply } from "./pages/cplnapply";
import { Timezone } from "./mobxStores/userData/timezone";
import { PrivateRouteDelayed } from "./privateRouteDelayed";
import { IPSET_ENABLED } from "./envVariables";
import { TriggerSentry } from "./pages/triggerSentry";

const HandleRootRedirectRaw: React.FC = () => {
  const { hasOrg, hasOrgInvite } = UserData;

  if (UIData.initialUrl.includes("/task/")) {
    return <Navigate to={UIData.initialUrl} />;
  }

  if (!hasOrg && hasOrgInvite) {
    return <Navigate to={`/task`} />;
  }

  return <Navigate to={"/console"} />;
};

const HandleRootRedirect = observer(HandleRootRedirectRaw);

const AppRaw: React.FC = () => {
  const nMainHeightHook = useInitNMainHeight();
  const [isConnectionLost, setIsConnectionLost] = React.useState(false);

  React.useEffect(() => {
    notification.config({
      placement: "bottomRight",
    });
  }, []);

  React.useEffect(() => {
    function changeHandler() {
      setIsConnectionLost(!navigator.onLine);
    }
    // @ts-ignore
    if (navigator.connection) {
      // @ts-ignore
      navigator.connection.onchange = changeHandler;
    }
    return () => {
      // @ts-ignore
      if (navigator.connection) {
        // @ts-ignore
        navigator.connection.onchange = null;
      }
    };
  }, []);

  React.useEffect(() => {
    if (!Theme.isRespectingOS) {
      return;
    }
    const onChange = () => {
      Theme.setToInitialTheme();
    };
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", onChange);
    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", onChange);
    };
  }, [Theme.isRespectingOS]);

  React.useEffect(() => {
    const id = setInterval(() => {
      TokenChecker.checkToken(ConsoleContext.orgTimeoutSeconds);
    }, TokenChecker.intervalMs);
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [ConsoleContext.org, ConsoleContext.orgTimeoutSeconds]);

  React.useEffect(() => {
    getTerraformSupportedKinds();
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<GlobalElement />}>
        <Route path="/" element={<HandleRootRedirect />} />
        <Route
          path={"/task/*"}
          element={
            <PrivateRoute title={"Tasks"}>
              <Task />
            </PrivateRoute>
          }
        />
        <Route
          path="/billing/*"
          element={
            <PrivateRoute title={"Billing"}>
              <BillingRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console"
          element={
            <PrivateRoute title={"Console"}>
              <ConsoleRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/apply"
          element={
            <PrivateRoute title={"Apply"}>
              <CPLNApply />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/gvc/:gvc/workload/*"
          element={
            <PrivateRoute title={"Workloads"}>
              <WorkloadRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/gvc/:gvc/identity/*"
          element={
            <PrivateRoute title={"Identities"}>
              <IdentityRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/gvc/:gvc/volumeset/*"
          element={
            <PrivateRoute title={"Volume Sets"}>
              <VolumeSetRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/gvc/*"
          element={
            <PrivateRoute title={`${ConsoleContext.hasGVC ? `${ConsoleContext.gvc} - ` : ""}GVC`}>
              <GVCRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/usage"
          element={
            <PrivateRouteDelayed
              title={"Cost & Usage"}
              onMount={() => Timezone.setOverride("Etc/UTC")}
              onClear={() => Timezone.clearOverride()}
            >
              <UsageRoute key={ConsoleContext.org} />
            </PrivateRouteDelayed>
          }
        />
        <Route
          path="/console/org/:org/logs"
          element={
            <PrivateRoute title={"Logs"}>
              <LogsRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/location/*"
          element={
            <PrivateRoute title={"Locations"}>
              <LocationRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/mk8s/*"
          element={
            <PrivateRoute title={"mk8s"}>
              <Mk8sRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/agent/*"
          element={
            <PrivateRoute title={"Agents"}>
              <AgentRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/secret/*"
          element={
            <PrivateRoute title={"Secrets"}>
              <SecretRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/quota/*"
          element={
            <PrivateRoute title={"Quotas"}>
              <QuotaRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/cloudaccount/*"
          element={
            <PrivateRoute title={"Cloud Accounts"}>
              <CloudaccountRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/domain/*"
          element={
            <PrivateRoute title={"Domains"}>
              <DomainRoute />
            </PrivateRoute>
          }
        />
        {IPSET_ENABLED ? (
          <Route
            path="/console/org/:org/ipset/*"
            element={
              <PrivateRoute title={"IP Sets"}>
                <IpSetRoute />
              </PrivateRoute>
            }
          />
        ) : null}
        <Route
          path="/console/org/:org/repository/*"
          element={
            <PrivateRoute title={"Repositories"}>
              <RepositoryRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/image/*"
          element={
            <PrivateRoute title={"Images"}>
              <ImageRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/audittrail"
          element={
            <PrivateRoute title={"Audit Trail"}>
              <AuditTrailRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/auditctx/*"
          element={
            <PrivateRoute title={"Audit Contexts"}>
              <AuditContextRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/user/*"
          element={
            <PrivateRoute title={"Users"}>
              <UserRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/policy/*"
          element={
            <PrivateRoute title={"Policies"}>
              <PolicyRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/serviceaccount/*"
          element={
            <PrivateRoute title={"Service Accounts"}>
              <ServiceaccountRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/group/*"
          element={
            <PrivateRoute title={"Groups"}>
              <GroupRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/helmrelease/*"
          element={
            <PrivateRoute title={"Helm Releases"}>
              <HelmReleasesRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/:org/marketplace/*"
          element={
            <PrivateRoute title={"Marketplace"}>
              <MarketplaceRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/org/*"
          element={
            <PrivateRoute title={`${ConsoleContext.hasOrg ? `${ConsoleContext.org} - ` : ""}Org`}>
              <OrgRoute />
            </PrivateRoute>
          }
        />
        <Route
          path="/console/educational-videos/*"
          element={
            <PrivateRoute title={"Service Accounts"}>
              <EducationalVideosRoute />
            </PrivateRoute>
          }
        />
        <Route path="trigger-sentry" element={<TriggerSentry />} />
        <Route path={"redirect"} element={<Redirect />} />
        <Route path={"*"} element={<Navigate to={"/"} />} />
      </Route>,
    ),
  );

  return (
    <>
      <Modal
        open={isConnectionLost}
        forceRender={true}
        closable={false}
        destroyOnClose={false}
        maskClosable={false}
        footer={null}
        title={"Connection is Lost"}
      >
        <span style={{ color: "var(--color-label-secondary)" }}>
          Your network connection is lost. Please resolve the issue to continue.
        </span>
      </Modal>
      {/* TODO remove */}
      <emitterGVCContext.Provider value={emitterGVC}>
        <NMainHeightContext.Provider value={nMainHeightHook}>
          <RouterProvider router={router} />
        </NMainHeightContext.Provider>
      </emitterGVCContext.Provider>
    </>
  );
};

export const App = observer(AppRaw);
