import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../advanced/providerAutoscaler";
import { ProviderPreInstallScript } from "../../advanced/providerPreInstallScript";
import { ProviderNetworking } from "../../advanced/providerNetworking";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderTritonAdvancedRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const t = mk8sDraft.provider_triton!;

  return (
    //
    <div>
      <div className="mb-4">
        <ProviderPreInstallScript provider="triton" preInstallScript={t.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={t.networking} readOnly />
      </div>
      <div>
        <ProviderAutoscaler provider="triton" autoscaler={t.autoscaler} />
      </div>
    </div>
  );
};

export const ProviderTritonAdvanced = observer(ProviderTritonAdvancedRaw);
