import * as React from "react";
import { WorkloadMobx } from "../../mst/kinds/workload";
import { observer } from "mobx-react-lite";
import { Dropdown, notification } from "antd";
import { VersionWrapper } from "./version-wrapper";
import { Link, useLocation } from "react-router-dom";
import { ContainersWrapper } from "./containers-wrapper";
import { request } from "../../services/cpln";
import { deploymentContainerSort, k8sKeySort, toSortedJSON } from "../../services/utils";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Activity, AlertCircle, ChevronDown, ChevronUp, Copy, ExternalLink } from "react-feather";
import { ViewModal } from "../modals/viewModal";
import jsYaml from "js-yaml";
import { Message } from "../../pages/workload/statefulDeployments/message";
import { Tooltip } from "../Tooltip";
import { Deployment, DeploymentVersion } from "../../schema/types/workload/deployment";
import { sortDeployments, ViewInfo } from "../../mst/kinds/workload.helpers";
import clsx from "clsx";

interface Props {
  workload: WorkloadMobx;
  deployments: Deployment[];
}
const DeploymentTableRaw: React.FC<Props> = ({ workload, deployments }) => {
  const { pathname } = useLocation();
  const { org } = ConsoleContext;
  const [viewInfo, setViewInfo] = React.useState<ViewInfo | null>(null);
  // TODO might save this to local storage per gvc/workload/deployment
  const [collapsedDeployments, setCollapsedDeployments] = React.useState<string[]>([]);

  function onToggleCollapseDeployment(deployment: string) {
    let _collapsedDeployments = [...collapsedDeployments];
    if (_collapsedDeployments.includes(deployment)) {
      _collapsedDeployments = _collapsedDeployments.filter((x) => x !== deployment);
    } else {
      _collapsedDeployments.push(deployment);
    }
    setCollapsedDeployments(_collapsedDeployments);
  }

  async function handleExportMenuClick(e: any) {
    if (e.key === "json") {
      await exportJSON();
    } else if (e.key === "yaml") {
      await exportYaml();
    }
  }

  async function exportJSON() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workload.name + "-deployments.json";
      let blob = "";
      const { data } = await request({ url: workload.selfLink + "/deployment" });
      blob = JSON.stringify(toSortedJSON(data), null, 2);
      const file = new Blob([blob], { type: "text/json" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  async function exportYaml() {
    try {
      const a = document.createElement("a");
      a.style.display = "none";
      a.classList.add("cpln-temp-a");
      a.download = workload.name + "-deployments.yaml";
      let blob = "";
      const { data } = await request({ url: workload.selfLink + "/deployment" });
      blob = jsYaml.dump(data, { indent: 2, noRefs: true, sortKeys: k8sKeySort });
      const file = new Blob([blob], { type: "text/yaml" });
      const href = URL.createObjectURL(file);
      a.href = href;
      a.click();
    } catch (e) {
      notification.warning({ message: "Failed", description: e.message });
    }
  }

  const isCollapsedAll = deployments.length === collapsedDeployments.length;

  function onToggleCollapseAll() {
    if (isCollapsedAll) {
      setCollapsedDeployments([]);
    } else {
      setCollapsedDeployments(deployments.map((d) => d.name));
    }
  }

  if (deployments.length < 1) {
    return (
      <div className="flex flex-col items-center">
        <AlertCircle className="my-4" />
        <span>No deployments found.</span>
        {/* <span>
          {state.requestedWorkloadLinks.includes(workload.selfLink)
            ? "No deployments running yet."
            : "Fetching deployments"}
        </span> */}
      </div>
    );
  }

  function getViewModalTitle(): string {
    let value = `Deployments (${workload.name})`;
    if (viewInfo) {
      if (viewInfo.deployment) {
        value = `Deployment ${viewInfo.deployment}`;
      }
      if (viewInfo.version !== undefined) {
        value = `${viewInfo.deployment} ver. ${viewInfo.version}`;
      }
    }
    return value;
  }

  function getViewModalFilename(): string {
    let value = `org-${org}-gvc-${workload.gvc}-workload-${workload.name}-deployments`;
    if (viewInfo) {
      if (viewInfo.deployment) {
        value += `-${viewInfo.deployment}`;
      }
      if (viewInfo.version !== undefined) {
        value += `-version-${viewInfo.version}`;
      }
      if (viewInfo.container) {
        value += `-container-${viewInfo.container}`;
      }
    }
    return value;
  }

  function getViewModalObject(): any {
    let value: any = deployments;
    if (viewInfo) {
      if (viewInfo.deployment) {
        value = (value as Deployment[]).find((deployment) => deployment.name === viewInfo.deployment)!;
      }
      if (viewInfo.version !== undefined) {
        if ((value as Deployment).status?.versions) {
          value = (value as Deployment).status!.versions!.find((version) => version.workload === viewInfo.version)!;
        }
      }
      if (viewInfo.container) {
        if ((value as DeploymentVersion).containers) {
          value = (value as DeploymentVersion).containers![viewInfo.container];
        }
      }
    }
    return value;
  }

  return (
    <>
      {viewInfo ? (
        <ViewModal
          kind={"deployment"}
          object={getViewModalObject()}
          onClose={() => setViewInfo(null)}
          title={getViewModalTitle()}
          visible={viewInfo !== null}
          filename={getViewModalFilename()}
          noSlim
        />
      ) : null}
      <div className="absolute top-0 right-0 flex items-start gap-6">
        <button onClick={onToggleCollapseAll} className={"ngfocus color-link"}>
          {isCollapsedAll ? "Expand All" : "Collapse All"}
          {isCollapsedAll ? (
            <ChevronDown className="inline-block feather-icon" style={{ transform: "translateY(1px)" }} />
          ) : (
            <ChevronUp className="inline-block feather-icon" style={{ transform: "translateY(1px)" }} />
          )}
        </button>
        <div className="flex flex-col items-start">
          <button className="m-0 mr-2 flex items-center ngfocus color-link" onClick={() => setViewInfo({})}>
            <span>View Deployments</span>
          </button>
          <Dropdown
            menu={{
              onClick: handleExportMenuClick,
              items: [
                { key: "json", label: "JSON" },
                { key: "yaml", label: "YAML" },
              ],
            }}
            trigger={["click"]}
          >
            <button className="m-0 mr-2 flex items-center ngfocus color-link">
              <span>Export Deployments</span>
              <ChevronDown className="feather-icon" />
            </button>
          </Dropdown>
        </div>
      </div>
      {sortDeployments(deployments).map((deployment) => {
        let isCollapsed = collapsedDeployments.includes(deployment.name);

        const isReady = deployment.status?.ready;

        return (
          <div className={`${isCollapsed ? "mb-4" : "mb-10"}`} key={deployment.name}>
            <div className={`text-xl font-medium mr-4 ${deployment.status?.endpoint ? "" : "mb-4"}`}>
              {deployment.name}{" "}
              <button className={`focus`} onClick={() => setViewInfo({ deployment: deployment.name })}>
                <Activity className={`feather-icon ${isReady ? "color-action" : "color-danger"}`} />
              </button>
              <button onClick={() => onToggleCollapseDeployment(deployment.name)} className="ml-4 text-base">
                {isCollapsed ? <ChevronDown className={`feather-icon`} /> : <ChevronUp className={`feather-icon`} />}
              </button>
            </div>
            {deployment.status?.endpoint ? (
              <div className={`flex items-center leading-none text-sm`}>
                <div>{deployment.status.endpoint}</div>
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(deployment.status?.endpoint || "");
                    notification.success({
                      message: "Copied to Clipboard",
                    });
                  }}
                  className={`ml-3 ngfocus color-link`}
                >
                  <Copy className="feather-icon" />
                </button>
                <button
                  className={`ml-3 ngfocus color-link`}
                  onClick={() => window.open(deployment.status?.endpoint, "_blank")}
                >
                  Open
                  <ExternalLink className="feather-icon ml-1 inline-block" style={{ transform: "translateY(2px)" }} />
                </button>
              </div>
            ) : null}
            {deployment.status?.message ? (
              <Message
                className="mt-2"
                messages={[deployment.status.message]}
                isHealthy={isReady}
                logsLink={pathname.replace("deployment", "-logs?trigger=true")}
                eventsLink={pathname.replace("deployment", "-events")}
              />
            ) : null}
            <VersionWrapper collapsed={collapsedDeployments.includes(deployment.name)}>
              {(deployment.status?.versions || [])
                .slice()
                .sort((a: any, b: any) => b.gvc + b.workload - (a.gvc + a.workload))
                .map((version) => (
                  <React.Fragment key={version.workload}>
                    <div className="mt-4 flex items-center">
                      <span className="font-medium">Version {version.workload}</span>
                      <button
                        onClick={() => setViewInfo({ deployment: deployment.name, version: version.workload })}
                        className={`ml-2 focus`}
                      >
                        <Activity className={`feather-icon ${version.ready ? "color-action" : "color-danger"}`} />
                      </button>
                    </div>
                    <Message
                      messages={[
                        version.message || "",
                        ...(version.ready ? [] : workload.status.resolvedImages?.errorMessages || []),
                      ]}
                      isHealthy={version.ready}
                      logsLink={pathname.replace("deployment", "-logs?trigger=true")}
                      eventsLink={pathname.replace("deployment", "-events")}
                    />
                    <ContainersWrapper>
                      {Object.values(version.containers || {})
                        .sort(deploymentContainerSort(workload))
                        .map((container, containerIndex) => {
                          const lastItem = containerIndex === Object.values(version.containers || {}).length - 1;

                          return (
                            <div
                              key={container.name}
                              className={clsx("px-4", { "border-b": !lastItem })}
                              style={{
                                paddingTop: 5,
                                paddingBottom: container.message ? "10" : "5",
                              }}
                            >
                              <div className="flex items-center w-full">
                                <div className={`w-2/12 flex items-center`}>
                                  <button
                                    className={`focus`}
                                    onClick={() =>
                                      setViewInfo({
                                        deployment: deployment.name,
                                        version: version.workload,
                                        container: container.name,
                                      })
                                    }
                                  >
                                    <Activity
                                      className={`feather-icon ${container.ready ? "color-action" : "color-danger"}`}
                                    />
                                  </button>
                                  <Tooltip title={container.name} open={container.name!.length >= 14}>
                                    <div className="ml-2 truncate">{container.name}</div>
                                  </Tooltip>
                                </div>
                                <div className="w-3/12 flex flex-col pr-2">
                                  <Tooltip title={container.image} open={container.image!.length >= 19}>
                                    <div className="pr-2 truncate  ">
                                      {container.image!.startsWith(`/org/${org}/image/`) ? (
                                        <Link className={`ngfocus color-link`} to={`/console${container.image}`}>
                                          {container.image}
                                        </Link>
                                      ) : (
                                        container.image
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className={`w-1/12 pr-2 text-sm ${container.ready ? "color-action" : "color-danger"}`}
                                >
                                  {container.ready ? "Ready" : "Not Ready"}
                                </div>
                                <div className={`w-2/12 pr-2 pl-4 flex flex-col`}>
                                  {container.resources?.cpu === undefined ? null : (
                                    <div className="text-sm">{container.resources.cpu} Millicores</div>
                                  )}
                                </div>
                                <div className={`w-2/12 pr-2 flex flex-col`}>
                                  {container.resources?.memory === undefined ? null : (
                                    <div className="text-sm">{container.resources.memory} MiB</div>
                                  )}
                                </div>
                                <div className={`w-2/12 pr-2 text-sm`}>
                                  {container.resources?.replicasReady === undefined &&
                                  container.resources?.replicas === undefined ? null : (
                                    <span>
                                      {container.resources?.replicasReady ?? "0"}/{container.resources?.replicas ?? "0"}{" "}
                                      Ready
                                    </span>
                                  )}
                                </div>
                              </div>
                              <Message
                                messages={[container.message || ""]}
                                isHealthy={container.ready}
                                logsLink={pathname.replace("deployment", "-logs?trigger=true")}
                                eventsLink={pathname.replace("deployment", "-events")}
                                className={`mt-2 ml-1`}
                              />
                            </div>
                          );
                        })}
                    </ContainersWrapper>
                  </React.Fragment>
                ))}
            </VersionWrapper>
          </div>
        );
      })}
    </>
  );
};

export const DeploymentTable = observer(DeploymentTableRaw);
