import { types, Instance, getParent } from "mobx-state-tree";
import { SelectModel } from "../../mobxDataModels/selectModel";
import { StringModel } from "../../mobxDataModels/stringModel";
import { PortModel } from "../../mobxDataModels/portModel";
import { defaultValues } from "../base";
import { NumberModel } from "../../mobxDataModels/numberModel";
import { WorkloadDraftMobx } from "./workload.draft";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";

export const WorkloadDraftContainerProbeModel = types
  .model({
    _method: types.optional(types.string, defaultValues.methodProbe),
    _exec_command: types.optional(types.string, ""),
    _tcp_port: types.maybe(types.number),
    _grpc_port: types.maybe(types.number),
    _http_path: defaultValues.pathProbe,
    _http_port: types.maybe(types.number),
    _http_scheme: types.optional(types.string, defaultValues.schemeProbe),
    _initialDelaySeconds: types.optional(types.number, defaultValues.initialDelaySeconds.default),
    _periodSeconds: types.optional(types.number, defaultValues.periodSeconds.default),
    _timeoutSeconds: types.optional(types.number, defaultValues.timeoutSecondsProbe.default),
    _successThreshold: types.optional(types.number, defaultValues.successThreshold.default),
    _failureThreshold: types.optional(types.number, defaultValues.failureThreshold.default),
    method: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Health Check Type",
        options: [
          { label: "HTTP", value: "http" },
          { label: "Command", value: "command" },
          { label: "TCP", value: "tcp" },
          { label: "GRPC", value: "grpc" },
        ],
        initialValue: defaultValues.methodProbe,
      }),
    ),
    exec_command: types.optional(types.string, ""),
    exec_args: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    tcp_port: types.optional(StringModel, () => PortModel.create({ label: "TCP Socket Port", isRequired: true })),
    grpc_port: types.optional(StringModel, () => PortModel.create({ label: "GRPC Port", isRequired: true })),
    http_path: types.optional(StringModel, () =>
      StringModel.create({ label: "Path", initialValue: defaultValues.pathProbe, isRequired: true }),
    ),
    http_port: types.optional(StringModel, () => PortModel.create()),
    http_headers: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
    http_scheme: types.optional(SelectModel, () =>
      SelectModel.create({
        label: "Scheme",
        initialValue: "HTTP",
        options: [
          { label: "HTTP", value: "HTTP" },
          { label: "HTTPS", value: "HTTPS" },
        ],
      }),
    ),
    initialDelaySeconds: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Initial Delay Sec",
        initialValue: String(defaultValues.initialDelaySeconds.default),
        min: defaultValues.initialDelaySeconds.min,
        max: defaultValues.initialDelaySeconds.max,
        isRequired: true,
      }),
    ),
    periodSeconds: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Period Sec",
        initialValue: String(defaultValues.periodSeconds.default),
        min: defaultValues.periodSeconds.min,
        max: defaultValues.periodSeconds.max,
        isRequired: true,
      }),
    ),
    timeoutSeconds: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Timeout Sec",
        initialValue: String(defaultValues.timeoutSecondsProbe.default),
        min: defaultValues.timeoutSecondsProbe.min,
        max: defaultValues.timeoutSecondsProbe.max,
        isRequired: true,
      }),
    ),
    successThreshold: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Success Threshold",
        initialValue: String(defaultValues.successThreshold.default),
        min: defaultValues.successThreshold.min,
        max: defaultValues.successThreshold.max,
        isRequired: true,
      }),
    ),
    failureThreshold: types.optional(StringModel, () =>
      NumberModel.create({
        label: "Failure Threshold",
        initialValue: String(defaultValues.failureThreshold.default),
        min: defaultValues.failureThreshold.min,
        max: defaultValues.failureThreshold.max,
        isRequired: true,
      }),
    ),
  })
  .views((self) => ({
    get commandsArray() {
      return [self.exec_command, ...self.exec_args.items.map((i) => i.firstValue)];
    },
    get http_headersArray() {
      return self.http_headers.items.map((i) => ({ name: i.firstValue, value: i.secondValue }));
    },
  }))
  .actions((self) => ({
    setExecCommand(value: string) {
      self.exec_command = value;
    },
    updateMethodOptions() {
      let parent: WorkloadDraftMobx;
      try {
        parent = getParent(self, 2);
      } catch (e) {
        return;
      }

      let methodOptions = [
        { label: "HTTP", value: "http" },
        { label: "Command", value: "command" },
        { label: "TCP", value: "tcp" },
      ];
      let methodOptionsAll = [...methodOptions, { label: "GRPC", value: "grpc" }];
      let useAll = true;
      if (parent) {
        if (parent.type.value === "cron") {
          if (self.method.value !== "grpc" && self._method !== "grpc") {
            useAll = false;
          }
        }
      }
      self.method.setOptions(useAll ? methodOptionsAll : methodOptions);
    },
  }))
  .actions((self) => ({
    reset() {
      self.updateMethodOptions();
      self.method.setInitialValue(self._method);

      self.exec_command = self._exec_command;
      self.exec_args.reset();

      self.tcp_port.setInitialValue(String(self._tcp_port || ""));

      self.grpc_port.setInitialValue(String(self._grpc_port || ""));

      self.http_path.setInitialValue(self._http_path);
      self.http_port.setInitialValue(String(self._http_port || ""));
      self.http_headers.reset();
      self.http_scheme.setInitialValue(self._http_scheme);

      self.initialDelaySeconds.setInitialValue(String(self._initialDelaySeconds));
      self.periodSeconds.setInitialValue(String(self._periodSeconds));
      self.timeoutSeconds.setInitialValue(String(self._timeoutSeconds));
      self.successThreshold.setInitialValue(String(self._successThreshold));
      self.failureThreshold.setInitialValue(String(self._failureThreshold));
    },
  }))
  .actions((self) => ({
    afterAttach() {
      if (self._exec_command) {
        self._method = "command";
      } else if (self._tcp_port) {
        self._method = "tcp";
      } else if (self._grpc_port) {
        self._method = "grpc";
      } else {
        self._method = "http";
      }
      self.reset();
    },
    confirm() {
      self._method = self.method.value as any;
      self.method.confirm();
      self._exec_command = self.exec_command;
      self.exec_args.confirm();

      if (self.tcp_port.value) {
        self._tcp_port = Number(self.tcp_port.value);
      }
      self.tcp_port.confirm();

      if (self.grpc_port.value) {
        self._grpc_port = Number(self.grpc_port.value);
      }
      self.grpc_port.confirm();

      self._http_path = self.http_path.value;
      self.http_path.confirm();
      if (self.http_port.value) {
        self._http_port = Number(self.http_port.value);
      } else {
        self._http_port = undefined;
      }
      self.http_port.confirm();
      self.http_headers.confirm();
      self._http_scheme = self.http_scheme.value as any;
      self.http_scheme.confirm();
      self._initialDelaySeconds = Number(self.initialDelaySeconds.value);
      self.initialDelaySeconds.confirm();
      self._periodSeconds = Number(self.periodSeconds.value);
      self.periodSeconds.confirm();
      self._timeoutSeconds = Number(self.timeoutSeconds.value);
      self.timeoutSeconds.confirm();
      self._successThreshold = Number(self.successThreshold.value);
      self.successThreshold.confirm();
      self._failureThreshold = Number(self.failureThreshold.value);
      self.failureThreshold.confirm();
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;

      if (self.method.isDirty) res = true;
      if (self.method.value === "command") {
        if (self._exec_command !== self.exec_command) res = true;
        if (self.exec_command) {
          if (self.exec_args.isDirty) res = true;
        }
      } else if (self.method.value === "tcp") {
        if (self._tcp_port !== Number(self.tcp_port.value)) res = true;
      } else if (self.method.value === "grpc") {
        if (self._grpc_port !== Number(self.grpc_port.value)) res = true;
      } else if (self.method.value === "http") {
        if (self._http_path !== self.http_path.value) res = true;
        if (self._http_port) {
          if (self._http_port !== Number(self.http_port.value)) res = true;
        } else if (self.http_port.value) res = true;
        if (self.http_headers.isDirty) res = true;
        if (self._http_scheme !== self.http_scheme.value) res = true;
      }
      if (self._initialDelaySeconds !== Number(self.initialDelaySeconds.value)) res = true;
      if (self._periodSeconds !== Number(self.periodSeconds.value)) res = true;
      if (self._timeoutSeconds !== Number(self.timeoutSeconds.value)) res = true;
      if (self._failureThreshold !== Number(self.failureThreshold.value)) res = true;
      if (self._successThreshold !== Number(self.successThreshold.value)) res = true;

      return res;
    },
    get isValid() {
      let res = true;
      if (self.method.value === "command") {
        if (!self.exec_command) res = false;
        if (self.exec_args.items.some((i) => !i.firstValue)) res = false;
      }
      if (self.method.value === "tcp" && !self.tcp_port.isValid) res = false;
      if (self.method.value === "grpc" && !self.grpc_port.isValid) res = false;
      if (self.method.value === "http") {
        if (!self.http_path.isValid) res = false;
        if (!self.http_port.isValid) res = false;
        if (self.http_headers.items.some((i) => !i.firstValue)) res = false;
      }
      if (!self.initialDelaySeconds.isValid) res = false;
      if (!self.periodSeconds.isValid) res = false;
      if (!self.timeoutSeconds.isValid) res = false;
      if (!self.failureThreshold.isValid) res = false;
      if (!self.successThreshold.isValid) res = false;
      return res;
    },
    asPatch(alreadyHad: boolean) {
      let res: any = {};
      if (self.method.value === "command") {
        res.exec = { command: [...self.commandsArray] };
        if (alreadyHad) {
          res.tcpSocket = null;
          res.httpGet = null;
          res.grpc = null;
        }
      } else if (self.method.value === "tcp") {
        res.tcpSocket = { port: Number(self.tcp_port.value) };
        if (alreadyHad) {
          res.exec = null;
          res.httpGet = null;
          res.grpc = null;
        }
      } else if (self.method.value === "grpc") {
        res.grpc = { port: Number(self.grpc_port.value) };
        if (alreadyHad) {
          res.exec = null;
          res.httpGet = null;
          res.tcp = null;
        }
      } else if (self.method.value === "http") {
        res.httpGet = {
          path: self.http_path.value,
          httpHeaders: [...self.http_headersArray],
          scheme: self.http_scheme.value,
        };
        if (self._http_port) {
          res.httpGet.port = self.http_port.value ? Number(self.http_port.value) : null;
        } else if (self.http_port.value) {
          res.httpGet.port = Number(self.http_port.value);
        }

        if (alreadyHad) {
          res.exec = null;
          res.tcpSocket = null;
          res.grpc = null;
        }
      }
      res.initialDelaySeconds = Number(self.initialDelaySeconds.value);
      res.periodSeconds = Number(self.periodSeconds.value);
      res.timeoutSeconds = Number(self.timeoutSeconds.value);
      res.successThreshold = Number(self.successThreshold.value);
      res.failureThreshold = Number(self.failureThreshold.value);
      return res;
    },
    get asObject() {
      let res: any = {};
      if (self.method.value === "command") {
        res.exec = { command: [...self.commandsArray] };
      } else if (self.method.value === "tcp") {
        res.tcpSocket = { port: Number(self.tcp_port.value) };
      } else if (self.method.value === "grpc") {
        res.grpc = { port: Number(self.grpc_port.value) };
      } else if (self.method.value === "http") {
        res.httpGet = {
          path: self.http_path.value,
          httpHeaders: [...self.http_headersArray],
          scheme: self.http_scheme.value,
        };
        if (self.http_port.value) {
          res.httpGet.port = Number(self.http_port.value);
        }
      }
      res.initialDelaySeconds = Number(self.initialDelaySeconds.value);
      res.periodSeconds = Number(self.periodSeconds.value);
      res.timeoutSeconds = Number(self.timeoutSeconds.value);
      res.successThreshold = Number(self.successThreshold.value);
      res.failureThreshold = Number(self.failureThreshold.value);
      return res;
    },
  }));
export interface WorkloadDraftContainerProbeMobx extends Instance<typeof WorkloadDraftContainerProbeModel> {}
