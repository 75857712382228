import { observer } from "mobx-react-lite";
import * as React from "react";
import { AlertCircle } from "react-feather";
import { Link } from "react-router-dom";

interface Props {
  messages: string[];
  isHealthy?: boolean;
  eventsLink: string;
  logsLink: string;
  className?: string;
  style?: any;
}
const MessageRaw: React.FC<Props> = ({
  messages: _messages,
  eventsLink,
  logsLink,
  isHealthy = true,
  className = "",
  style = {},
}) => {
  let messages: string[] = [];
  for (const _message of _messages) {
    let _messagesProcessed: string[] = [_message];
    if (_message.includes("\n")) {
      const splits = _message.split("\n");
      for (const split of splits) {
        if (split.includes(";")) {
          const subSplits = split.split(";");
          _messagesProcessed.push(...subSplits);
        } else {
          _messagesProcessed.push(split);
        }
      }
    }
    for (const _message of _messagesProcessed) {
      if (messages.includes(_message)) {
        continue;
      }
      if (!_message) {
        continue;
      }
      if (_message.toLowerCase().includes("is disabled on BYOK clusters".toLowerCase())) {
        continue;
      }
      messages.push(_message);
    }
  }

  if (messages.length < 1) {
    return null;
  }

  if (isHealthy) {
    messages = messages.filter((message) => !message.includes("check events"));
  }

  return (
    <div className={`text-sm flex flex-col gap-1 ${className}`} style={style}>
      {messages.map((message, index) => (
        <div key={index} className="flex items-center gap-1">
          <div style={{ width: 16 }}>
            <AlertCircle className={`feather-icon color-link`} />
          </div>
          {message.toLowerCase().includes("check logs") ? (
            <div>
              <span>{message.split("check logs")[0]}</span>
              <Link className="inline color-link" to={logsLink}>
                check logs
              </Link>
              <span>{message.split("check logs")[1]}</span>
            </div>
          ) : message.toLowerCase().includes("check events") ? (
            <div>
              <span>{message.split("check events")[0]}</span>
              <Link className="inline color-link" to={eventsLink}>
                check events
              </Link>
              <span>{message.split("check events")[1]}</span>
            </div>
          ) : (
            <span>{message}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export const Message = observer(MessageRaw);
