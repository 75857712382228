import { types, Instance } from "mobx-state-tree";
import { ngParseLink } from "../../utils/linkParser/linkParser";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { DIRECT_LB_ENABLED, DIRECT_LB_IPSET_ENABLED } from "../../envVariables";

export const WorkloadDraftLoadBalancerDirectPortReadOnlyModel = types.model({
  externalPort: types.number,
  protocol: types.string,
  containerPort: types.maybe(types.number),
  scheme: types.maybe(types.string),
});

export const WorkloadDraftLoadBalancerDirectModel = types
  .model({
    _enabled: types.optional(types.boolean, false),
    enabled: types.optional(types.boolean, false),
    _ipSet: types.optional(types.string, ""),
    ipSet: types.optional(types.string, ""),
    _ports: types.array(WorkloadDraftLoadBalancerDirectPortReadOnlyModel),
    ports: types.optional(ListOfItemsModel, () => ListOfItemsModel.create()),
  })
  .actions((self) => ({
    reset() {
      self.enabled = self._enabled;
      if (DIRECT_LB_IPSET_ENABLED) {
        const { name: _ipsetName } = ngParseLink(self._ipSet, { kind: "ipset", useInputCtx: true });
        self.ipSet = _ipsetName;
      }
      self.ports.setInitialItems(
        self._ports.map((p) => ({
          firstValue: String(p.externalPort || ""),
          secondValue: p.protocol,
          thirdValue: String(p.containerPort || ""),
          fourthValue: String(p.scheme || ""),
        })),
      );
      self.ports.reset();
    },
    confirm() {
      self._enabled = self.enabled;
      if (DIRECT_LB_IPSET_ENABLED) {
        self._ipSet = self.ipSet;
      }
      self._ports.clear();
      for (const port of self.ports.items) {
        self._ports.push({
          externalPort: Number(port.firstValue),
          protocol: port.secondValue,
          containerPort: port.thirdValue ? Number(port.thirdValue) : undefined,
          scheme: port.fourthValue,
        });
      }

      self.ports.confirm();
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.reset();
    },
    setEnabled(value: boolean) {
      self.enabled = value;
    },
    setIpSet(value: string) {
      self.ipSet = value;
    },
  }))
  .views((self) => ({
    get isPortsValid() {
      let res = true;
      if (DIRECT_LB_ENABLED) {
        if (self.enabled) {
          if (self.ports.items.some((i) => !i.firstValue || !i.secondValue)) res = false;
        }
      }
      return res;
    },
    get portsError(): string {
      if (!DIRECT_LB_ENABLED) return "";
      if (!self.enabled) return "";
      if (self.ports.items.some((i) => !i.firstValue || !i.secondValue)) {
        return "External Port and Protocol values are required";
      }
      if (self.ports.items.some((i) => Number.isNaN(i.firstValue))) {
        return "External Port must be a number";
      }
      if (
        self.ports.items.some((i) => {
          const externalPortAsNumber = Number(i.firstValue);
          if (Number.isNaN(externalPortAsNumber)) return false;
          if (externalPortAsNumber < 22 || externalPortAsNumber > 32768) return true;
          return false;
        })
      ) {
        return "External Port must be between 22 and 32768";
      }
      if (self.ports.items.some((i) => !!i.thirdValue && Number.isNaN(i.thirdValue))) {
        return "Container Port must be a number";
      }
      if (
        self.ports.items.some((i) => {
          if (!i.thirdValue) return false;
          const containerPortAsNumber = Number(i.thirdValue);
          if (Number.isNaN(containerPortAsNumber)) return false;
          if (containerPortAsNumber < 80 || containerPortAsNumber > 65535) return true;
          return false;
        })
      ) {
        return "Container Port must be between 80 and 65535";
      }
      if (
        self.ports.items.some((i) => {
          if (!i.thirdValue) return false;
          const containerPortAsNumber = Number(i.thirdValue);
          if (Number.isNaN(containerPortAsNumber)) return false;
          const reserved = [8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000];
          if (reserved.includes(containerPortAsNumber)) return true;
          return false;
        })
      ) {
        return "Container Port cannot use reserved ports (8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000)";
      }

      return "";
    },
  }))
  .views((self) => ({
    get isDirty() {
      let res = false;
      if (self.enabled !== self._enabled) res = true;
      if (self.enabled) {
        if (DIRECT_LB_ENABLED) {
          const { name: _ipsetName } = ngParseLink(self._ipSet, { kind: "ipset", useInputCtx: true });
          if (self.ipSet !== _ipsetName) res = true;
        }
        if (self.ports.isDirty) res = true;
      }
      return res;
    },
    get isValid() {
      let res = true;
      if (!self.isPortsValid) res = false;
      return res;
    },
    get asObject() {
      const res: any = {
        enabled: self.enabled,
        ports: self.ports.items.map((i) => {
          const port: any = {
            externalPort: Number(i.firstValue),
            protocol: i.secondValue,
          };
          if (i.thirdValue) {
            port.containerPort = Number(i.thirdValue);
          }
          if (i.fourthValue) {
            port.scheme = i.fourthValue;
          }
          return port;
        }),
      };
      if (DIRECT_LB_IPSET_ENABLED) {
        const { absolute: ipsetLink } = ngParseLink(self.ipSet, { kind: "ipset" });
        res.ipSet = ipsetLink;
      }
      return res;
    },
  }));
export interface WorkloadDraftLoadBalancerDirectMobx extends Instance<typeof WorkloadDraftLoadBalancerDirectModel> {}
