import * as React from "react";
import { observer } from "mobx-react-lite";
import { VolumeSetMobx } from "../../../mst/kinds/volumeset";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { WorkloadDraftContainerMobx } from "../../../mst/stores/workload.draft.container";
import { PathUriMobx, VOLUME_URI } from "../../../mst/stores/workload.draft.container.volume";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGInput } from "../../../newcomponents/input/input";
import { MoreHorizontal } from "react-feather";
import { InputGroup } from "../../../newcomponents/inputGroup/inputGroup";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGCombobox } from "../../../newcomponents/select/ngcombobox";
import { useDebounce } from "../../../components/table/useDebounce";
import { linksOf, request } from "../../../services/cpln";
import clsx from "clsx";
import { Theme } from "../../../mobxStores/uiData/theme";
import { Secret } from "../../../schema/types/secret";
import { ngParseLink } from "../../../utils/linkParser/linkParser";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  containerDraft: WorkloadDraftContainerMobx;
  volume: PathUriMobx;
}

const VolumeItemRaw: React.FC<Props> = ({ workloadDraft, containerDraft, volume }) => {
  const [debouncedValue, setDebouncedValue] = useDebounce(volume.uriValue, 400);
  const [secret, setSecret] = React.useState<Secret | undefined>();
  const [suggestedProperties, setSuggestedProperties] = React.useState(false);

  React.useEffect(() => {
    setMetadata(volume, secret, false);
  }, [debouncedValue]);

  async function setMetadata(volume: PathUriMobx, secret?: Secret, reveal?: boolean) {
    if (!secret) {
      volume.setMetadata({});
      return;
    }

    // INFO keep in sync with EnvironmentVariable component
    const secretTypeActionMap = {
      opaque: { shouldReveal: false, properties: ["payload"] },
      tls: { shouldReveal: false, properties: ["key", "cert", "chain"] },
      gcp: { shouldReveal: false, properties: [] },
      aws: { shouldReveal: false, properties: ["accessKey", "secretKey", "roleArn", "externalId"] },
      ecr: { shouldReveal: false, properties: ["accessKey", "secretKey", "roleArn", "externalId"] },
      userpass: { shouldReveal: false, properties: ["username", "password"] },
      keypair: { shouldReveal: false, properties: ["secretKey", "publicKey", "passphrase"] },
      "azure-sdk": { shouldReveal: false, properties: [] },
      "azure-connector": { shouldReveal: false, properties: ["url", "code"] },
      docker: { shouldReveal: false, properties: [] },
      dictionary: { shouldReveal: true, properties: [] },
      "nats-account": { shouldReveal: false, properties: ["accountId", "privateKey"] },
    };

    if (!secretTypeActionMap[secret.type].shouldReveal) {
      volume.setMetadata({ ...secret, properties: secretTypeActionMap[secret.type].properties });
      return;
    }

    if (!reveal) {
      volume.setMetadata({ ...secret });
      return;
    }

    try {
      const revealLink = linksOf(secret).reveal!;
      const { data: revealedSecret } = await request({ url: revealLink });
      if (typeof revealedSecret.data === "object") {
        let props: string[] = Object.keys(revealedSecret.data);
        volume.setMetadata({ ...secret, properties: props });
      } else {
        volume.setMetadata({});
      }
      setSuggestedProperties(true);
    } catch (e) {
      volume.setMetadata({});
    }
  }

  const selfScratchVolumes: string[] = containerDraft.volumes.editVolumes
    .map((volume) => {
      if (volume.uriPrefix !== VOLUME_URI.SCRATCH.prefix) {
        return false;
      }
      return volume.uriValue;
    })
    .filter(Boolean) as string[];

  const scratchVolumes = workloadDraft.allScratchVolumes.filter((sv) => !selfScratchVolumes.includes(sv));

  const showBrowse =
    !volume.uri ||
    volume.uri.startsWith("s3") ||
    volume.uri.startsWith("gs") ||
    volume.uri.startsWith("azureblob") ||
    volume.uri.startsWith("azurefs");

  let options = Object.entries(VOLUME_URI).map(([key, value]) => ({ label: value.label, value: value.prefix }));
  if (workloadDraft.type.value !== "stateful") {
    options = options.filter((option) => !option.value.includes("volumeset"));
  }

  const VOLUME_URI_ITEM = Object.values(VOLUME_URI).find((i) => i.prefix === volume.uriPrefix);

  return (
    <React.Fragment>
      <div className="flex items-center gap-2">
        <InputGroup key={volume.uriPrefix} style={{ width: `calc(100% / 3 *2)` }} colSpans={[3, 6, 3]}>
          <NGSelect
            value={volume.uriPrefix}
            onChange={volume.setUriPrefix}
            renderOption={({ getOptionValue, getOptionLabel, option, isHovered, isSelected, props }) => {
              return (
                <li
                  key={getOptionValue(option)}
                  {...props}
                  className={clsx(`flex flex-col`, {
                    "option-hover": isHovered,
                    "option-selected": isSelected,
                  })}
                >
                  <span>{getOptionLabel(option)}</span>
                  {getOptionValue(option) !== "text" ? (
                    <span
                      className="text-sm"
                      style={{
                        display: "inline-block",
                        padding: "1px 4px",
                        borderRadius: 2,
                        color: Theme.theme === "dark" ? `var(--color-gray-50)` : `var(--color-gray-600)`,
                        backgroundColor: Theme.theme === "dark" ? `var(--color-gray-600)` : `var(--color-gray-50)`,
                      }}
                    >
                      {getOptionValue(option)}
                    </span>
                  ) : null}
                </li>
              );
            }}
            options={options}
          />
          {volume.uriPrefix === VOLUME_URI.SECRET.prefix ? (
            <NGKindSelect
              value={volume.uriValue}
              onChange={(value, item) => {
                volume.setUriValue(value);
                setDebouncedValue(value);
                setSecret(item);
              }}
              renderOption={({ getOptionValue, getOptionLabel, props, option, isHovered, isSelected }) => (
                <li
                  key={getOptionValue(option)}
                  {...props}
                  className={clsx(`flex items-center justify-between`, {
                    "option-hover": isHovered,
                    "option-selected": isSelected,
                  })}
                >
                  <span className="truncate">{getOptionLabel(option)}</span>
                  <span className="truncate">{(option as any).type}</span>
                </li>
              )}
              kind="secret"
              placeholder={"Secret"}
            />
          ) : volume.uriPrefix === VOLUME_URI.VOLUMESET.prefix ? (
            <NGKindSelect
              value={volume.uriValue}
              onChange={volume.setUriValue}
              kind="volumeset"
              placeholder="Volume Set"
              queries={[{ rel: "gvc", value: ConsoleContext.gvc! }]}
              filterOptions={(vs: VolumeSetMobx) => {
                if (!!vs.status.usedByWorkload) {
                  const { name } = ngParseLink(vs.status.usedByWorkload, { useInputCtx: true });
                  if (name === workloadDraft.name.value) {
                    return true;
                  }
                  return false;
                }
                return true;
              }}
              fetchAll
            />
          ) : volume.uriPrefix === VOLUME_URI.SCRATCH.prefix ? (
            <NGCombobox
              value={volume.uriValue}
              onChange={volume.setUriValue}
              options={scratchVolumes.map((sv) => ({ label: sv, value: sv }))}
            />
          ) : (
            <NGInput
              value={volume.uriValue}
              onChange={(e) => volume.setUriValue(e.target.value)}
              placeholder={VOLUME_URI_ITEM?.value}
              buttons={
                showBrowse
                  ? [
                      {
                        title: "Browse",
                        onClick: () => {
                          containerDraft.volumes.browse.setVolumeId(volume.id);
                          const provider = volume.uriPrefix.startsWith("gs")
                            ? "gcp"
                            : volume.uriPrefix.startsWith("s3")
                            ? "aws"
                            : "azure";
                          containerDraft.volumes.browse.setProvider(provider);
                          containerDraft.volumes.browse.setPage("provider");
                        },
                        render: () => <MoreHorizontal className="h-4" />,
                      },
                    ]
                  : []
              }
            />
          )}
          {volume.uriPrefix === VOLUME_URI.SECRET.prefix ? (
            <NGCombobox
              value={volume.uriSuffix}
              onChange={volume.setUriSuffix}
              options={(volume.metadata.properties || []).map((v: string) => ({ label: v, value: v }))}
              placeholder="Property"
              showNoItems={false}
            />
          ) : null}
        </InputGroup>
        <NGInput
          data-testid="tag-value"
          style={{ width: `calc(100% / 3)` }}
          placeholder={"Path"}
          value={String(volume.path)}
          onChange={(e) => volume.setPath(String(e.target.value))}
        />
        <NGButton
          onClick={() => containerDraft.volumes.remove(volume.id)}
          className={`inline-block h-full`}
          variant={"danger"}
          outlined
          size={"small"}
        >
          Remove
        </NGButton>
      </div>
      {volume.metadata?.type === "dictionary" && !suggestedProperties ? (
        <div className="flex items-center justify-end gap-2">
          <NGButton
            onClick={() => setMetadata(volume, secret, true)}
            variant={"secondary"}
            size="small"
            style={{ width: 250 }}
          >
            Reveal & Suggest Properties
          </NGButton>
          <span style={{ width: 74 }} />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const VolumeItem = observer(VolumeItemRaw);
