import * as React from "react";
import { FormButtons } from "../forms/formButtons";
import { notification } from "antd";
import { useDetailContext } from "./detailContext";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { Tags as TagsType } from "../../schema/types/base";
import { request } from "../../services/cpln";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { ListOfItemsModel } from "../../mobxDataModels/listOfItemsModel";
import { NGInput } from "../../newcomponents/input/input";
import { tagHelpers } from "../../mst/kinds/tag.helpers";
import { sortBy } from "lodash";
import { observer } from "mobx-react-lite";
import NGAlert from "../../newcomponents/alert";

const STORAGE_KEY_TAG_LINKS_INFO = "urlTagsUpdateDismissed";

interface Props {
  link: string;
  tags: TagsType;
  hideButtonsForMk8s?: boolean;
}

const TagsRaw: React.FC<Props> = ({ link, tags, hideButtonsForMk8s = false }) => {
  const { fetchItem } = useDetailContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isValid, setIsValid] = React.useState(false);
  const [previousItemLength, setPreviousItemLength] = React.useState<number | null>(null);

  function getDefaultTagItems() {
    return sortBy(
      Object.keys(tags).map((k) => ({ firstValue: k, secondValue: String(tags[k ?? ""]) })),
      "firstValue",
    );
  }
  const tagsRef = React.useRef(ListOfItemsModel.create({ _items: getDefaultTagItems() }));
  function getTagItems() {
    return tagsRef.current.items.map((i) => ({ firstValue: i.firstValue, secondValue: i.secondValue }));
  }

  const duplicateKeyData = React.useMemo(() => tagHelpers(getTagsAsArray()).getDuplicateKeyData(), [
    tagsRef.current.hash,
  ]);

  React.useEffect(() => {
    let isValid = true;
    if (duplicateKeyData.hasDuplicates) isValid = false;
    if (getTagItems().some((i) => !i.firstValue)) isValid = false;
    setIsValid(isValid);
  }, [duplicateKeyData.hasDuplicates, tagsRef.current.hash]);

  React.useEffect(() => {
    setIsDirty(tagsRef.current.isDirty);
  }, [tagsRef.current.isDirty]);

  React.useEffect(() => {
    if (previousItemLength === null || tagsRef.current.items.length <= previousItemLength) {
      // no action
    } else {
      const index = tagsRef.current.items.length - 1;
      setTimeout(() => {
        const tagValueInput = document.querySelector<HTMLInputElement>(`[data-tagindex="${index}"]`);
        if (tagValueInput) {
          tagValueInput.focus();
        }
      }, 100);
    }

    setPreviousItemLength(tagsRef.current.items.length);
  }, [tagsRef.current.items.length]);

  React.useEffect(() => {
    PromptContext.setWhen(isDirty);
  }, [isDirty]);

  useCleanPrompt();

  function getTags() {
    let result: TagsType = {};
    for (const tagItem of getTagItems()) {
      result[tagItem.firstValue] = tagItem.secondValue;
    }
    return result;
  }
  function getTagsAsArray() {
    return getTagItems().map((i) => ({ key: i.firstValue, value: i.secondValue }));
  }

  function onInfoClose(): void {
    localStorage.setItem(STORAGE_KEY_TAG_LINKS_INFO, "true");
  }

  async function handleSave() {
    try {
      setIsLoading(true);
      await request({ method: "patch", url: link, body: { "$replace/tags": getTags() } });
      notification.success({
        message: "Success",
        description: `Updated tags`,
      });

      setIsLoading(false);
      if (fetchItem) {
        fetchItem();
      }
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({ message: "Failed", description: errorMessage });
      if (e.response.status === 409) {
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  function handleReset() {
    tagsRef.current.setInitialItems(getDefaultTagItems());
    tagsRef.current.reset();
  }

  return (
    <>
      <NGAlert
        className="mb-4"
        style={{ maxWidth: "100%", width: "100%" }}
        type="info"
        title="Tag Links"
        message="Tags with a URL as their value will appear as clickable links on the 'Info' page."
        isClosed={localStorage.getItem(STORAGE_KEY_TAG_LINKS_INFO) === "true"}
        closable
        onClose={onInfoClose}
      />
      <NGInputListMst
        data={tagsRef.current}
        removeFlexGrow
        canAdd={() => !duplicateKeyData.hasDuplicates}
        firstInput={(i, index) => (
          <NGInput
            value={i.firstValue}
            placeholder={"Tag"}
            onChange={(e) => i.setFirstValue(e.target.value)}
            invalid={index === duplicateKeyData.index}
            data-tagindex={index}
          />
        )}
        secondInput={(i) => (
          <NGInput
            value={i.secondValue}
            placeholder={"Value (Optional)"}
            onChange={(e) => i.setSecondValue(e.target.value)}
          />
        )}
        label="Tags"
        error={
          duplicateKeyData.hasDuplicates
            ? `Tag name ${tagsRef.current.items[duplicateKeyData.index].firstValue} is already being used.`
            : ""
        }
      />
      {hideButtonsForMk8s ? null : (
        <FormButtons
          loading={isLoading}
          onReset={handleReset}
          onSave={handleSave}
          resetDisabled={!isDirty || isLoading}
          saveDisabled={!isValid || !isDirty || isLoading}
        />
      )}
    </>
  );
};

export const Tags = observer(TagsRaw);
