import { types } from "mobx-state-tree";
import { AutoscalerModel } from "./autoscaler";
import { TaintModel } from "./common";
import { NetworkingModel } from "./networking";
import { PreInstallScriptProperty } from "./preInstallScript";

const TritonPoolModel = types.model("Hetzner Node Pool", {
  name: types.string, // regex /^([A-Za-z0-9][-A-Za-z0-9_.]*)?[A-Za-z0-9]$/
  labels: types.maybe(types.frozen()), // ask for examples
  taints: types.array(TaintModel),
  packageId: types.string,
  overrideImageId: types.maybe(types.string),
  publicNetworkId: types.maybe(types.string),
  privateNetworkIds: types.array(types.string),
  tritonTags: types.optional(types.frozen(), {}),
  minSize: types.number, // min 0
  maxSize: types.number, // >=  minSize
});

export const TritonProviderConnectionModel = types.model("Triton Provider Connection", {
  url: types.string,
  account: types.string,
  user: types.optional(types.string, ""),
  privateKeySecretLink: types.string,
});

export const TritonProviderModel = types.model("Triton Provider", {
  connection: TritonProviderConnectionModel,
  location: types.string,
  privateNetworkId: types.string,
  firewallEnabled: types.optional(types.boolean, false),
  nodePools: types.array(TritonPoolModel),
  imageId: types.string,
  sshKeys: types.array(types.string),
  autoscaler: types.optional(AutoscalerModel, () => AutoscalerModel.create()),
  networking: types.optional(NetworkingModel, () => NetworkingModel.create()),
  preInstallScript: PreInstallScriptProperty,
});
