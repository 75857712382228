import * as React from "react";
import Scrollbars, { ScrollbarProps } from "react-custom-scrollbars";
import { observer } from "mobx-react-lite";
import { Theme } from "../../mobxStores/uiData/theme";

interface Props extends ScrollbarProps {
  thumbStyle?: React.CSSProperties;
}

const CustomScrollbarsRaw: React.FC<Props> = (props) => {
  const scrollbarColor: string = Theme.theme == "light" ? "#CCCCCC" : "var(--color-gray-1200)";
  let thumbStyle: React.CSSProperties = {
    backgroundColor: scrollbarColor,
    borderRadius: 6,
  };

  if (props.thumbStyle) {
    thumbStyle = {
      ...thumbStyle,
      ...props.thumbStyle,
    };
  }

  return (
    <Scrollbars
      /* Default */
      renderThumbHorizontal={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            ...thumbStyle,
          }}
        />
      )}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            ...thumbStyle,
          }}
        />
      )}
      /* Specified Preferences */
      {...props}
    >
      {props.children}
    </Scrollbars>
  );
};

export const CustomScrollbars = observer(CustomScrollbarsRaw);
