import * as React from "react";
import clsx from "clsx";
import NGAlert from "../../../newcomponents/alert";
import { observer } from "mobx-react-lite";
import { EnvVarsMobx } from "../../../mobxDataModels/envVarsModel";
import { NGInput } from "../../../newcomponents/input/input";
import { InputGroup } from "../../../newcomponents/inputGroup/inputGroup";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGKindSelect } from "../../../newcomponents/select/ngkindselect";
import { NGCombobox } from "../../../newcomponents/select/ngcombobox";
import { NGButton } from "../../../newcomponents/button/Button";
import { X } from "react-feather";
import { EnvVarInherit } from "./environmentVariables";
import { notification } from "antd";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { linksOf, request } from "../../../services/cpln";
import { useDebounce } from "../../../components/table/useDebounce";
import { EnvVarMobx } from "../../../mobxDataModels/envVarModel";
import { envCplnReferenceOptions } from "../../../mst/kinds/workload";
import { Theme } from "../../../mobxStores/uiData/theme";
import { Secret } from "../../../schema/types/secret";

interface Props {
  env: EnvVarsMobx;
  envVar: EnvVarMobx;
  inherit: EnvVarInherit;
  isUpdating: boolean;
  onAdd: () => void;
  onUpdate: () => void;
  onCancelEdit: () => void;
}

const EnvironmentVariableRaw: React.FC<Props> = ({
  env,
  envVar,
  inherit,
  isUpdating,
  onAdd,
  onUpdate,
  onCancelEdit,
}) => {
  const [debouncedValue, setDebouncedValue] = useDebounce(envVar.value, 400);
  const [secret, setSecret] = React.useState<Secret | undefined>();
  const [suggestedProperties, setSuggestedProperties] = React.useState(false);
  const [flattenedDictionary, setFlattenedDictionary] = React.useState(false);
  const [isEnvVarNameTaken, setIsEnvVarNameTaken] = React.useState(checkEnvVarNameExists());

  // Effects
  React.useEffect(() => {
    setMetadata(envVar, secret);
    setSuggestedProperties(false);
    setFlattenedDictionary(false);
  }, [debouncedValue]);

  React.useEffect(() => {
    setIsEnvVarNameTaken(checkEnvVarNameExists());
  }, [envVar.name, isUpdating]);

  React.useEffect(() => {
    setMetadata(envVar);
  }, [envVar.valuePrefix]);

  // Functions
  async function setMetadata(envVar: EnvVarMobx, secret?: Secret, reveal?: boolean) {
    if (!secret) {
      envVar.setMetadata({});
      return;
    }

    const secretTypeActionMap = {
      opaque: { shouldReveal: false, supportsProperties: false, properties: [] },
      tls: { shouldReveal: false, supportsProperties: true, properties: ["key", "cert", "chain"] },
      gcp: { shouldReveal: false, supportsProperties: false, properties: [] },
      aws: {
        shouldReveal: false,
        supportsProperties: true,
        properties: ["accessKey", "secretKey", "roleArn", "externalId"],
      },
      ecr: {
        shouldReveal: false,
        supportsProperties: true,
        properties: ["accessKey", "secretKey", "roleArn", "externalId"],
      },
      userpass: { shouldReveal: false, supportsProperties: true, properties: ["username", "password"] },
      keypair: { shouldReveal: false, supportsProperties: true, properties: ["secretKey", "publicKey", "passphrase"] },
      "azure-sdk": { shouldReveal: false, supportsProperties: false, properties: [] },
      "azure-connector": { shouldReveal: false, supportsProperties: true, properties: ["url", "code"] },
      docker: { shouldReveal: false, supportsProperties: false, properties: [] },
      dictionary: { shouldReveal: true, supportsProperties: true, properties: [] },
      "nats-account": { shouldReveal: false, supportsProperties: true, properties: ["accountId", "privateKey"] },
    };

    const actionItem = secretTypeActionMap[secret.type];
    if (!actionItem.shouldReveal) {
      envVar.setMetadata({
        ...secret,
        supportsProperties: actionItem.supportsProperties,
        properties: actionItem.properties,
      });
      return;
    }

    if (!reveal) {
      envVar.setMetadata({ ...secret, supportsProperties: actionItem.supportsProperties });
      return;
    }

    try {
      const revealLink = linksOf(secret).reveal!;
      const { data: revealedSecret } = await request({ url: revealLink });
      if (typeof revealedSecret.data === "object") {
        let props: string[] = Object.keys(revealedSecret.data);
        envVar.setMetadata({ ...secret, supportsProperties: actionItem.supportsProperties, properties: props });
      } else {
        envVar.setMetadata({ supportsProperties: actionItem.supportsProperties });
      }
      setSuggestedProperties(true);
    } catch (e) {
      envVar.setMetadata({ supportsProperties: actionItem.supportsProperties });
    }
  }

  async function flattenDictionary() {
    try {
      const secretName = envVar.metadata.name;
      const secretLink = `/org/${ConsoleContext.org}/secret/${secretName}`;
      const { data: revealedSecretItem } = await request({ url: secretLink + "/-reveal" });
      for (const key of Object.keys(revealedSecretItem.data)) {
        const varItem: EnvVarMobx = env.new();
        varItem.setName(`${secretName}.${key}`);
        varItem.setValuePrefix(`cpln://secret/`);
        varItem.setValueBody(secretName);
        varItem.setValueSuffix(key);
        env.addEnvVar(varItem);
      }
      setFlattenedDictionary(true);
    } catch (e) {
      notification.warning({
        message: "Failed to flatten dictionary to environment variables",
        description: e.message,
      });
    }
  }

  function checkEnvVarNameExists(): boolean {
    if (env.editVars.length === 0) {
      return false;
    }

    for (let editVar of env.editVars) {
      if (editVar.index === envVar.index) {
        continue;
      }

      if (editVar.name === envVar.name) {
        return true;
      }
    }

    return false;
  }

  // Computed
  const secretValueHasWhitespace: boolean =
    envVar.valuePrefix.includes("secret") && (envVar.valueBody.includes(" ") || envVar.valueSuffix.includes(" "));
  const isDisabled: boolean =
    !envVar.isValid ||
    isEnvVarNameTaken ||
    secretValueHasWhitespace ||
    (envVar.valuePrefix !== "text" && envVar.valueBody.length == 0);
  const canSuggestProperties: boolean = envVar.metadata?.type === "dictionary" && !suggestedProperties;
  const canFlattenDictionary: boolean = envVar.metadata?.type === "dictionary" && !flattenedDictionary;
  const canRevealSecret: boolean =
    envVar.valuePrefix.includes("secret") && (canSuggestProperties || canFlattenDictionary);

  return (
    <React.Fragment>
      {/* Form */}
      <div className="flex gap-2">
        {/* Key */}
        <NGInput
          style={{ width: "calc((100% - 90px) / 3)" }}
          placeholder={"Key"}
          value={envVar.name}
          onChange={(e) => envVar.setName(e.target.value)}
        />

        {/* Value */}
        <InputGroup style={{ width: "calc((100% - 90px) / 3 * 2)" }} key={envVar.valuePrefix} colSpans={[3, 6, 3]}>
          <NGSelect
            value={envVar.valuePrefix}
            onChange={envVar.setValuePrefix}
            renderOption={({ getOptionValue, getOptionLabel, option, isHovered, isSelected, props }) => {
              return (
                <li
                  key={getOptionValue(option)}
                  {...props}
                  className={clsx(`flex flex-col`, {
                    "option-hover": isHovered,
                    "option-selected": isSelected,
                  })}
                >
                  <span>{getOptionLabel(option)}</span>
                  {getOptionValue(option) !== "text" ? (
                    <span
                      className="text-sm"
                      style={{
                        display: "inline-block",
                        padding: "1px 4px",
                        borderRadius: 2,
                        color: Theme.theme === "dark" ? `var(--color-gray-50)` : `var(--color-gray-600)`,
                        backgroundColor: Theme.theme === "dark" ? `var(--color-gray-600)` : `var(--color-gray-50)`,
                      }}
                    >
                      {getOptionValue(option)}
                    </span>
                  ) : null}
                </li>
              );
            }}
            options={[
              { label: "Literal Value", value: "text" },
              { label: "Secret", value: "cpln://secret/" },
              { label: "Built-in", value: "cpln://reference/" },
            ]}
          />
          {envVar.valuePrefix === "cpln://secret/" ? (
            <NGKindSelect
              value={envVar.valueBody}
              onChange={(value, item) => {
                envVar.setValueBody(value);
                setDebouncedValue(value);
                setSecret(item);
              }}
              kind="secret"
              placeholder={"Secret"}
              renderOption={({ getOptionValue, getOptionLabel, props, option, isHovered, isSelected }) => (
                <li
                  key={getOptionValue(option)}
                  {...props}
                  className={clsx(`flex items-center justify-between`, {
                    "option-hover": isHovered,
                    "option-selected": isSelected,
                  })}
                >
                  <span className="truncate">{getOptionLabel(option)}</span>
                  <span className="truncate">{(option as any).type}</span>
                </li>
              )}
            />
          ) : envVar.valuePrefix === "cpln://reference/" ? (
            <NGSelect
              value={envVar.valueBody}
              onChange={envVar.setValueBody}
              options={envCplnReferenceOptions.map((v: string) => ({ label: v, value: v }))}
              placeholder="Built-in Reference"
            />
          ) : (
            <NGInput
              placeholder={"Value"}
              value={envVar.valueBody}
              onChange={(e) => envVar.setValueBody(e.target.value)}
            />
          )}
          {/* && envVar.metadata.supportsProperties */}
          {/* Not using this because it requires to fetch secrets initially to see their types */}
          {envVar.valuePrefix === "cpln://secret/" ? (
            <NGCombobox
              value={envVar.valueSuffix}
              onChange={envVar.setValueSuffix}
              options={(envVar.metadata.properties || []).map((v: string) => ({ label: v, value: v }))}
              placeholder="Property"
              showNoItems={false}
            />
          ) : null}
        </InputGroup>

        {/* Buttons */}
        <NGButton
          disabled={isDisabled}
          variant={isUpdating ? "primary" : "action"}
          outlined
          onClick={isUpdating ? onUpdate : onAdd}
          className={`flex-grow`}
        >
          {isUpdating ? "Save" : "Add"}
        </NGButton>
        {isUpdating && (
          <NGButton variant="secondary" outlined renderIcon={(_, props) => <X {...props} />} onClick={onCancelEdit} />
        )}
      </div>

      {/* Reveal Secret Buttons */}
      {canRevealSecret ? (
        <div className="flex justify-end gap-2 mt-2">
          {/* Reveal & Suggest Properties Button */}
          {canSuggestProperties ? (
            <NGButton
              onClick={() => setMetadata(envVar, secret, true)}
              variant={"secondary"}
              size="small"
              style={{ width: 250 }}
            >
              Reveal & Suggest Properties
            </NGButton>
          ) : null}

          {/* Reveal & Flatten Dictionary Button */}
          {canFlattenDictionary ? (
            <NGButton onClick={() => flattenDictionary()} variant={"secondary"} size="small" style={{ width: 250 }}>
              Reveal & Flatten Dictionary
            </NGButton>
          ) : null}
        </div>
      ) : null}

      {/* Validation Alerts */}
      {isEnvVarNameTaken ? (
        <NGAlert type="error" className="mt-2" message={`The variable name "${envVar.name}" is already in use.`} />
      ) : secretValueHasWhitespace ? (
        <NGAlert
          type="error"
          className="mt-2"
          message={`Empty variable values are not allowed when referencing secrets.`}
        />
      ) : null}

      {/* Alert: Override Inhertied Env Variable */}
      {inherit.isActive && inherit.value && inherit.env.map((e) => e.name).includes(envVar.name) ? (
        <div className="mb-2 flex items-center mt-2">
          <NGAlert
            type="warning"
            renderIcon={() => null as any}
            render={() => {
              return (
                <div className="text-sm">
                  <div>Overrides Inherited Env Variable</div>
                </div>
              );
            }}
            size={"small"}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export const EnvironmentVariable = observer(EnvironmentVariableRaw);
