import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../advanced/providerAutoscaler";
import { ProviderNetworking } from "../../../advanced/providerNetworking";
import { ProviderPreInstallScript } from "../../../advanced/providerPreInstallScript";
import { NGSwitch } from "../../../../../newcomponents/switch";
import { NGLabelText } from "../../../../../newcomponents/text/labelText";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderAWSAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const a = mk8sDraft.provider_aws!;

  const awsTagsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.awsTags`,
    paths: a.awsTags.items.map((i) => `spec.provider.aws.awsTags.${i.firstValue}`),
  });

  return (
    //
    <div>
      <NGInputListMst
        data={a.awsTags}
        className="mb-8"
        label="AWS Tags"
        firstInput={(i) => {
          let invalid = false;
          if (!i.firstValue) {
            invalid = true;
          }
          if (i.firstValue && awsTagsDryRun.message.includes(`.${i.firstValue}`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Key"
              required
              invalid={invalid}
              value={i.firstValue}
              onChange={(e) => i.setFirstValue(e.target.value)}
            />
          );
        }}
        secondInput={(i) => {
          let invalid = false;
          if (i.secondValue && awsTagsDryRun.message.includes(`.${i.secondValue}`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Value"
              required
              invalid={invalid}
              value={i.secondValue}
              onChange={(e) => i.setSecondValue(e.target.value)}
            />
          );
        }}
        invalid={awsTagsDryRun.severity === "error"}
        error={awsTagsDryRun.severity === "error" ? awsTagsDryRun.message : ""}
        warning={awsTagsDryRun.severity === "error" ? "" : awsTagsDryRun.message}
      />
      <NGSwitch className="mb-4" value={a.skipCreateRoles} onChange={(checked) => a.setSkipCreateRoles(checked)}>
        <NGLabelText>Skip Create Roles</NGLabelText>
      </NGSwitch>
      <div className="mb-4">
        <ProviderPreInstallScript provider="aws" preInstallScript={a.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={a.networking} />
      </div>
      <div>
        <ProviderAutoscaler provider="aws" autoscaler={a.autoscaler} />
      </div>
    </div>
  );
};

export const Mk8sCreateProviderAWSAdvanced = observer(Mk8sCreateProviderAWSAdvancedRaw);
