import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGButton } from "../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { getDryRunErrorText } from "../../getDryRunErrorText";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";

type RouteParams = "index";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderGenericNodePoolRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const g = mk8sDraft.provider_generic;
  const np = g.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `generic.nodePools[${index}].`;

  const labelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.generic.nodePools[${index}].labels`,
    paths: np.labels.items.map((i) => `spec.provider.generic.nodePools[${index}].labels.${i.firstValue}`),
  });
  const taintsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.generic.nodePools[${index}].taints`,
    paths: [
      ...np.taints.items.map((_, idx) => `spec.provider.generic.nodePools[${index}].taints[${idx}]`),
      ...np.taints.items.map((_, idx) => `spec.provider.generic.nodePools[${index}].taints[${idx}].key`),
      ...np.taints.items.map((_, idx) => `spec.provider.generic.nodePools[${index}].taints[${idx}].value`),
      ...np.taints.items.map((_, idx) => `spec.provider.generic.nodePools[${index}].taints[${idx}].effect`),
    ],
  });

  return (
    <div>
      <NGFormElement
        name={`${pre}name`}
        label={np.name.label}
        value={np.name.value}
        onChange={np.name.setValue}
        required
        error={!g.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
      />
      <NGInputListMst
        data={np.labels}
        className="mb-8"
        label="Labels"
        firstInput={(i) => {
          let invalid = false;
          if (!i.firstValue) {
            invalid = true;
          }
          if (i.firstValue && labelsDryRun.message.includes(`.${i.firstValue}`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Key"
              required
              invalid={invalid}
              value={i.firstValue}
              onChange={(e) => i.setFirstValue(e.target.value)}
            />
          );
        }}
        secondInput={(i) => {
          let invalid = false;
          if (!i.secondValue) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Value"
              required
              invalid={invalid}
              value={i.secondValue}
              onChange={(e) => i.setSecondValue(e.target.value)}
            />
          );
        }}
        invalid={labelsDryRun.severity === "error"}
        error={labelsDryRun.severity === "error" ? labelsDryRun.message : ""}
        warning={labelsDryRun.severity === "error" ? "" : labelsDryRun.message}
      />
      <NGInputListMst
        data={np.taints}
        className="mb-8"
        label="Taints"
        firstInput={(i, index) => {
          let invalid = false;
          if (!i.firstValue) {
            invalid = true;
          }
          if (i.firstValue && taintsDryRun.message.includes(`[${index}].key`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Key"
              required
              invalid={invalid}
              value={i.firstValue}
              onChange={(e) => i.setFirstValue(e.target.value)}
            />
          );
        }}
        secondInput={(i) => {
          let invalid = false;
          if (!i.secondValue) {
            invalid = true;
          }
          if (i.secondValue && taintsDryRun.message.includes(`[${index}].value`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Value"
              required
              invalid={invalid}
              value={i.secondValue}
              onChange={(e) => i.setSecondValue(e.target.value)}
            />
          );
        }}
        thirdInput={(i) => {
          let invalid = false;
          if (i.thirdValue && taintsDryRun.message.includes(`[${index}].effect`)) {
            invalid = true;
          }

          return (
            <NGSelect
              placeholder="Effect"
              invalid={invalid}
              value={i.thirdValue}
              onChange={(value) => i.setThirdValue(value)}
              options={[
                { label: "NoSchedule", value: "NoSchedule" },
                { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                { label: "NoExecute", value: "NoExecute" },
              ]}
            />
          );
        }}
        invalid={taintsDryRun.severity === "error"}
        error={taintsDryRun.severity === "error" ? taintsDryRun.message : ""}
        warning={taintsDryRun.severity === "error" ? "" : taintsDryRun.message}
      />
      <NGButton
        style={{ width: 220 }}
        variant={"danger"}
        outlined
        onClick={() => {
          g.removeNodePoolAt(np.index);
          navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
        }}
      >
        Delete Node Pool
      </NGButton>
    </div>
  );
};

export const ProviderGenericNodePool = observer(ProviderGenericNodePoolRaw);
