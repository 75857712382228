import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import NGAlert from "../../../../newcomponents/alert";
import { DryRunAlert } from "../../dryRunAlert";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const CreateAddonsAWSELBRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const v = mk8sDraft.addon_awsElb.roleArn;
  return (
    <div>
      <div className="mb-4">
        <DryRunAlert
          key={mk8sDryRun.key}
          canShow={true}
          dryRunResponse={mk8sDryRun.response}
          path={"spec.addOns.awsELB"}
        />
      </div>
      <div className="mb-2">AWS ELB</div>
      <div className="flex gap-2 items-start">
        <NGFormElement name={"awsElb.roleArn"} label={v.label} value={v.value} onChange={v.setValue} error={v.error} />
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={v.setValue}
            path={"spec.addOns.awsELB.roleArn"}
          />
        </div>
      </div>
      {mk8sDraft.provider.value === "aws" ? <NGAlert title="Role ARN is optional with AWS Provider." /> : null}
    </div>
  );
};

export const CreateAddonsAWSELB = observer(CreateAddonsAWSELBRaw);
