import { observer } from "mobx-react-lite";
import * as React from "react";
import { Mk8sDraftNetworkingMobx } from "../../../mst/stores/mk8s.draft.providerNetworking";
import { NGLabel } from "../../../newcomponents/text/label";
import { DryRunAlert } from "./../dryRunAlert";
import { homeLink, request } from "../../../services/cpln";
import { mk8sDryRun } from "../../../mobxStores/dryRun/mk8s";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { NGProviderBrowser } from "./../ngProviderBrowser";
import { Mk8sDraftMobx } from "../../../mst/stores/mk8s.draft";

interface Props {
  clusterDraft: Mk8sDraftMobx;
  networking: Mk8sDraftNetworkingMobx;
  readOnly?: boolean;
}
const ProviderNetworkingRaw: React.FC<Props> = ({ clusterDraft, networking, readOnly }) => {
  const provider = clusterDraft.provider.value;

  const [browserKey, setBrowserKey] = React.useState("");

  return (
    <>
      <NGLabel>Networking</NGLabel>

      <div className="flex gap-2 items-start">
        {readOnly ? (
          <div>
            <NGLabel>Service Network</NGLabel>
            <div className="flex items-center mb-4">
              <div>{networking.serviceNetwork.value}</div>
            </div>
          </div>
        ) : (
          <NGFormElement
            name={"networking.serviceNetwork"}
            label={networking.serviceNetwork.label}
            value={networking.serviceNetwork.value}
            onChange={networking.serviceNetwork.setValue}
            required={networking.serviceNetwork.isRequired}
            error={networking.serviceNetwork.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("serviceNetwork"), render: () => <MoreHorizontal /> },
            ]}
          />
        )}
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={networking.serviceNetwork.setValue}
            path={`spec.provider.${provider}.networking.serviceNetwork`}
          />
        </div>
      </div>
      <div className="flex gap-2 items-start">
        {readOnly ? (
          <div>
            <NGLabel>Pod Network</NGLabel>
            <div className="flex items-center mb-4">
              <div>{networking.podNetwork.value}</div>
            </div>
          </div>
        ) : (
          <NGFormElement
            name={"networking.podNetwork"}
            label={networking.podNetwork.label}
            value={networking.podNetwork.value}
            onChange={networking.podNetwork.setValue}
            required={networking.podNetwork.isRequired}
            error={networking.podNetwork.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("podNetwork"), render: () => <MoreHorizontal /> },
            ]}
          />
        )}
        <div className="mt-6">
          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            onFix={networking.podNetwork.setValue}
            path={`spec.provider.${provider}.networking.podNetwork`}
          />
        </div>
      </div>
      {browserKey === "serviceNetwork" ? (
        <NGProviderBrowser
          title="Browse Service Networks"
          initialValue={networking.serviceNetwork.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "serviceNetworks",
                cluster: clusterDraft.asObject,
              },
            });
            return data.data;
          }}
          onOk={(value) => networking.serviceNetwork.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Service Network"]}
          getData={(item: any) => [item, item]}
        />
      ) : null}
      {browserKey === "podNetwork" ? (
        <NGProviderBrowser
          title="Browse Pod Networks"
          initialValue={networking.podNetwork.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "podNetworks",
                cluster: clusterDraft.asObject,
              },
            });
            return data.data.filter((d: any) => d !== "vpc");
          }}
          onOk={(value) => networking.podNetwork.setValue(value as string)}
          onClose={() => setBrowserKey("")}
          labels={["Pod Network"]}
          getData={(item: any) => [item, item]}
        />
      ) : null}
    </>
  );
};

export const ProviderNetworking = observer(ProviderNetworkingRaw);
