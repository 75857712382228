import * as React from "react";
import { observer } from "mobx-react-lite";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { ProviderAutoscaler } from "../../../advanced/providerAutoscaler";
import { ProviderNetworking } from "../../../advanced/providerNetworking";
import { ProviderPreInstallScript } from "../../../advanced/providerPreInstallScript";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderHetznerAdvancedRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const h = mk8sDraft.provider_hetzner!;

  const hetznerLabelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.hetzner.hetznerLabels`,
    paths: h.hetznerLabels.items.map((i) => `spec.provider.hetzner.hetznerLabels.${i.firstValue}`),
  });
  const floatingIpSelectorDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.hetzner.floatingIPSelector`,
    paths: h.floatingIPSelector.items.map((i) => `spec.provider.hetzner.floatingIPSelector.${i.firstValue}`),
  });

  return (
    //
    <div>
      <NGInputListMst
        data={h.hetznerLabels}
        className="mb-8"
        label="Hetzner Labels"
        firstInput={(i) => {
          let invalid = false;
          if (!i.firstValue) {
            invalid = true;
          }
          if (i.firstValue && hetznerLabelsDryRun.message.includes(`.${i.firstValue}`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Key"
              required
              invalid={invalid}
              value={i.firstValue}
              onChange={(e) => i.setFirstValue(e.target.value)}
            />
          );
        }}
        secondInput={(i) => {
          let invalid = false;
          if (!i.secondValue) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Value"
              required
              invalid={invalid}
              value={i.secondValue}
              onChange={(e) => i.setSecondValue(e.target.value)}
            />
          );
        }}
        invalid={hetznerLabelsDryRun.severity === "error"}
        error={hetznerLabelsDryRun.severity === "error" ? hetznerLabelsDryRun.message : ""}
        warning={hetznerLabelsDryRun.severity === "error" ? "" : hetznerLabelsDryRun.message}
      />
      <NGInputListMst
        data={h.floatingIPSelector}
        className="mb-8"
        label="Floating IP Selector"
        firstInput={(i) => {
          let invalid = false;
          if (!i.firstValue) {
            invalid = true;
          }
          if (i.firstValue && floatingIpSelectorDryRun.message.includes(`.${i.firstValue}`)) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Key"
              required
              invalid={invalid}
              value={i.firstValue}
              onChange={(e) => i.setFirstValue(e.target.value)}
            />
          );
        }}
        secondInput={(i) => {
          let invalid = false;
          if (!i.secondValue) {
            invalid = true;
          }

          return (
            <NGInput
              placeholder="Value"
              required
              invalid={invalid}
              value={i.secondValue}
              onChange={(e) => i.setSecondValue(e.target.value)}
            />
          );
        }}
        invalid={floatingIpSelectorDryRun.severity === "error"}
        error={floatingIpSelectorDryRun.severity === "error" ? floatingIpSelectorDryRun.message : ""}
        warning={floatingIpSelectorDryRun.severity === "error" ? "" : floatingIpSelectorDryRun.message}
      />
      <div className="mb-4">
        <ProviderPreInstallScript provider="hetzner" preInstallScript={h.preInstallScript} />
      </div>
      <div className="mb-4">
        <ProviderNetworking clusterDraft={mk8sDraft} networking={h.networking} />
      </div>
      <div>
        <ProviderAutoscaler provider="hetzner" autoscaler={h.autoscaler} />
      </div>
    </div>
  );
};

export const Mk8sCreateProviderHetznerAdvanced = observer(Mk8sCreateProviderHetznerAdvancedRaw);
