import * as React from "react";
import { Dropdown, Modal, Radio } from "antd";
import { observer } from "mobx-react-lite";
import { Filter, Triangle } from "react-feather";
import { NGButton } from "../../newcomponents/button/Button";
import { NGCheckbox } from "../../newcomponents/checkbox";
import NGAlert from "../../newcomponents/alert";
import { NGInput } from "../../newcomponents/input/input";
import { InfoTooltip } from "../../components/InfoTooltip";

interface ColumnFilters {
  [_: string]: string;
}

interface Props {
  initialValue: string | string[];
  onOk: (value: string | string[]) => void;
  onClose: () => void;
  fetchData: () => Promise<any[]>;
  labels: string[];
  getData: (item: any) => any[];
  title: string;
  rightAlign?: string[];
  distinctFilters?: string[];
  filteredColumns?: string[];
  tooltips?: { label: string; content: string }[];
  multipleChoice?: boolean;
  sortable?: boolean;
  width?: number;
}
const NGProviderBrowserRaw: React.FC<Props> = ({
  initialValue,
  onOk,
  onClose,
  fetchData,
  labels,
  getData,
  title,
  rightAlign = [],
  distinctFilters = [],
  filteredColumns = [],
  tooltips = [],
  multipleChoice = false,
  sortable = false,
  width = 1200,
}) => {
  function getInitialValues() {
    if (!initialValue) {
      return [];
    }
    if (Array.isArray(initialValue)) {
      return initialValue;
    }
    return [initialValue];
  }
  const [selectedValues, setSelectedValues] = React.useState<string[]>(getInitialValues());
  const [items, setItems] = React.useState<any[]>([]);

  // Column specific filter
  const [columnFilters, setColumnFilters] = React.useState<ColumnFilters>({});
  // Capture all distinct values on first render
  // Capture selected ones in another value
  const [allDistinctValues, setAllDistinctValues] = React.useState<{ [_: string]: string[] }>({});
  const [filteredDistinctValues, setFilteredDistinctValues] = React.useState<{ [_: string]: string[] }>({});
  const [filteredOutItemValues, setFilteredOutItemValues] = React.useState<string[]>([]);
  React.useEffect(() => {
    const _allDistinctValues: { [_: string]: string[] } = {};
    const _filteredDistinctValues: { [_: string]: string[] } = {};
    for (const distinctFilter of distinctFilters) {
      _allDistinctValues[distinctFilter] = [];
      _filteredDistinctValues[distinctFilter] = [];
      for (const labelIndexStr in labels) {
        const labelIndex = Number(labelIndexStr);
        const label = labels[labelIndex];

        if (distinctFilter !== label) {
          continue;
        }

        items
          .map(getData)
          .sort(sortData)
          .forEach((data) => {
            const columnData = data[labelIndex + 1];
            const columnValue = Array.isArray(columnData) ? columnData[0] : columnData;
            if (!_allDistinctValues[distinctFilter].includes(columnValue)) {
              _allDistinctValues[distinctFilter].push(columnValue);
            }
          });
      }
    }
    setAllDistinctValues(_allDistinctValues);
    setFilteredDistinctValues(_filteredDistinctValues);
  }, [items]);

  React.useEffect(() => {
    const _filteredOutItemValues: string[] = [];
    for (const labelIndexStr in labels) {
      const labelIndex = Number(labelIndexStr);
      const label = labels[labelIndex];

      const labelFilters = filteredDistinctValues[label];
      if (!labelFilters || labelFilters.length < 1) {
        continue;
      }

      const itemDatas = items.map(getData).sort(sortData);
      for (const itemData of itemDatas) {
        const itemValue = itemData[0];
        const columnData = itemData[labelIndex + 1];
        const columnValue = Array.isArray(columnData) ? columnData[0] : columnData;
        if (_filteredOutItemValues.includes(itemValue)) {
          continue;
        }

        if (!labelFilters.includes(columnValue)) {
          _filteredOutItemValues.push(itemValue);
          continue;
        }
      }
    }

    for (const labelIndexStr in labels) {
      const labelIndex = Number(labelIndexStr);
      const label = labels[labelIndex];

      const columnFilter = columnFilters[label];
      if (!columnFilter || columnFilter.length < 1) {
        continue;
      }

      const itemDatas = items.map(getData).sort(sortData);
      for (const itemData of itemDatas) {
        const itemValue = itemData[0];
        const columnData = itemData[labelIndex + 1];
        const columnValue = Array.isArray(columnData) ? columnData[0] : columnData;
        if (_filteredOutItemValues.includes(itemValue)) {
          continue;
        }

        if (!columnValue.toLowerCase().includes(columnFilter.toLowerCase())) {
          _filteredOutItemValues.push(itemValue);
          continue;
        }
      }
    }
    setFilteredOutItemValues(_filteredOutItemValues);
  }, [columnFilters, filteredDistinctValues]);

  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [sortKey, setSortKey] = React.useState("");
  const [sortType, setSortType] = React.useState<"asc" | "desc" | "none">("none");

  React.useEffect(() => {
    prepare();
  }, []);

  async function prepare() {
    try {
      setIsLoading(true);
      setError("");
      setItems([]);
      const data = await fetchData();
      setItems(data);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      setIsLoading(false);
    }
  }

  function sortData(a: any, b: any) {
    if (sortType === "none") {
      return 0;
    }

    const _labelIndex = labels.indexOf(sortKey);
    const key = _labelIndex + 1;
    let aValData = a[key];
    let bValData = b[key];

    let aVal = Array.isArray(aValData) ? aValData[0] : aValData;
    let bVal = Array.isArray(bValData) ? bValData[0] : bValData;

    if (sortType === "desc") {
      const _bVal = bVal;
      bVal = aVal;
      aVal = _bVal;
    }

    let result = 0;
    if (aVal > bVal) {
      result = -1;
    }
    if (bVal > aVal) {
      result = 1;
    }

    return result;
  }

  return (
    <>
      <Modal
        width={width}
        open={true}
        maskClosable={false}
        title={title}
        onCancel={() => {
          // TODO do not lose data from the draft
          setSelectedValues([]);
          onClose();
        }}
        footer={
          <div className="modal-actions">
            <NGButton
              variant="secondary"
              onClick={() => {
                // TODO do not lose data from the draft
                setSelectedValues([]);
                onClose();
              }}
            >
              Cancel
            </NGButton>
            <NGButton
              variant="primary"
              onClick={() => {
                onOk(multipleChoice ? selectedValues : selectedValues[0]);
                onClose();
              }}
              disabled={selectedValues.length < 1}
            >
              OK
            </NGButton>
          </div>
        }
      >
        <div style={{ maxHeight: "60vh" }} className="overflow-auto w-full">
          {error ? (
            <NGAlert type="error" className="my-4" title="Error" message={error} />
          ) : items.length < 1 ? (
            <div className="py-8 text-center text-lg border border-t-o">
              {isLoading ? "Loading..." : "No Items Found"}
            </div>
          ) : (
            <>
              <div className="flex flex-nowrap w-full">
                {/* Loop through labels */}
                {labels.map((label, labelIndex) => {
                  const isFirstLabel = labelIndex === 0;
                  const isLastLabel = labelIndex === labels.length - 1;
                  const isNameLabel = isFirstLabel;

                  const isRightAligned = rightAlign.includes(label);
                  const hasDistinctFilter = distinctFilters.includes(label);
                  const hasFilteredValues = filteredDistinctValues[label] && filteredDistinctValues[label].length > 0;

                  const canFilter = filteredColumns.includes(label);
                  const tooltip = tooltips.find((t) => t.label === label)?.content;

                  return (
                    <div className="flex flex-col flex-grow">
                      {/* Render label */}
                      <div
                        className={`whitespace-nowrap relative h-10 flex items-center px-2 py-1 table-labels border-y`}
                        style={{
                          borderRadius: 0,
                          borderTopLeftRadius: isFirstLabel ? 6 : 0,
                          borderBottomLeftRadius: isFirstLabel ? 6 : 0,
                          borderTopRightRadius: isLastLabel ? 6 : 0,
                          borderBottomRightRadius: isLastLabel ? 6 : 0,
                        }}
                      >
                        {label}
                        {tooltip ? <InfoTooltip title={tooltip} /> : null}
                        <div className="flex-grow" />
                        {canFilter ? (
                          <NGInput
                            size={"small"}
                            placeholder={`Filter by ${label}`}
                            value={columnFilters[label]}
                            showClear
                            onChange={(e) => setColumnFilters((c) => ({ ...c, [label]: e.target.value }))}
                          />
                        ) : null}
                        {hasDistinctFilter && allDistinctValues[label].length > 0 ? (
                          <Dropdown
                            trigger={["click"]}
                            dropdownRender={() => (
                              <div
                                className="p-2 shadow-sm border overflow-auto"
                                style={{ maxHeight: "50vh", backgroundColor: "var(--color-drop)" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {allDistinctValues[label].map((value) => (
                                  <div
                                    className="p-1"
                                    key={`${label}---${value}`}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      const _filtered = JSON.parse(JSON.stringify(filteredDistinctValues));
                                      let _filteredThis = _filtered[label];
                                      if (!_filteredThis) {
                                        return;
                                      }
                                      if (_filtered[label].includes(value)) {
                                        _filtered[label] = _filtered[label].filter((v: string) => v !== value);
                                      } else {
                                        _filtered[label].push(value);
                                      }
                                      setFilteredDistinctValues(_filtered);
                                    }}
                                  >
                                    <NGCheckbox
                                      checked={filteredDistinctValues[label]?.includes(value)}
                                      // TODO fix
                                      onChange={(e) => {}}
                                    >
                                      <span>{value}</span>
                                    </NGCheckbox>
                                  </div>
                                ))}
                              </div>
                            )}
                          >
                            <button className={`ngfocus color-link-hover`}>
                              <Filter
                                className={`feather-icon ml-2 ${hasFilteredValues ? "fill-current color-link" : ""}`}
                              />
                            </button>
                          </Dropdown>
                        ) : null}
                        {sortable ? (
                          <button
                            className={`ngfocus color-link-hover`}
                            onClick={() => {
                              if (sortKey !== label) {
                                setSortKey(label);
                                setSortType("desc");
                              } else {
                                setSortType(sortType === "none" ? "desc" : sortType === "desc" ? "asc" : "none");
                              }
                            }}
                          >
                            <Triangle
                              style={{ transform: sortKey === label && sortType === "asc" ? "rotate(180deg)" : "" }}
                              className={`feather-icon ml-2 ${
                                sortKey === label && sortType !== "none" ? "fill-current color-link" : ""
                              }`}
                            />
                          </button>
                        ) : null}
                        {isLastLabel ? null : (
                          <div
                            className="absolute"
                            style={{
                              right: 0,
                              width: 1,
                              top: "25%",
                              height: "50%",
                              backgroundColor: "rgba(0, 0, 0, 0.05)",
                            }}
                          />
                        )}
                      </div>

                      {/* Render items for that label value */}
                      {items
                        .map(getData)
                        .sort(sortData)
                        .map((data) => {
                          const itemValue = data[0];
                          const columnData = data[labelIndex + 1];
                          // const columnValue = Array.isArray(columnData) ? columnData[0] : columnData;
                          const displayText: string = (Array.isArray(columnData) ? columnData[1] : columnData) || "";

                          if (filteredOutItemValues.includes(itemValue) && !selectedValues.includes(itemValue)) {
                            return null;
                          }

                          if (isNameLabel) {
                            return (
                              <div className={`whitespace-nowrap h-8 flex-grow px-2 py-1 border-b`}>
                                {multipleChoice ? (
                                  <NGCheckbox
                                    id={`${itemValue}-checkbox}`}
                                    className={"mr-1"}
                                    checked={selectedValues.includes(itemValue)}
                                    onChange={(checked) => {
                                      if (checked && !selectedValues.includes(itemValue)) {
                                        setSelectedValues([...selectedValues, itemValue]);
                                        return;
                                      }
                                      if (!checked && selectedValues.includes(itemValue)) {
                                        setSelectedValues(selectedValues.filter((v) => v !== itemValue));
                                      }
                                    }}
                                  >
                                    <span className={`cursor-pointer color-default`}>{displayText}</span>
                                  </NGCheckbox>
                                ) : (
                                  // TODO we need a single radio button
                                  <>
                                    <Radio
                                      id={`${itemValue}-radio}`}
                                      checked={selectedValues.length > 0 && selectedValues[0] === itemValue}
                                      onChange={() => setSelectedValues([itemValue])}
                                    />
                                    <label
                                      htmlFor={`${itemValue}-${multipleChoice ? "checkbox" : "radio"}}`}
                                      className={`cursor-pointer`}
                                    >
                                      {displayText}
                                    </label>
                                  </>
                                )}
                              </div>
                            );
                          }

                          return (
                            <div
                              className={`whitespace-nowrap h-8 px-2 py-1 border-b  ${
                                isRightAligned ? "flex justify-end text-right" : ""
                              }`}
                            >
                              {isRightAligned ? <pre className="flex-grow">{displayText}</pre> : displayText}
                              {isRightAligned && !!rightAlign ? <div style={{ height: 1, width: 19 }} /> : null}
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export const NGProviderBrowser = observer(NGProviderBrowserRaw);
