import { makeAutoObservable } from "mobx";
import { homeLink, request, resourceLink } from "../../services/cpln";
import { DryRunIssue, DryRunResponse, DryRunSchemaError } from "../../services/utils";
import { v4 as uuidv4 } from "uuid";
import { ConsoleContext } from "../consoleContext/consoleContext";

class Mk8sDryRunClass {
  forEdit: boolean = false;
  id: string = uuidv4();
  key: string = uuidv4();
  response: DryRunResponse = null as any;

  constructor() {
    makeAutoObservable(this);
  }

  reset(forEdit: boolean) {
    this.forEdit = forEdit;
    this.response = null as any;
  }

  setResponse(value: DryRunResponse) {
    this.response = value;
  }

  get hasError() {
    return this.response?.details?.issues?.some((i) => i.severity === "error");
  }

  hasErrorOf(checks: string[]) {
    const filteredIssues = this.response?.details?.issues?.filter((i) => i.severity === "error") || [];
    for (const check of checks) {
      if (filteredIssues.some((i) => i.path.includes(check))) {
        return true;
      }
    }
    return false;
  }

  *run(body: any) {
    try {
      body.links = [{ href: `/org/${ConsoleContext.org}`, rel: "org" }];
      const url = this.forEdit ? resourceLink("mk8s", body.name) : homeLink("mk8s");
      const method = this.forEdit ? "patch" : "post";
      // @ts-ignore
      const res = yield request({ url: url + "?dryRun", body, method: method });
      this.response = processResponse({ ...res.data, message: res.data.message + " - test 1" });
    } catch (e) {
      this.response = processResponse({ ...e.response.data, message: e.response.data.message + " - test 2" });
    }
    this.key = uuidv4();
  }
}

export const mk8sDryRun = new Mk8sDryRunClass();

function processResponse(res: DryRunResponse | DryRunSchemaError): DryRunResponse {
  try {
    if (!isSchemaError(res)) return res as DryRunResponse;

    const schemaErr = res as DryRunSchemaError;

    if (!schemaErr || !schemaErr.details) return null as any;

    let issues: DryRunIssue[] = [];

    issues = schemaErr.details.map((d) => {
      let p = "";

      for (let pathIndexStr in d.path) {
        const pathIndex = Number(pathIndexStr);
        const isFirst = pathIndex === 0;
        const pathPart = d.path[pathIndex];
        const isNumber = !Number.isNaN(Number(pathPart));
        if (isNumber) {
          p += `[${pathPart}]`;
        } else {
          p += `${isFirst ? "" : "."}${pathPart}`;
        }
      }

      return {
        message: d.message || "",
        path: p,
        severity: "error",
      };
    });

    return {
      code: "400",
      details: { issues: issues },
      id: schemaErr.id,
      message: schemaErr.message,
      status: schemaErr.status,
    };
  } catch (e) {
    console.error("Error processing mk8s dry run: ", e.message);
    return null as any;
  }
}

function isSchemaError(res: DryRunResponse | DryRunSchemaError) {
  return !((res as unknown) as DryRunResponse).details?.issues;
}
