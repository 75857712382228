export const BASE_URL = process.env.REACT_APP_DATASERVICE;
export const IS_CYPRESS = !!(window as any).Cypress;
export const IS_TEST = process.env.NODE_ENV === "test";
export const IS_DEVELOPMENT = process.env.NODE_ENV === "development";
export const IS_TEST_OR_DEVELOPMENT = ["development", "test"].includes(process.env.NODE_ENV);
export const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV;
export const IS_DEPLOYMENT_ENV_PROD = process.env.REACT_APP_DEPLOYMENT_ENV === "production";
export const IS_DEPLOYMENT_ENV_STAGING = process.env.REACT_APP_DEPLOYMENT_ENV === "staging";
export const IS_DEPLOYMENT_ENV_TEST = process.env.REACT_APP_DEPLOYMENT_ENV === "test";
export const IS_LOCAL = process.env.REACT_APP_MODE_LOCAL === "true";
export const IS_HEADLESS = process.env.REACT_APP_MODE_HEADLESS === "true";

export const DOCS_URL = process.env.REACT_APP_DOCS;
export const OPENAPI_URL = process.env.REACT_APP_OPENAPI;
export const MARKETING_URL = process.env.REACT_APP_MAIN_URL;

export const IS_HUBSPOT_INTEGRATION_ENABLED = process.env.REACT_APP_ENABLE_HUBSPOT_INTEGRATION === "true";
export const IS_HUBSPOT_ACCOUNT_CREATED_FLOW_ENABLED =
  process.env.REACT_APP_ENABLE_HUBSPOT_ACCOUNT_CREATED_FLOW === "true";
export const HUBSPOT_SERVICE_URL = process.env.REACT_APP_HUBSPOT_SERVICE;
export const HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID;
export const REFERRAL_SERVICE_PARTNER_URL = process.env.REACT_APP_REFERRAL_SERVICE_PARTNER;
export const MARKETPLACE_SERVICE_URL = process.env.REACT_APP_MARKETPLACE_SERVICE;
export const IS_SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLE === "true";
export const FIREWALL_ALLALLOW_REMOVES_PREV_ITEMS =
  process.env.REACT_APP_FIREWALL_ALLALLOW_REMOVES_PREV_ITEMS === "true";
export const LOCATION_SELECTION_ON_ACCOUNT_CREATE_ENABLED =
  process.env.REACT_APP_ENABLE_LOCATION_SELECTION_ON_ACCOUNT_CREATE === "true";
export const IPSET_ENABLED = process.env.REACT_APP_ENABLE_IPSET === "true";
export const DIRECT_LB_ENABLED = process.env.REACT_APP_ENABLE_DIRECT_LB === "true";
export const DIRECT_LB_IPSET_ENABLED = process.env.REACT_APP_ENABLE_DIRECT_LB_IPSET === "true";
export const DOWNPAGE_ENABLED = process.env.REACT_APP_ENABLE_DOWNPAGE === "true";
export const MEMORY_AUTOSCALING_ENABLED = process.env.REACT_APP_ENABLE_MEMORY_AUTOSCALING === "true";

export const DEPLOYMENT_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_DEPLOYMENT_UPDATE_INTERVAL_MS);
export const KUBERNETES_READINESS_UPDATE_INTERVAL_MS = Number(
  process.env.REACT_APP_KUBERNETES_READINESS_UPDATE_INTERVAL_MS,
);
export const DOMAIN_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_DOMAIN_UPDATE_INTERVAL_MS);
export const TOKEN_EXP_CHECK_INTERVAL_MS = Number(process.env.REACT_APP_TOKEN_EXP_CHECK_INTERVAL_MS);
export const METRICS_TOKEN_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_METRICS_TOKEN_UPDATE_INTERVAL_MS);
export const MK8S_DASHBOARD_TOKEN_UPDATE_INTERVAL_MS = Number(
  process.env.REACT_APP_MK8S_DASHBOARD_TOKEN_UPDATE_INTERVAL_MS,
);
export const REQUEST_REPLICAS_INTERVAL_MS = Number(process.env.REACT_APP_REQUEST_REPLICAS_INTERVAL_MS);
export const VERSION_CHECK_INTERVAL_MS = Number(process.env.REACT_APP_VERSION_CHECK_INTERVAL_MS);
export const AGENT_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_AGENT_UPDATE_INTERVAL_MS);
export const ALL_DOMAINS_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_ALL_DOMAINS_UPDATE_INTERVAL_MS);
export const DBC_BEFORE_READY_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_DBC_BEFORE_READY_UPDATE_INTERVAL_MS);
export const DBC_AFTER_READY_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_DBC_AFTER_READY_UPDATE_INTERVAL_MS);
export const VOLUMESET_UPDATE_INTERVAL_MS = Number(process.env.REACT_APP_VOLUMESET_UPDATE_INTERVAL_MS);
export const VOLUMESET_COMMANDS_INTERVAL_MS = Number(process.env.REACT_APP_VOLUMESET_COMMANDS_INTERVAL_MS);

export const DISABLE_SENTRY_ON_LOCAL = IS_LOCAL && process.env.REACT_APP_DISABLE_SENTRY === "true";

export const STORAGE_KEY_DEFAULT_VARIABLES: string = process.env.REACT_APP_STORAGE_KEY_DEFAULT_VARIABLES as string;
export const STORAGE_KEY_LOG_ORDER_BY: string = process.env.REACT_APP_STORAGE_KEY_LOG_ORDER_BY as string;
export const STORAGE_KEY_LOG_TABLE_TIMESTAMP: string = process.env.REACT_APP_STORAGE_KEY_LOG_TABLE_TIMESTAMP as string;
export const STORAGE_KEY_LOG_TABLE_LABELS: string = process.env.REACT_APP_STORAGE_KEY_LOG_TABLE_LABELS as string;
export const STORAGE_KEY_LOG_TABLE_WRAPPED_LINES: string = process.env
  .REACT_APP_STORAGE_KEY_LOG_TABLE_WRAPPED_LINES as string;
export const STORAGE_KEY_LOG_TABLE_PRETTIFY_JSON: string = process.env
  .REACT_APP_STORAGE_KEY_LOG_TABLE_PRETTIFY_JSON as string;
export const STORAGE_KEY_USER_ACTIVE_TIMESTAMP: string = process.env
  .REACT_APP_STORAGE_KEY_USER_ACTIVE_TIMESTAMP as string;
export const USER_ACTIVE_THRESHOLD_MS: number =
  Number(process.env.REACT_APP_USER_ACTIVE_THRESHOLD_MIN || "1") * 60 * 1000;
export const STORAGE_KEY_TRIAL_ACTIVE_TAB: string = "TRIAL_ACTIVE_TAB";
export const STORAGE_KEY_LOGGED_OUT_STATE = "LOGGED_OUT_STATE";

if (IS_DEVELOPMENT) {
  console.info("env", process.env);
}
