import * as React from "react";
import { notification } from "antd";
import { observer } from "mobx-react-lite";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { CreateLayout } from "../../../components/create/layout";
import { CreateFormLink } from "../../org/create/create";
import { StringMobx, StringModel } from "../../../mobxDataModels/stringModel";
import { NameModel } from "../../../mobxDataModels/nameModel";
import { GVCCreateGeneral } from "./general";
import { useGVCEmitter } from "../../../emitters/gvc";
import { homeLink, request } from "../../../services/cpln";
import { TagsNewModel } from "../../../mobxDataModels/tagsNewModel";
import { TagsNew } from "../../../components/create/tags";
import { UserData } from "../../../mobxStores/userData/userData";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { EnvVarsModel } from "../../../mobxDataModels/envVarsModel";
import { EnvironmentVariables } from "../../workload/container/environmentVariables";
import { GVCCreateLocations } from "./locations";
import { GVCCreateLoadBalancer } from "./loadBalancer";
import { NumberModel } from "../../../mobxDataModels/numberModel";
import { BasePathContext, useBasePath } from "../../../reactContexts/basePathContext";
import { PromptContext } from "../../../mobxStores/prompt/prompt";
import { NGFormData } from "../../../mobxStores/ngFormData";
import { NGFormContext } from "../../../reactContexts/ngFormContext";
import { AuthContent } from "../../../components/detail/authContent";
import { CplnJwtDraftMobx, CplnJwtDraftModel } from "../../../mst/stores/cpln.jwt.client.draft";
import { BooleanModel } from "../../../mobxDataModels/booleanModel";
import { ListOfItemsModel } from "../../../mobxDataModels/listOfItemsModel";
import { GVCCreatePullSecrets } from "./pullSecrets";
import { DOWNPAGE_ENABLED } from "../../../envVariables";

const GVCCreateRaw: React.FC = () => {
  const basePath = useBasePath("/gvc/-create/*");

  const formDataRef = React.useRef(new NGFormData());

  const navigate = useNavigate();
  const gvcEmitter = useGVCEmitter();
  const { pathname } = useLocation();

  const [isDedicatedLoadBalancerEnabled, setIsDedicatedLoadBalancerEnabled] = React.useState(false);
  const trustedProxiesRef = React.useRef<StringMobx>(
    NumberModel.create({ label: "Trusted Proxies", min: 0, max: 2, initialValue: 0, isRequired: true }),
  );
  const redirectRef = React.useRef<StringMobx>(StringModel.create({ label: "Redirect 5xx", initialValue: "" }));

  const authDraftRef = React.useRef<CplnJwtDraftMobx>(
    CplnJwtDraftModel.create({
      enableAuthFilter: BooleanModel.create({ initialValue: false }),
      name: StringModel.create({ label: "Name", isRequired: true, initialValue: "" }),
      issuer: StringModel.create({
        label: "Issuer",
        isRequired: true,
        initialValue: "",
        validationKey: "url",
      }),
      excludedWorkloads: ListOfItemsModel.create({
        _items: [],
      }),
      audiences: ListOfItemsModel.create({
        _items: [],
      }),
      claim_to_headers: ListOfItemsModel.create({
        _items: [],
      }),
      remote_jwks_http_uri: StringModel.create({
        label: "JWKS HTTP URI",
        isRequired: true,
        initialValue: "",
        validationKey: "url",
      }),
      remote_jwks_http_uri_timeout: NumberModel.create({
        label: "Default URI Timeout (seconds)",
        min: 0,
        initialValue: "",
        isRequired: true,
      }),
      rules: ListOfItemsModel.create({
        _items: [
          {
            firstValue: "/metric",
            secondValue: "true",
          },
          {
            firstValue: "/",
            secondValue: "false",
          },
        ],
      }),
    }),
  );

  const nameRef = React.useRef(NameModel.create());
  const descriptionRef = React.useRef(StringModel.create({ label: "Description" }));

  const [locationLinks, setLocationLinks] = React.useState<string[]>([]);
  const [pullSecretLinks, setPullSecretLinks] = React.useState<string[]>([]);

  const envRef = React.useRef(EnvVarsModel.create());
  const tagsRef = React.useRef(TagsNewModel.create());

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const startingPage = pathname.split("/-create")[0] + "/-create/-general";
    navigate(startingPage, { replace: true });
  }, []);

  function getGVCObject() {
    const name = nameRef.current.value;
    const body: any = {
      name,
      description: descriptionRef.current.value || nameRef.current.value,
      tags: tagsRef.current.asObject,
      spec: {
        loadBalancer: {
          dedicated: isDedicatedLoadBalancerEnabled,
        },
      },
    };

    if (
      isDedicatedLoadBalancerEnabled &&
      trustedProxiesRef.current.value.length > 0 &&
      trustedProxiesRef.current.isValid
    ) {
      body.spec.loadBalancer.trustedProxies = Number(trustedProxiesRef.current.value);
    }
    if (DOWNPAGE_ENABLED) {
      if (isDedicatedLoadBalancerEnabled && redirectRef.current.value.length > 0) {
        body.spec.loadBalancer.redirect = { class: { "5xx": redirectRef.current.value } };
      }
    }
    if (envRef.current.asArray.length > 0) {
      body.spec.env = envRef.current.asArray;
    }
    if (locationLinks.length > 0) {
      body.spec.staticPlacement = { locationLinks: locationLinks };
    }
    if (pullSecretLinks.length > 0) {
      body.spec.pullSecretLinks = pullSecretLinks;
    }
    if (authDraftRef.current.enableAuthFilter.value) {
      body.spec.sidecar = { envoy: authDraftRef.current.asObject };
    }

    return { kind: "gvc", ...body };
  }

  async function onConfirm() {
    try {
      setIsLoading(true);
      await request({ method: "post", url: homeLink("gvc"), body: getGVCObject() });
      notification.success({
        message: "Success",
        description: "Created GVC",
      });
      gvcEmitter.emit("GVC_CREATED");
      await UserData.requestGVCNames();
      ConsoleContext.setGVC(nameRef.current.value);
      await UserData.requestHasWorkload();
      PromptContext.setIsDisabled(true);
      setIsLoading(false);
      ConsoleContext.setRedirect(`/console/org/${ConsoleContext.org}/gvc/${nameRef.current.value}`);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.error({
        message: "Failed",
        description: errorMessage,
      });
    }
  }

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-create");
  }

  const links: CreateFormLink[] = [
    { name: "General", value: `-general`, isActive: true },
    {
      name: "Locations",
      value: `-locations`,
      isActive: nameRef.current.isValid,
    },
    {
      name: "Pull Secrets",
      value: `-pullsecrets`,
      isActive: nameRef.current.isValid,
    },
    {
      name: "Load Balancer",
      value: `-loadbalancer`,
      isActive: nameRef.current.isValid,
    },
    {
      name: "Auth",
      value: `-auth`,
      isActive: nameRef.current.isValid,
    },
    {
      name: "Env Variables",
      value: `-env`,
      isActive:
        nameRef.current.isValid && (!authDraftRef.current.enableAuthFilter.value || authDraftRef.current.isValid),
    },
    {
      name: "Tags",
      value: `-tags`,
      isActive:
        nameRef.current.isValid && (!authDraftRef.current.enableAuthFilter.value || authDraftRef.current.isValid),
    },
  ].filter(Boolean) as any;

  const next = {
    isActive: false,
    label: "Next",
    url: "/",
  };

  if (pathname.includes("-general")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Locations)";
    next.url = `-locations`;
  } else if (pathname.includes("-locations")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Pull Secrets)";
    next.url = `-pullsecrets`;
  } else if (pathname.includes("-pullsecrets")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Load Balancer)";
    next.url = `-loadbalancer`;
  } else if (pathname.includes("-loadbalancer")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Auth)";
    next.url = `-auth`;
  } else if (pathname.includes("-auth")) {
    if (nameRef.current.isValid && (!authDraftRef.current.enableAuthFilter.value || authDraftRef.current.isValid)) {
      next.isActive = true;
    }
    next.label = "Next (Env Variables)";
    next.url = `-env`;
  } else if (pathname.includes("-env")) {
    if (nameRef.current.isValid) {
      next.isActive = true;
    }
    next.label = "Next (Tags)";
    next.url = `-tags`;
  }

  let canCreate = nameRef.current.isValid && tagsRef.current.isValid;
  if (isDedicatedLoadBalancerEnabled && !trustedProxiesRef.current.isValid) {
    canCreate = false;
  }
  if (!envRef.current.isValid) {
    canCreate = false;
  }
  if (authDraftRef.current.enableAuthFilter.value && !authDraftRef.current.isValid) {
    canCreate = false;
  }

  return (
    <BasePathContext.Provider value={basePath}>
      <NGFormContext.Provider value={formDataRef.current}>
        <CreateLayout
          getPreview={getGVCObject}
          next={next}
          shouldBlockNavigation={shouldBlockNavigation}
          title={"Create GVC"}
          name={nameRef.current.value}
          canCreate={canCreate}
          onConfirm={onConfirm}
          isLoading={isLoading}
          links={links}
        >
          <Routes>
            <Route index element={<Navigate to={`-general`} />} />
            <Route
              path={`-general`}
              element={<GVCCreateGeneral name={nameRef.current} description={descriptionRef.current} />}
            />
            <Route path={`-locations`} element={<GVCCreateLocations setLocationLinks={setLocationLinks} />} />
            <Route path={`-pullsecrets`} element={<GVCCreatePullSecrets setPullSecretLinks={setPullSecretLinks} />} />
            <Route
              path={`-loadbalancer`}
              element={
                <GVCCreateLoadBalancer
                  isDedicatedLoadBalancerEnabled={isDedicatedLoadBalancerEnabled}
                  setIsDedicatedLoadBalancerEnabled={setIsDedicatedLoadBalancerEnabled}
                  trustedProxies={trustedProxiesRef.current}
                  redirect={redirectRef.current}
                />
              }
            />
            <Route path={`-auth`} element={<AuthContent kind="gvc" authDraftRef={authDraftRef.current} />} />
            <Route
              path={`-env`}
              element={
                <EnvironmentVariables env={envRef.current} filename={nameRef.current.value} hasDefaultEnv={false} />
              }
            />
            <Route path={`-tags`} element={<TagsNew tags={tagsRef.current} />} />
          </Routes>
        </CreateLayout>
      </NGFormContext.Provider>
    </BasePathContext.Provider>
  );
};

export const GVCCreate = observer(GVCCreateRaw);
