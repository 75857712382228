import * as React from "react";
import { observer } from "mobx-react-lite";
import { WorkloadDraftMobx } from "../../mst/stores/workload.draft";
import { NGSwitch } from "../../newcomponents/switch";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { NGFormElement } from "../../newcomponents/ngformelement/ngformelement";
import { DIRECT_LB_IPSET_ENABLED } from "../../envVariables";
import { NGInputListMst } from "../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../newcomponents/input/input";
import { NGSelect } from "../../newcomponents/select/ngselect";
import { FormButtons } from "../../components/forms/formButtons";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { useSetPromptShouldBlock } from "../../reactHooks/useSetPromptShouldBlock";
import { WorkloadDraftLoadBalancerMobx } from "../../mst/stores/workload.draft.lb";
import { notification } from "antd";
import { inputTransforms } from "../../mobxDataModels/transforms";
import { InfoTooltip } from "../../components/InfoTooltip";
import { NGInputAdapter } from "../../newcomponents/input/inputAdapter";
import NGAlert from "../../newcomponents/alert";

interface Props {
  workloadDraft: WorkloadDraftMobx;
  loadBalancer: WorkloadDraftLoadBalancerMobx;
  patch: (body: any) => Promise<void>;
  workloadLink: string;
}
const LoadBalancerRaw: React.FC<Props> = ({ workloadDraft, loadBalancer: lb, patch, workloadLink }) => {
  const d = lb.direct;

  const [isLoading, setIsLoading] = React.useState(false);
  const [promptWhen, setPromptWhen] = React.useState(false);

  React.useEffect(() => {
    PromptContext.setWhen(promptWhen);
  }, [promptWhen]);

  useCleanPrompt();
  useSetPromptShouldBlock(shouldBlockNavigation);

  function shouldBlockNavigation(nextLocation: any) {
    const { pathname } = nextLocation;
    return !pathname.includes("/-loadbalancer");
  }

  React.useEffect(() => {
    lb.reset();
    return () => {
      lb.reset();
    };
  }, []);

  React.useEffect(() => {
    const lastItem = d.ports.items[d.ports.items.length - 1];
    if (!lastItem) {
      return;
    }
    if (!!lastItem.secondValue) {
      return;
    }
    lastItem.setSecondValue("TCP");
  }, [d.ports.items.length]);

  React.useEffect(() => {
    setPromptWhen(lb.isDirty);
  }, [lb.isDirty]);

  let geoHeadersError = "";
  if (lb.enabledGeoHeaders) {
    if (!lb.geoHeaderAsn.value && !lb.geoHeaderCity.value && !lb.geoHeaderCountry.value && !lb.geoHeaderRegion.value) {
      geoHeadersError = "At least one header must be set when geo headers are enabled";
    }
    const headers = [
      lb.geoHeaderAsn.value,
      lb.geoHeaderCity.value,
      lb.geoHeaderCountry.value,
      lb.geoHeaderRegion.value,
    ].filter(Boolean);
    const headersSet = new Set(headers);
    if (headers.length != headersSet.size) {
      geoHeadersError = "Headers must be unique";
    }
  }

  async function save() {
    try {
      setIsLoading(true);
      await patch(workloadDraft.asPatch);
      lb.confirm();
      notification.success({
        message: "Success",
        description: "Workload is updated",
      });
      setPromptWhen(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      setPromptWhen(false);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="p-4 border rounded w-min mb-4">
        <div className={`flex items-center ${d.enabled ? "mb-4" : ""}`} style={{ width: 900 }}>
          <NGSwitch value={d.enabled} onChange={d.setEnabled}>
            <NGLabelText>Enable Direct Load Balancer</NGLabelText>
          </NGSwitch>
          <InfoTooltip
            title={[
              `Load Balancer charges will be accrued for each location.`,
              `The standard workload endpoints will route traffic to the Direct Workload Load Balancer.`,
              `Any required certificates must be provided by the workload at runtime.`,
            ]}
          />
        </div>
        {d.enabled ? (
          <>
            {DIRECT_LB_IPSET_ENABLED ? (
              <NGFormElement label={"Ip Set"} name={"ipSet"} value={d.ipSet} onChange={d.setIpSet} />
            ) : null}

            <NGInputListMst
              label="Ports"
              data={d.ports}
              firstInput={(i) => (
                <NGInput
                  value={i.firstValue}
                  onChange={(e) => i.setFirstValue(inputTransforms.number[0](e.target.value))}
                  placeholder="External Port"
                  required
                  invalid={
                    !i.firstValue ||
                    Number.isNaN(Number(i.firstValue)) ||
                    Number(i.firstValue) < 22 ||
                    Number(i.firstValue) > 32768
                  }
                />
              )}
              secondInput={(i) => (
                <NGSelect
                  value={i.secondValue}
                  onChange={i.setSecondValue}
                  placeholder="Protocol"
                  options={[
                    { label: "TCP", value: "TCP" },
                    { label: "UDP", value: "UDP" },
                  ]}
                />
              )}
              thirdInput={(i) => (
                <NGInput
                  value={i.thirdValue}
                  onChange={(e) => i.setThirdValue(inputTransforms.number[0](e.target.value))}
                  placeholder="Container Port"
                  invalid={
                    !!i.thirdValue &&
                    (Number.isNaN(Number(i.thirdValue)) ||
                      Number(i.thirdValue) < 80 ||
                      Number(i.thirdValue) > 65535 ||
                      [8012, 8022, 9090, 9091, 15000, 15001, 15006, 15020, 15021, 15090, 41000].includes(
                        Number(i.thirdValue),
                      ))
                  }
                />
              )}
              fourthInput={(i) => (
                <NGSelect
                  value={i.fourthValue}
                  onChange={i.setFourthValue}
                  placeholder="Scheme"
                  options={[
                    { label: "http", value: "http" },
                    { label: "tcp", value: "tcp" },
                    { label: "https", value: "https" },
                    { label: "ws", value: "ws" },
                    { label: "wss", value: "wss" },
                  ]}
                />
              )}
              invalid={!d.isPortsValid}
              error={d.portsError}
            />
          </>
        ) : null}
      </div>
      <div className="p-4 border rounded w-min">
        <div className={`flex items-center ${lb.enabledGeoHeaders ? "mb-4" : ""}`} style={{ width: 450 }}>
          <NGSwitch value={lb.enabledGeoHeaders} onChange={lb.setEnabledGeoHeaders}>
            <NGLabelText>Enable Geo Headers</NGLabelText>
          </NGSwitch>
        </div>
        {lb.enabledGeoHeaders ? (
          <>
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderAsn}
              infoContents={["The geo asn header."]}
            />
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderCity}
              infoContents={["The geo city header."]}
            />
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderCountry}
              infoContents={["The geo country header."]}
            />
            <NGInputAdapter
              className="mb-4"
              style={{ width: 450 }}
              data={lb.geoHeaderRegion}
              infoContents={["The geo region header."]}
            />
          </>
        ) : null}
        {geoHeadersError ? <NGAlert type={"error"} title={geoHeadersError} /> : null}
      </div>
      <div className="mt-8 flex items-center">
        <FormButtons
          loading={isLoading}
          onReset={() => lb.reset()}
          onSave={save}
          resetDisabled={!lb.isDirty || isLoading}
          saveDisabled={!lb.isDirty || !lb.isValid || isLoading}
        />
      </div>
    </>
  );
};

export const LoadBalancer = observer(LoadBalancerRaw);
