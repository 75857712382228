import * as React from "react";
import { ReleaseInstance, ReleaseState, ReleaseStateDeployment } from "../types/release";
import { Table } from "../../../newcomponents/table/table";
import { NGButton } from "../../../newcomponents/button/Button";
import { Modal, notification } from "antd";
import { DateString } from "../../../components/dateString";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { request } from "../../../services/cpln";
import { formatStrings } from "../../../utils/time";
import { useDetailContext } from "../../../components/detail/detailContext";

interface ReleaseStateDeploymentTableItem extends ReleaseStateDeployment {
  isLoadingRollback: boolean;
  isLoadingRollbackThis: boolean;
}

interface Props {
  release: ReleaseInstance;
  state: ReleaseState;
}
export const ReleaseRevisions: React.FC<Props> = ({ release, state }) => {
  const { fetchItem } = useDetailContext();

  const [isLoadingRollbackRevision, setIsLoadingRollbackRevision] = React.useState<number | null>(null);
  const [isLoadingRollback, setIsLoadingRollback] = React.useState(false);

  async function handleRollback(revision: number) {
    Modal.confirm({
      title: "Are you sure?",
      content: `App release will be rolled back to the state at revision ${revision}.`,
      okText: "Rollback",
      okButtonProps: { type: "primary" },
      cancelText: "Cancel",
      cancelButtonProps: { type: "primary", disabled: isLoadingRollback },
      onOk: async () => {
        try {
          setIsLoadingRollbackRevision(revision);
          setIsLoadingRollback(true);
          const { data } = await request({
            service: "marketplace",
            method: "post",
            url: `/org/${ConsoleContext.org}/release/${release.name}/-rollback`,
            body: {
              release: release.name,
              revision: revision,
            },
          });
          console.log("response on rollback", data);
          notification.success({ message: "Success", description: "Rolled back release" });
          setIsLoadingRollbackRevision(null);
          setIsLoadingRollback(false);
          if (fetchItem) {
            fetchItem();
          }
        } catch (e) {
          setIsLoadingRollbackRevision(null);
          setIsLoadingRollback(false);
          console.log(e);
          notification.warning({ message: "Failed", description: e.message });
        }
      },
    });
  }

  const releaseStateDeploymentTableItems: ReleaseStateDeploymentTableItem[] = state.deployments
    .map((d) => {
      return {
        ...d,
        isLoadingRollback: isLoadingRollback,
        isLoadingRollbackThis: isLoadingRollbackRevision === d.revision,
      };
    })
    .toReversed();

  return (
    //
    <>
      <Table<ReleaseStateDeploymentTableItem>
        columns={[
          {
            id: "revision",
            label: "Revision",
            size: 100,
          },
          {
            id: "description",
            label: "Description",
          },
          {
            id: "status",
            label: "Status",
          },
          {
            id: "updated",
            label: "Updated",
            cell: ({ getValue }) => {
              return <DateString iso={getValue()} format={formatStrings.log} />;
            },
          },
          {
            id: "actions",
            label: "Actions",
            cell: (p) => {
              const item = p.row.original;

              if (item.revision === state.deployments[state.deployments.length - 1].revision) {
                return null;
              }

              return (
                <NGButton
                  variant="secondary"
                  onClick={() => handleRollback(item.revision)}
                  size={"small"}
                  disabled={item.isLoadingRollback}
                  loading={item.isLoadingRollbackThis}
                >
                  Rollback
                </NGButton>
              );
            },
          },
        ]}
        data={releaseStateDeploymentTableItems}
        title={"Revisions"}
        tableId="release-revisions-update1"
        hideSettings
      />
    </>
  );
};
