import * as React from "react";
import { Dropdown, notification } from "antd";
import { NGButton } from "../../button/Button";
import { ChevronDown } from "react-feather";
import { _Kind } from "../../../mst/kinds";
import { TerraformContext } from "../../../mobxStores/terraform/terraformContext";
import { Kind } from "../../../schema/types/base";
import { itemHelpers as itemHelpersBase } from "../../../mst/kinds/item.helpers";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { observer } from "mobx-react-lite";
import { Theme } from "../../../mobxStores/uiData/theme";
import { TerraformImportModal } from "../../../components/modals/tfImportModal";
import { ItemType } from "antd/es/menu/interface";

interface IProps {
  kind: Kind;
  selectionLinks: string[];
  options?: { key: string; group?: string; label: string; danger?: boolean; disabled?: boolean; callback: Function }[];
}
const TableActionsDropdownRaw = ({ kind, selectionLinks, options = [] }: IProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [terraformImportCommand, setTerraformImportCommand] = React.useState("");

  const itemHelpers = itemHelpersBase({ org: ConsoleContext.org!, gvc: ConsoleContext.gvc, kind: kind });

  const canExportToTerraform: boolean = TerraformContext.supportedKinds.includes(kind as any);
  const keyFunctionMap: { [_: string]: Function } = {
    "export-json": () => itemHelpers.handleExportJSON(selectionLinks, false),
    "export-yaml": () => itemHelpers.handleExportYaml(selectionLinks, false),
    "export-json-slim": () => itemHelpers.handleExportJSON(selectionLinks, true),
    "export-yaml-slim": () => itemHelpers.handleExportYaml(selectionLinks, true),
    "export-terraform": () => itemHelpers.handleExportTerraform(selectionLinks),
  };
  const menuExportItems = [
    { key: "export-json", label: "JSON" },
    { key: "export-yaml", label: "YAML" },
    { key: "export-json-slim", label: "JSON Slim" },
    { key: "export-yaml-slim", label: "YAML Slim" },
  ];
  if (canExportToTerraform) {
    menuExportItems.push({ key: "export-terraform", label: "Terraform" });
  }
  const items: ItemType[] = [
    {
      key: "ignore-export",
      label: "Export",
      children: menuExportItems,
    },
  ];
  for (const option of options) {
    keyFunctionMap[option.key] = option.callback;
    if (!!option.group) {
      const groupKey = option.group.replaceAll(" ", "-").toLowerCase();
      let itemGroup = items.find((item) => item?.key === groupKey);
      if (!itemGroup) {
        items.push({ key: groupKey, label: option.group, children: [] });
        itemGroup = items.find((item) => item?.key === groupKey);
      }
      (itemGroup! as any).children.push({
        key: option.key,
        label: option.label,
        danger: option.danger || false,
        disabled: option.disabled || false,
      });
    } else {
      items.push({
        key: option.key,
        label: option.label,
        danger: option.danger || false,
        disabled: option.disabled || false,
      });
    }
  }

  async function handleActionsMenuClick(e: any) {
    try {
      setIsLoading(true);
      const fn = keyFunctionMap[e.key];
      const response = await fn();

      if (e.key == "export-terraform") {
        setTerraformImportCommand(response);
      }
    } catch (e) {
      notification.error({ message: "Failed", description: e.message });
    }
    setIsLoading(false);
  }

  return (
    <>
      <Dropdown
        menu={{ onClick: handleActionsMenuClick, items: items }}
        placement={"bottom"}
        trigger={["click"]}
        disabled={isLoading}
      >
        <NGButton
          loading={isLoading}
          disabled={isLoading}
          size={"normal"}
          outlined={Theme.theme === "light"}
          variant="primary"
          renderIcon={(hover, props) => <ChevronDown {...props} size={18} />}
        >
          Actions
        </NGButton>
      </Dropdown>
      {terraformImportCommand ? (
        <TerraformImportModal command={terraformImportCommand} onClose={() => setTerraformImportCommand("")} />
      ) : null}
    </>
  );
};

export const TableActionsDropdown = observer(TableActionsDropdownRaw);
