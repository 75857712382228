import { defaultCiphers } from "../mst/kinds/domain/ciphers";

export const _defaultValues = {
  pullSecretTypes: ["gcp", "docker", "ecr"],
  networkResource: {
    discover: "ip", // ip or fqdn
  },
  nativeNetworkResource: {
    provider: "aws", // aws, gcp
  },
  port: {
    min: 80,
    max: 65535,
    default: 8080,
  },
  initialDelaySeconds: {
    min: 0,
    max: 600,
    default: 0,
  },
  periodSeconds: {
    min: 1,
    max: 60,
    default: 10,
  },
  timeoutSecondsProbe: {
    min: 1,
    max: 60,
    default: 1,
  },
  successThreshold: {
    min: 1,
    max: 20,
    default: 1,
  },
  failureThreshold: {
    min: 1,
    max: 20,
    default: 3,
  },
  target: {
    min: 0,
    max: 20000,
    default: 100,
  },
  minScale: { min: 0, default: 1 },
  maxScale: { min: 0, default: 3 },
  scaleToZeroDelay: { min: 30, default: 300, max: 3600 },
  maxConcurrency: { min: 0, default: 1000, max: 1000 },
  timeoutSecondsOptions: { min: 1, default: 5, max: 600 },
  capacityAI: true,
  allowIP: "0.0.0.0/0",
  methodProbe: "command",
  schemeProbe: "HTTP",
  pathProbe: "/",
  metric: "concurrency",
  inboundAllowType: "none",
  debug: false,
  cpu: "50m",
  memory: "128Mi",
  cpuAmount: "50",
  memoryAmount: "128",
  workload: {
    rolloutOptions: {
      minReadySeconds: {
        default: 0,
      },
      maxSurgeReplicas: {
        default: "25%",
      },
      scalingPolicy: {
        default: "OrderedReady",
      },
    },
    options: {
      metricPercentile: "p50",
    },
    container: {
      port: {
        protocol: "http",
        number: 8080,
      },
      gpu: {
        default: "t4",
        options: ["t4", "a10g"],
      },
    },
  },
  identity: {
    ngs: {
      resp: {
        max: {
          min: -1,
          default: 1,
        },
      },
      subs: {
        min: -1,
        default: -1,
      },
      data: {
        min: -1,
        default: -1,
      },
      payload: {
        min: -1,
        default: -1,
      },
    },
  },
  org: {
    logging: {
      coralogix: {
        cluster: "coralogix.com",
      },
      datadog: {
        host: "http-intake.logs.datadoghq.com",
      },
      logzio: {
        listenerHost: "listener.logz.io",
      },
      fluentd: {
        port: 24224,
      },
    },
  },
  domain: {
    value: "none",
    cors: {
      maxAge: "24h",
      allowCredentials: false,
    },
    tls: {
      cipherSuites: defaultCiphers,
      minProtocolVersion: "TLSV1_2",
    },
    port: {
      number: {
        options: [443, 80, 1443, 3000, 3001, 3002, 3003, 3004, 5912, 8443, 8444, 50051, 50052, 5912],
      },
      protocol: {
        options: ["http", "http2", "tcp"],
      },
    },
  },
  volumeset: {
    initialCapacity: {
      min: 10,
      default: 10,
    },
    performanceClass: {
      default: "general-purpose-ssd",
      options: ["general-purpose-ssd", "high-throughput-ssd"],
    },
    fileSystemType: {
      default: "ext4",
      options: ["xfs", "ext4"],
    },
    snapshots: {
      retentionDurationAmount: {
        min: 1,
        default: "7",
      },
      retentionDurationUnit: {
        default: "d",
      },
    },
    autoscaling: {
      maxCapacity: {
        min: 10,
        default: 10,
      },
      minFreePercentage: {
        min: 1,
        max: 100,
        default: 1,
      },
      scalingFactor: {
        min: 1.1,
        default: 1.1,
      },
    },
  },
  mk8s: {
    firewall: [
      {
        sourceCIDR: "0.0.0.0/0",
        description: "Default allow-all rule",
      },
    ],
    provider: {
      hetzner: {
        image: "ubuntu-20.04",
      },
      common: {
        autoscaler: {
          expander: ["most-pods"],
          unneededTime: "10m",
          unreadyTime: "20m",
          utilizationThreshold: 0.7,
        },
      },
    },
  },
};
