import * as React from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate } from "react-router-dom";
import { WorkloadDraftMobx } from "../../../mst/stores/workload.draft";
import { AutoScalingDoc } from "../autoScalingDoc";
import { BasePathContext } from "../../../reactContexts/basePathContext";
import { NGButton } from "../../../newcomponents/button/Button";
import { NGSwitch } from "../../../newcomponents/switch";
import { NGInputAdapter } from "../../../newcomponents/input/inputAdapter";
import { NGLabel } from "../../../newcomponents/text/label";
import { NGSelect } from "../../../newcomponents/select/ngselect";
import { NGFormElement } from "../../../newcomponents/ngformelement/ngformelement";
import { NGLabelText } from "../../../newcomponents/text/labelText";
import { NGInput } from "../../../newcomponents/input/input";
import { NGFormLabel } from "../../../newcomponents/text/formLabel";
import clsx from "clsx";
import { NGError } from "../../../newcomponents/text/error";
import { InfoTooltip } from "../../../components/InfoTooltip";
import NGAlert from "../../../newcomponents/alert";
import { Location } from "../../../schema/types/location";

interface Props {
  draft: WorkloadDraftMobx;
  gvcLocationNames: string[];
}
const WorkloadCreateLocalOptionsItemRaw: React.FC<Props> = ({ draft, gvcLocationNames }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const basePath = React.useContext(BasePathContext);
  const optionsPath = `${basePath}/-options`;
  const defaultOptionsPath = `${basePath}/-options/-default`;
  const localOptionIndex = pathname.split(`${optionsPath}/`)[1];

  const localOptions = draft.localOptions[Number(localOptionIndex)]!;

  function locationFilterFn(locationItem: Location) {
    const alreadySelectedLocations = draft.localOptions
      .filter((_, _index) => Number(localOptionIndex) !== _index)
      .map((locOpt) => locOpt.location.value);
    let showThisLocation = true;
    if (alreadySelectedLocations.includes(locationItem.name)) showThisLocation = false;
    if (!gvcLocationNames.includes(locationItem.name)) showThisLocation = false;
    return showThisLocation;
  }

  async function onDelete() {
    draft.removeLocalOptionsItemAt(Number(localOptionIndex));
    navigate(defaultOptionsPath);
  }

  const revert = {
    capacityAI() {
      localOptions.setCapacityAI(draft.defaultOptions.capacityAI, false);
    },
    debug() {
      localOptions.setDebug(draft.defaultOptions.debug);
    },
    suspend() {
      localOptions.setSuspend(draft.defaultOptions.suspend);
    },
    timeoutSeconds() {
      localOptions.timeoutSeconds.setValue(draft.defaultOptions.timeoutSeconds.value);
    },
    metric() {
      draft.setMetric(draft.defaultOptions.metric.value as any, Number(localOptionIndex));
    },
    targetMetricPercentile() {
      localOptions.target.setValue(draft.defaultOptions.target.value);
      localOptions.metricPercentile.setValue(draft.defaultOptions.metricPercentile.value);
    },
    minScale() {
      localOptions.minScale.setValue(draft.defaultOptions.minScale.value);
    },
    maxScale() {
      localOptions.maxScale.setValue(draft.defaultOptions.maxScale.value);
    },
    scaleToZeroDelay() {
      localOptions.scaleToZeroDelay.setValue(draft.defaultOptions.scaleToZeroDelay.value);
    },
    maxConcurrency() {
      localOptions.maxConcurrency.setValue(draft.defaultOptions.maxConcurrency.value);
    },
  };

  if (!localOptions) {
    return <div style={{ height: 500 }} />;
  }

  let canRevertTargetMetricPercentile = false;
  if (draft.defaultOptions.target.value !== localOptions.target.value) {
    canRevertTargetMetricPercentile = true;
  }
  if (
    localOptions.metric.value === "latency" &&
    draft.defaultOptions.metricPercentile.value !== localOptions.metricPercentile.value
  ) {
    canRevertTargetMetricPercentile = true;
  }

  const currentLocation = localOptions.location.value || "notset";

  return (
    <>
      <NGFormElement
        label={localOptions.location.label}
        name={`${localOptionIndex}location`}
        kind={"location"}
        required
        as={"kindselect"}
        value={localOptions.location.value}
        onChange={localOptions.location.setValue}
        // TODO if we can improve this, we can remove the fetchAll prop from ngkindselect
        kindSelectProps={{ filterOptions: locationFilterFn, fetchAll: true }}
      />
      {draft.isStateful ? null : (
        <>
          <div className={`mb-4 flex items-center`}>
            <NGSwitch
              value={localOptions.capacityAI}
              onChange={(value: boolean) => localOptions.setCapacityAI(value, false)}
              isDisabled={localOptions.metric.value === "cpu"}
            >
              <NGLabelText>Capacity AI</NGLabelText>
            </NGSwitch>
            <InfoTooltip
              title={[
                `Enables intelligent dynamic resource allocation of CPU and Memory for each container by setting resource reservations based on usage data realtime observation.`,
                `Capacity AI can significantly reduce cost. It is well suited for workloads with steady usage characteristics and less ideally suited for workloads with significant spikes in usage.`,
              ]}
            />
            {localOptions.metric.value === "cpu" ? null : draft.defaultOptions.capacityAI !==
              localOptions.capacityAI ? (
              <RevertButton onClick={revert.capacityAI} />
            ) : null}
          </div>
          {draft.defaultOptions.metric.value === "cpu" ? (
            <NGAlert
              className="mb-4"
              message={"Capacity AI is not available when Scaling Strategy is set to CPU Utilization"}
            />
          ) : null}
        </>
      )}
      {draft.isCron ? null : (
        <div className="mb-4 flex items-center">
          <NGSwitch value={localOptions.debug} onChange={localOptions.setDebug}>
            <NGLabelText>Debug</NGLabelText>
          </NGSwitch>
          <InfoTooltip title={`Adds debug response headers when the headers "x-cpln-debug: true" is in the request.`} />
          {draft.defaultOptions.debug !== localOptions.debug ? <RevertButton onClick={revert.debug} /> : null}
        </div>
      )}
      <div className="mb-4 flex items-center">
        <NGSwitch value={localOptions.suspend} onChange={localOptions.setSuspend}>
          <NGLabelText>Suspend</NGLabelText>
        </NGSwitch>
        <InfoTooltip title={`If checked, the workload will be in a suspended mode and not running.`} />
        {draft.defaultOptions.suspend !== localOptions.suspend ? <RevertButton onClick={revert.suspend} /> : null}
      </div>
      {draft.isCron ? null : (
        <div className={clsx("mb-4")}>
          <NGFormLabel
            name={`${currentLocation}timeoutSeconds`}
            required={localOptions.timeoutSeconds.isRequired}
            invalid={!localOptions.timeoutSeconds.isValid}
          >
            {localOptions.timeoutSeconds.label}
          </NGFormLabel>
          <div>Request to the workload will timeout when reaching this number of seconds.</div>
          <div className="flex items-center">
            <NGInput
              value={localOptions.timeoutSeconds.value}
              onChange={(e) => localOptions.timeoutSeconds.setValue(e.target.value)}
              name={`${currentLocation}timeoutSeconds`}
              required={localOptions.timeoutSeconds.isRequired}
              invalid={!localOptions.timeoutSeconds.isValid}
              style={{ width: 450 }}
            />
            <InfoTooltip
              title={[
                `The maximum request duration in seconds before the workload will timeout.`,
                `This timeout amount can be reached when waiting for the workload to respond or when waiting for a new workload to become available when using Autoscaling.`,
                `The minimum value is 1 second and the maximum value is 3600 seconds (1 hour).`,
              ]}
            />
            {draft.defaultOptions.timeoutSeconds.value !== localOptions.timeoutSeconds.value ? (
              <RevertButton onClick={revert.timeoutSeconds} shift />
            ) : null}
          </div>
          <div style={{ width: 450 }}>
            {localOptions.timeoutSeconds.error ? <NGError>{localOptions.timeoutSeconds.error}</NGError> : null}
          </div>
        </div>
      )}
      {draft.isServerless || draft.isStandard || draft.isStateful ? (
        <>
          <div className="mt-4 mb-2 flex items-center gap-2">
            <NGLabel>Auto Scaling</NGLabel>
            <AutoScalingDoc />
          </div>
          <NGLabel>{localOptions.metric.label}</NGLabel>
          <div className="flex items-center mb-4">
            <NGSelect
              style={{ width: 450 }}
              onChange={(value) => draft.setMetric(value as any, localOptionIndex as any)}
              options={localOptions.metric.options}
              value={localOptions.metric.value}
            />
            <InfoTooltip
              className={`mt-6`}
              // TODO update for workload types other than serverless
              title={[
                `Scaling is determined based on a specific metric.`,
                `‘Disabled’ means there’s only one ‘Replica count’. In the API, the Min Scale and Max Scale are both set to the same specified value.`,
                `'concurrency (Concurrent Requests)' uses the number of concurrent requests to the workload.`,
                `'cpu (CPU Utilization)' uses % processor time utilized by the workload.`,
                `'memory' memory in Mi for the target.`,
                `'rps (Requests Per Second)' uses requests per second for the target.`,
                `The number of replicas for this deployment scales up/down in accordance with the chosen scaling strategy and values provided.`,
              ]}
            />
            {draft.defaultOptions.metric.value !== localOptions.metric.value ? (
              <RevertButton className={`self-center`} onClick={revert.metric} />
            ) : null}
          </div>
          {localOptions.metric.value === "disabled" ? null : (
            <>
              {localOptions.metric.value === "latency" ? (
                <>
                  <div className="flex items-end mb-4">
                    <div>
                      <NGLabel>{localOptions.metricPercentile.label}</NGLabel>
                      <NGSelect
                        style={{ width: 215, marginRight: 10 }}
                        onChange={localOptions.metricPercentile.setValue}
                        options={localOptions.metricPercentile.options}
                        value={localOptions.metricPercentile.value}
                      />
                    </div>
                    <div>
                      <NGInputAdapter
                        data={localOptions.target}
                        style={{
                          width: 225,
                        }}
                      />
                    </div>
                    {canRevertTargetMetricPercentile ? (
                      <RevertButton onClick={revert.targetMetricPercentile} shift />
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="flex items-end mb-4">
                  <div>
                    <NGInputAdapter
                      data={localOptions.target}
                      style={{
                        width: 450,
                      }}
                    />
                  </div>
                  {canRevertTargetMetricPercentile ? (
                    <RevertButton onClick={revert.targetMetricPercentile} shift />
                  ) : null}
                </div>
              )}
            </>
          )}
          <div className="flex items-end mb-4">
            <div>
              <NGInputAdapter
                data={localOptions.minScale}
                style={{ width: 450 }}
                infoContents={[
                  `The minimum number of replicas.`,
                  `If set to zero, a new replica becomes active when a request is received, after a few seconds. It is recommended to set Min Scale to at least 2 replicas for production use cases when availability is paramount.`,
                ]}
                description="Minimum number of replicas."
              />
            </div>
            {draft.defaultOptions.minScale.value !== localOptions.minScale.value ? (
              <RevertButton onClick={revert.minScale} shift />
            ) : null}
          </div>
          {localOptions.metric.value === "disabled" ? null : (
            <div className="flex items-end mb-4">
              <div>
                <NGInputAdapter
                  data={localOptions.maxScale}
                  style={{ width: 450 }}
                  infoContents={["The maximum number of replicas."]}
                  description="The maximum number of replicas."
                />
              </div>
              {draft.defaultOptions.maxScale.value !== localOptions.maxScale.value ? (
                <RevertButton onClick={revert.maxScale} shift />
              ) : null}
            </div>
          )}
          {localOptions.metric.value === "disabled" ? null : draft.isServerless || draft.isStandard ? (
            <div className="flex items-end mb-4">
              <div>
                <NGInputAdapter
                  data={localOptions.scaleToZeroDelay}
                  style={{ width: 450 }}
                  infoContents={[
                    `The number of seconds until a scale down event occurs. For example, if the number of concurrent requests drops to a level that warrants a scale down event, the number of replicas will be adjusted after the number of seconds specified by this value.`,
                    `The minimum value is 30 seconds.`,
                  ]}
                  description="Number of seconds (min. 30) before a scale down event."
                />
              </div>
              {draft.defaultOptions.scaleToZeroDelay.value !== localOptions.scaleToZeroDelay.value ? (
                <RevertButton onClick={revert.scaleToZeroDelay} shift />
              ) : null}
            </div>
          ) : null}
          {draft.isServerless ? (
            <div className="flex items-end mb-4">
              <div>
                <NGInputAdapter
                  data={localOptions.maxConcurrency}
                  style={{ width: 450 }}
                  infoContents={[
                    `Maximum number of concurrent requests routed to a replica.`,
                    `If no replicas are available to fulfill the request, the request is queued until a replica is available. Either from existing requests having been completed, or from new replica(s) becoming available as a result of a scale up action.`,
                  ]}
                  description="Maximum number of concurrent requests routed to a replica."
                />
              </div>
              {draft.defaultOptions.maxConcurrency.value !== localOptions.maxConcurrency.value ? (
                <RevertButton onClick={revert.maxConcurrency} shift />
              ) : null}
            </div>
          ) : null}
        </>
      ) : null}
      <div className="mt-8 flex items-center">
        <NGButton style={{ width: 215, marginRight: 10 }} onClick={() => onDelete()} variant={"danger"} outlined>
          Delete Local Options
        </NGButton>
      </div>
    </>
  );
};

export const WorkloadCreateLocalOptionsItem = observer(WorkloadCreateLocalOptionsItemRaw);

interface RevertButtonProps {
  onClick: any;
  className?: string;
  shift?: boolean;
}
export const RevertButton: React.FC<RevertButtonProps> = ({ onClick, className = "", shift = false }) => {
  return (
    <NGButton
      style={{ marginBottom: shift ? 3 : undefined }}
      size={"small"}
      className={`ml-2 ${className}`}
      onClick={onClick}
      variant={"secondary"}
      outlined
    >
      Revert
    </NGButton>
  );
};
