import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, useLocation } from "react-router-dom";
import { DetailLayout } from "../../components/detail/layout";
import { Events } from "../../components/detail/events";
import { AccessReport } from "../../components/detail/accessReport";
import { TagsNew } from "../../components/detail/tagsNew";
import { AuditTrail } from "../../components/detail/auditTrail";
import { Info } from "./info";
import { mk8sMobx } from "../../mst/kinds/mk8s/mk8s";
import { Provider } from "./provider/provider";
import { AddonsRoute } from "./addons/route";
import { Mk8sDraftMobx } from "../../mst/stores/mk8s.draft";
import { mk8sDryRun } from "../../mobxStores/dryRun/mk8s";
import { KubeConfig } from "./kubeConfig";
import { IS_DEPLOYMENT_ENV_TEST, METRICS_TOKEN_UPDATE_INTERVAL_MS } from "../../envVariables";
import { HiddenSectionsMobx } from "../../mobxStores/hiddenSections";
import { Firewall } from "./firewall";
import { BasePathContext, useBasePath } from "../../reactContexts/basePathContext";
import { InnerNavLink } from "../../components/detail/ngNav";
import { AlertCircle, Plus } from "react-feather";
import { FormButtons } from "../../components/forms/formButtons";
import { Tooltip as AntTooltip, notification } from "antd";
import { useDetailContext } from "../../components/detail/detailContext";
import { useCleanPrompt } from "../../reactHooks/useCleanPrompt";
import { PromptContext } from "../../mobxStores/prompt/prompt";
import { useSetPromptShouldBlock } from "../../reactHooks/useSetPromptShouldBlock";
import { useNGFormContext } from "../../reactContexts/ngFormContext";
import { Discovery } from "../../mobxStores/discovery/discovery";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { getLocalToken } from "../../services/cpln";
import { getMetricsUrl } from "../../services/utils";
import { Mk8sLogs } from "./logs";
import { useEventlogs } from "../../reactHooks/useEventlog";
import { useDebounce } from "../../components/table/useDebounce";

// add button
// checkbox on list of items
// scrape annotated checkbox

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
  startDraft: (w: mk8sMobx) => Promise<void>;
}
const Mk8sDetailRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const basePath = useBasePath("/mk8s/:mk8s/*");
  const { pathname } = useLocation();

  const eventlogHook = useEventlogs(mk8s.selfLink);

  const { fetchItem } = useDetailContext();

  const [debouncedHash, setDebouncedHash] = useDebounce("", 500);
  const [isLoading, setIsLoading] = React.useState(false);

  const [metricsTokenFromLocal, setMetricsTokenFromLocal] = React.useState("");
  React.useEffect(() => {
    getLocalToken().then((res) => {
      if (res.accessToken !== "pass") {
        setMetricsTokenFromLocal(res.accessToken);
      }
    });
    const localTokenInterval = setInterval(() => {
      getLocalToken().then((res) => {
        if (res.accessToken !== "pass") {
          setMetricsTokenFromLocal(res.accessToken);
        }
      });
    }, METRICS_TOKEN_UPDATE_INTERVAL_MS);
    return () => {
      clearInterval(localTokenInterval);
    };
  }, []);

  React.useEffect(() => {
    mk8sDryRun.reset(true);

    PromptContext.setOnDiscard(() => mk8sDraft.reset());
  }, []);

  React.useEffect(() => {
    PromptContext.setWhen(mk8sDraft.isDirty);
  }, [mk8sDraft.isDirty]);

  useSetPromptShouldBlock((nextLocation, currentLocation) => {
    const allowedPaths = [
      //
      "-info",
      "-provider",
      "-addons",
      "-firewall",
      "-tags",
    ];

    let shouldBlock = true;
    for (const allowedPath of allowedPaths) {
      if (nextLocation.pathname.includes(allowedPath)) {
        shouldBlock = false;
        break;
      }
    }
    return shouldBlock;
  });

  useCleanPrompt();

  React.useEffect(() => {
    setDebouncedHash(mk8sDraft.hash);
  }, [mk8sDraft.hash]);
  React.useEffect(() => {
    mk8sDryRun.run(mk8sDraft.asObject);
  }, [debouncedHash]);

  function reset() {
    mk8sDraft.reset();
  }

  const dryrunErrors = (mk8sDryRun.response?.details.issues || []).filter((i) => i.severity === "error");

  const anyAddonsDryRun = dryrunErrors.some((i) =>
    [
      "spec.addOns", //
      "spec.addOns.awsWorkloadIdentity",
      "spec.addOns.dashboard",
      "spec.addOns.localPathStorage",
      "spec.addOns.sysbox",
    ].includes(i.path),
  );

  const awsDryRun = dryrunErrors.some((i) => mk8sDraft.provider_aws.dryRun_awsPathList.includes(i.path));
  const awsAdvancedDryRun = dryrunErrors.some((i) => mk8sDraft.provider_aws.dryRun_advancedPathList.includes(i.path));

  const digitaloceanDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_digitalocean.dryRun_digitaloceanPathList.includes(i.path),
  );
  const digitaloceanAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_digitalocean.dryRun_advancedPathList.includes(i.path),
  );

  const ephemeralDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_ephemeral.dryRun_ephemeralPathList.includes(i.path),
  );

  const genericDryRun = dryrunErrors.some((i) => mk8sDraft.provider_generic.dryRun_genericPathList.includes(i.path));
  const genericAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_generic.dryRun_advancedPathList.includes(i.path),
  );

  const hetznerDryRun = dryrunErrors.some((i) => mk8sDraft.provider_hetzner.dryRun_hetznerPathList.includes(i.path));
  const hetznerAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_hetzner.dryRun_advancedPathList.includes(i.path),
  );

  const lambdalabsDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_lambdalabs.dryRun_lambdalabsPathList.includes(i.path),
  );
  const lambdalabsAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_lambdalabs.dryRun_advancedPathList.includes(i.path),
  );

  const linodeDryRun = dryrunErrors.some((i) => mk8sDraft.provider_linode.dryRun_linodePathList.includes(i.path));
  const linodeAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_linode.dryRun_advancedPathList.includes(i.path),
  );

  const oblivusDryRun = dryrunErrors.some((i) => mk8sDraft.provider_oblivus.dryRun_oblivusPathList.includes(i.path));
  const oblivusAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_oblivus.dryRun_advancedPathList.includes(i.path),
  );

  const paperspaceDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_paperspace.dryRun_paperspacePathList.includes(i.path),
  );
  const paperspaceAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_paperspace.dryRun_advancedPathList.includes(i.path),
  );

  const tritonDryRun = dryrunErrors.some((i) => mk8sDraft.provider_triton.dryRun_tritonPathList.includes(i.path));
  const tritonAdvancedDryRun = dryrunErrors.some((i) =>
    mk8sDraft.provider_triton.dryRun_advancedPathList.includes(i.path),
  );

  const ngLinks: InnerNavLink[] = [
    // TODO handle isDirty in innernavlink item
    { label: "Info", url: "-info" },
    {
      label: mk8sDraft.provider.options.find((opt) => opt.value === mk8sDraft.provider.value)!.label,
      url: `-provider/${mk8sDraft.provider.value}`,
      visible: !!mk8sDraft.provider.value,
      disableFold: true,
      renderIcon:
        mk8sDraft.provider.value === "aws"
          ? !mk8sDraft.provider_aws.ui_isAwsValid || awsDryRun
            ? () => (
                <AntTooltip title={"AWS is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "digitalocean"
          ? !mk8sDraft.provider_digitalocean.ui_isDigitalOceanValid || digitaloceanDryRun
            ? () => (
                <AntTooltip title={"Digital Ocean is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "ephemeral"
          ? !mk8sDraft.provider_ephemeral.ui_isEphemeralValid || ephemeralDryRun
            ? () => (
                <AntTooltip title={"Ephemeral is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "generic"
          ? !mk8sDraft.provider_generic.ui_isGenericValid || genericDryRun
            ? () => (
                <AntTooltip title={"Location is required"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "hetzner"
          ? !mk8sDraft.provider_hetzner.ui_isHetznerValid || hetznerDryRun
            ? () => (
                <AntTooltip title={"Hetzner is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "lambdalabs"
          ? !mk8sDraft.provider_lambdalabs.ui_isLambdalabsValid || lambdalabsDryRun
            ? () => (
                <AntTooltip title={"Lambda Labs is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "linode"
          ? !mk8sDraft.provider_linode.ui_isLinodeValid || linodeDryRun
            ? () => (
                <AntTooltip title={"Linode is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "oblivus"
          ? !mk8sDraft.provider_oblivus.ui_isOblivusValid || oblivusDryRun
            ? () => (
                <AntTooltip title={"Oblivus is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "paperspace"
          ? !mk8sDraft.provider_paperspace.ui_isPaperspaceValid || paperspaceDryRun
            ? () => (
                <AntTooltip title={"Paperspace is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : mk8sDraft.provider.value === "triton"
          ? !mk8sDraft.provider_triton.ui_isTritonValid || tritonDryRun
            ? () => (
                <AntTooltip title={"Triton is not configured"}>
                  <AlertCircle className="ml-1 h-4 color-danger" />
                </AntTooltip>
              )
            : undefined
          : undefined,
    },
  ];
  if (mk8sDraft.provider.value === "aws") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/aws/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_aws.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_aws.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.aws.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/aws/-advanced",
      renderIcon:
        !mk8sDraft.provider_aws.ui_isAdvancedValid || awsAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }
  if (mk8sDraft.provider.value === "digitalocean") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/digitalocean/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_digitalocean.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_digitalocean.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.digitalocean.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/digitalocean/-advanced",
      renderIcon:
        !mk8sDraft.provider_digitalocean.ui_isAdvancedValid || digitaloceanAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }
  if (mk8sDraft.provider.value === "ephemeral") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/ephemeral/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_ephemeral.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_ephemeral.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.ephemeral.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
  }
  if (mk8sDraft.provider.value === "generic") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/generic/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_generic.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_generic.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.generic.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid."}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/generic/-advanced",
      renderIcon:
        !mk8sDraft.provider_generic.ui_isAdvancedValid || genericAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "hetzner") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/hetzner/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_hetzner.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_hetzner.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.hetzner.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Dedicated Server Node Pools",
      url: "-provider/hetzner/-dedicated-server-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_hetzner.dedicatedServerNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_hetzner.isDedicatedServerNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.hetzner.dedicatedServerNodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Dedicated Server Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/hetzner/-advanced",
      renderIcon:
        !mk8sDraft.provider_hetzner.ui_isAdvancedValid || hetznerAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "lambdalabs") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/lambdalabs/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_lambdalabs.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_lambdalabs.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.lambdalabs.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Unmanaged Node Pools",
      url: "-provider/lambdalabs/-unmanaged-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_lambdalabs.unmanagedNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_lambdalabs.isUnmanagedNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.lambdalabs.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/lambdalabs/-advanced",
      renderIcon:
        !mk8sDraft.provider_lambdalabs.ui_isAdvancedValid || lambdalabsAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "linode") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/linode/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_linode.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_linode.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.linode.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/linode/-advanced",
      renderIcon:
        !mk8sDraft.provider_linode.ui_isAdvancedValid || linodeAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "oblivus") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/oblivus/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_oblivus.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_oblivus.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.oblivus.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Unmanaged Node Pools",
      url: "-provider/oblivus/-unmanaged-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_oblivus.unmanagedNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_oblivus.isUnmanagedNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.oblivus.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/oblivus/-advanced",
      renderIcon:
        !mk8sDraft.provider_oblivus.ui_isAdvancedValid || oblivusAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "paperspace") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/paperspace/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_paperspace.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_paperspace.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.paperspace.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Unmanaged Node Pools",
      url: "-provider/paperspace/-unmanaged-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_paperspace.unmanagedNodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid || !mk8sDraft.provider_paperspace.isUnmanagedNodePoolNameValid(np.index)
              ? () => (
                  <AntTooltip title={"Unmanaged Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/paperspace/-advanced",
      renderIcon:
        !mk8sDraft.provider_paperspace.ui_isAdvancedValid || paperspaceAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  if (mk8sDraft.provider.value === "triton") {
    ngLinks.push({
      label: "Node Pools",
      url: "-provider/triton/-node-pools",
      disableFold: true,
      links: [
        ...mk8sDraft.provider_triton.nodePools.map((np, npIndex) => ({
          label: np.name.value || "New Node Pool",
          url: String(np.index),
          isDirty: np.isDirty,
          renderIcon:
            !np.isValid ||
            !mk8sDraft.provider_triton.isNodePoolNameValid(np.index) ||
            dryrunErrors.some((i) => i.path.includes(`spec.provider.triton.nodePools[${npIndex}]`))
              ? () => (
                  <AntTooltip title={"Node Pool is not valid"}>
                    <AlertCircle className="ml-1 h-4 color-danger" />
                  </AntTooltip>
                )
              : undefined,
        })),
        { label: "Add", url: "-add", type: "action", renderIcon: () => <Plus /> },
      ],
    });
    ngLinks.push({
      label: "Advanced",
      url: "-provider/triton/-advanced",
      renderIcon:
        !mk8sDraft.provider_triton.ui_isAdvancedValid || tritonAdvancedDryRun
          ? () => (
              <AntTooltip title={"Advanced options are not valid"}>
                <AlertCircle className="ml-1 h-4 color-danger" />
              </AntTooltip>
            )
          : undefined,
    });
  }

  ngLinks.push({
    label: "Add-ons",
    url: "-addons",
    renderIcon:
      !mk8sDraft.ui_isAddonsValid || anyAddonsDryRun
        ? () => (
            <AntTooltip title={"Addons are not valid."}>
              <AlertCircle className="ml-1 h-4 color-danger" />
            </AntTooltip>
          )
        : undefined,
    links: [
      mk8sDraft.addon_awsEcr.isEnabled
        ? {
            label: "AWS ECR",
            url: "-aws-ecr",
            renderIcon:
              !mk8sDraft.addon_awsEcr.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.awsECR`))
                ? () => (
                    <AntTooltip title={"AWS ECR addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.provider.value === "aws" && mk8sDraft.addon_awsEfs.isEnabled
        ? {
            label: "AWS EFS",
            url: "-aws-efs",
            renderIcon:
              !mk8sDraft.addon_awsEfs.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.awsEFS`))
                ? () => (
                    <AntTooltip title={"AWS EFS addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_awsElb.isEnabled
        ? {
            label: "AWS ELB",
            url: "-aws-elb",
            renderIcon:
              !mk8sDraft.addon_awsElb.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.awsELB`))
                ? () => (
                    <AntTooltip title={"AWS ELB addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_azureAcr.isEnabled
        ? {
            label: "Azure Container Registry",
            url: "-azure-acr",
            renderIcon:
              !mk8sDraft.addon_azureAcr.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.azureACR`))
                ? () => (
                    <AntTooltip title={"Azure ACR addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_azureWorkloadIdentity.isEnabled
        ? {
            label: "Azure Workload Identity",
            url: "-azure-workload-identity",
            renderIcon:
              !mk8sDraft.addon_azureWorkloadIdentity.isValid ||
              dryrunErrors.some((i) => i.path.includes(`spec.addOns.azureWorkloadIdentity`))
                ? () => (
                    <AntTooltip title={"Azure Workload Identity addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_logs.isEnabled
        ? {
            label: "Logs",
            url: "-logs",
            renderIcon:
              !mk8sDraft.addon_logs.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.logs`))
                ? () => (
                    <AntTooltip title={"Logs addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_metrics.isEnabled
        ? {
            label: "Metrics",
            url: "-metrics",
            renderIcon:
              !mk8sDraft.addon_metrics.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.metrics`))
                ? () => (
                    <AntTooltip title={"Metrics addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
      mk8sDraft.addon_nvidia.isEnabled
        ? {
            label: "NVIDIA",
            url: "-nvidia",
            renderIcon:
              !mk8sDraft.addon_nvidia.isValid || dryrunErrors.some((i) => i.path.includes(`spec.addOns.nvidia`))
                ? () => (
                    <AntTooltip title={"NVIDIA addon is not valid."}>
                      <AlertCircle className="ml-1 h-4 color-danger" />
                    </AntTooltip>
                  )
                : undefined,
          }
        : { label: "filterOut", url: "filterOut" },
    ].filter((l) => l.label !== "filterOut"),
  });
  ngLinks.push({
    label: "Firewall",
    url: "-firewall",
    renderIcon:
      !mk8sDraft.firewall.isValid || dryrunErrors.some((i) => i.path.includes(`spec.firewall`))
        ? () => (
            <AntTooltip title={"Firewall is not valid."}>
              <AlertCircle className="ml-1 h-4 color-danger" />
            </AntTooltip>
          )
        : undefined,
  });
  ngLinks.push({
    label: "Tags",
    url: "-tags",
    renderIcon:
      !mk8sDraft.tags.isValid || dryrunErrors.some((i) => i.path.includes(`tags`))
        ? () => (
            <AntTooltip title={"Tags are not valid."}>
              <AlertCircle className="ml-1 h-4 color-danger" />
            </AntTooltip>
          )
        : undefined,
  });
  ngLinks.push({ type: "separator", label: "", url: "" });
  ngLinks.push({ label: "kubeconfig", url: "-kubeconfig" });
  ngLinks.push({ label: "Logs", url: "-cluster-logs" });
  ngLinks.push({
    label: "Metrics",
    url: "-mk8s-metrics",
    type: "externalLink",
    externalLink: {
      action: `${Discovery.endpoints.grafana.replace("{org}", ConsoleContext.org!)}/auth/process`,
      inputs: [
        { name: "redirect", value: getMetricsUrl("mk8s", { clusterName: mk8s.name, clusterAlias: mk8s.alias }) },
        { name: "idToken", value: metricsTokenFromLocal },
      ],
      method: "POST",
    },
  });
  ngLinks.push({ label: "Events", url: "-events" });
  ngLinks.push({ label: "Audit Trail", url: `-audittrail` });
  ngLinks.push({ label: "Access Report", url: "-accessreport" });

  async function save() {
    try {
      setIsLoading(true);
      await mk8s.patch(mk8sDraft.asBodyObject);
      mk8sDraft.confirm();
      notification.success({
        message: "Success",
        description: "Updated Kubernetes",
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed",
        description: errorMessage,
      });
      if (e.response.status === 409) {
        reset();
        if (fetchItem) {
          await fetchItem();
          notification.info({
            message: "Updated Item",
            description: "Fetched the latest version of the item and discarded changes.",
          });
        }
      }
    }
  }

  return (
    <div style={{ maxWidth: pathname.includes("-cluster-logs") ? undefined : 1600 }}>
      <BasePathContext.Provider value={basePath}>
        <DetailLayout
          links={[]}
          ngLinks={ngLinks}
          useNewNav
          renderButtons={() => {
            return (
              <FormButtons
                onSave={save}
                onReset={() => reset()}
                resetDisabled={isLoading || !mk8sDraft.isDirty}
                saveDisabled={isLoading || !mk8sDraft.isDirty || !mk8sDraft.isValid || mk8sDryRun.hasError}
                loading={isLoading}
              />
            );
          }}
          hideButtons={["-kubeconfig", "-events", "-cluster-logs", "-audittrail", "accessreport"]}
        >
          <Route path={`-info`} element={<Info mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-provider/*`} element={<Provider mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-addons/*`} element={<AddonsRoute mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-firewall`} element={<Firewall mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route path={`-kubeconfig`} element={<KubeConfig mk8s={mk8s} mk8sDraft={mk8sDraft} />} />
          <Route
            path={`-tags`}
            element={<TagsNew tagsMobx={mk8sDraft.tags} item={mk8s} kindPlural={"mk8s"} hideButtons />}
          />
          <Route path={`-cluster-logs`} element={<Mk8sLogs mk8s={mk8s} />} />
          <Route
            path={`-events`}
            element={
              <Events
                kind={"mk8s"}
                eventlogs={eventlogHook.eventlogs}
                isLoading={eventlogHook.isLoading}
                fetchEventlogs={eventlogHook.fetchEventlogs}
              />
            }
          />
          <Route path={`-audittrail`} element={<AuditTrail kind="mk8s" resourceId={mk8s.id} />} />
          <Route path={`-accessreport`} element={<AccessReport itemLink={mk8s.selfLink} />} />
        </DetailLayout>
      </BasePathContext.Provider>
      {IS_DEPLOYMENT_ENV_TEST && HiddenSectionsMobx.isRevealed("debug") ? (
        <div>
          <div>Invalid Reason</div>
          <div>{mk8sDraft.invalidReason || "none"}</div>
          <div>Dry run error</div>
          <div>Has error?: {String(mk8sDryRun.hasError)}</div>
          {mk8sDryRun.hasError ? <pre className="text-xs">{JSON.stringify(mk8sDryRun.response, null, 2)}</pre> : null}
        </div>
      ) : null}
    </div>
  );
};

export const Mk8sDetail = observer(Mk8sDetailRaw);
