import * as React from "react";
import CodeMirror, { ReactCodeMirrorProps, ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { langs } from "@uiw/codemirror-extensions-langs";
import { EditorView } from "@codemirror/view";
import { githubLight, githubDark } from "@uiw/codemirror-theme-github";
import { linter, Diagnostic, lintGutter } from "@codemirror/lint";
import { jsonParseLinter } from "@codemirror/lang-json";
import parser from "js-yaml";
import { Theme } from "../../../mobxStores/uiData/theme";
import clsx from "clsx";

const yamlLinter = linter((view) => {
  const diagnostics: Diagnostic[] = [];
  try {
    // @ts-ignore
    parser.load(view.state.doc);
  } catch (e) {
    const loc = e.mark;
    const from = loc ? loc.position : 0;
    const to = from;
    const severity = "error";
    diagnostics.push({
      from,
      to,
      message: e.message,
      severity,
    });
  }
  return diagnostics;
});

export type CodeEditorLanguages = "json" | "yaml" | "javascript" | "shell" | "markdown" | "hcl";

interface Props {
  value: string;
  setValue: (value: string) => void;
  language: CodeEditorLanguages;
  options: ReactCodeMirrorProps;
  minHeight?: string;
  delay?: boolean;
  // lint support for JSON and YAML
  lint?: boolean;
  noBorder?: boolean;
}

export const CodeEditor: React.FC<Props> = React.memo(function CodeEditor({
  value,
  setValue,
  language,
  options = {},
  minHeight,
  delay = false,
  lint = false,
  noBorder = false,
}) {
  const editorRef = React.useRef<ReactCodeMirrorRef>(null);
  const [canShow, setCanShow] = React.useState(!delay);

  React.useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setCanShow(true);
      }, 250);
    }
  }, []);

  const onChange = React.useCallback((value: any, _: any) => {
    setValue(value);
  }, []);

  let extensions = [EditorView.lineWrapping];
  if (lint) {
    extensions.push(lintGutter());
  }
  switch (language) {
    case "javascript":
      extensions.push(langs.javascript({ jsx: true }));
      break;
    case "yaml":
      extensions.push(langs.yaml());
      if (lint) extensions.push(yamlLinter);
      break;
    case "json":
      extensions.push(langs.json());
      if (lint) extensions.push(linter(jsonParseLinter()));
      break;
    case "shell":
      extensions.push(langs.shell());
      break;
    case "markdown":
      extensions.push(langs.markdown());
      break;
    default:
      extensions.push(langs.javascript({ jsx: true }));
      break;
  }

  if (!canShow) {
    return null;
  }

  // Functions //
  function focusOnEditor(): void {
    if (editorRef && !editorRef.current?.view?.hasFocus) {
      editorRef.current?.view?.focus();
    }
  }

  return (
    <CodeMirror
      ref={editorRef}
      {...options}
      value={value}
      className={clsx("outline-none focus:ring-1 cursor-text", noBorder ? "" : "border rounded px-2 py-2")}
      extensions={extensions}
      onChange={onChange}
      onClick={focusOnEditor}
      minHeight={minHeight || "250px"}
      autoFocus
      theme={Theme.theme === "dark" ? githubDark : githubLight}
    />
  );
});
