import React, { useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { observer } from "mobx-react-lite";
import { AccountChange } from "./AccountChange";
import { ParsedLocation } from "../../../mobxStores/parsedLocation/parsedLocation";
import { ConsoleContext } from "../../../mobxStores/consoleContext/consoleContext";
import { BillingContext } from "../../../mobxStores/billingContext/billingContext";
import { SidebarContext } from "../../../mobxStores/uiData/sidebarContext";
import {
  OrgIcon,
  ImageIcon,
  IdentityIcon,
  VolumeSetIcon,
  LocationIcon,
  DomainIcon,
  AgentIcon,
  SecretIcon,
  CloudAccountIcon,
  LogsIcon,
  UsageIcon,
  QuotaIcon,
  UserIcon,
  GroupIcon,
  ServiceAccountIcon,
  PolicyIcon,
  AuditTrailIcon,
  AuditContextIcon,
  AccountIcon,
  InvoiceIcon,
  PaymentMethodIcon,
  KubernetesIcon,
  WorkloadIconAdd,
  WorkloadIcon,
} from "../../Icons";
import clsx from "clsx";
import { UIData } from "../../../mobxStores/uiData/uiData";
import { OrgMenuLink } from "./orgMenuLink";
import { MetricsMenuLink } from "./metricsMenuLink";
import { MenuList, MenuLink } from "./menu";
import { GVCMenuLink } from "./gvcMenuLink";
import { NGButton } from "../../../newcomponents/button/Button";
import { UserData } from "../../../mobxStores/userData/userData";
import { useLocation, useNavigate } from "react-router-dom";
import { IPSET_ENABLED } from "../../../envVariables";
import { CustomScrollbars } from "../../Scrollbar";

const MenuLeftRaw: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { org, gvc } = ConsoleContext;
  const { isServicesExpanded, isAccessControlExpanded } = SidebarContext;
  const {
    AccountUUID,
    canViewOrgs,
    canViewUsers,
    canViewUsage,
    canViewPaymentMethods,
    canViewInvoices,
  } = BillingContext;

  const scrollbarRef = useRef<Scrollbars>(null);

  useEffect(() => {
    scrollbarRef.current?.scrollTop(SidebarContext.scrollTop);
  }, []);

  return (
    <div className={clsx("sidebar-container", { collapsed: UIData.isNavCollapsed })}>
      <CustomScrollbars
        autoHide
        ref={scrollbarRef}
        className="sidebar-scrollbar"
        onUpdate={(values) => SidebarContext.setScrollPosition(values.scrollTop)}
        thumbStyle={{ width: UIData.isNavCollapsed ? 0 : "auto" }}
      >
        <div className="sidebar">
          <div className="sidebar-console">
            {ParsedLocation.dashboard === "billing" && (
              <MenuList>
                <AccountChange />
                {canViewOrgs ? (
                  <MenuLink url={`/billing/account/${AccountUUID}/orgs`} Icon={OrgIcon} label={"Orgs"} />
                ) : null}
                {canViewInvoices ? (
                  <MenuLink url={`/billing/account/${AccountUUID}/invoices`} Icon={InvoiceIcon} label={"Invoices"} />
                ) : null}
                {canViewPaymentMethods ? (
                  <MenuLink
                    url={`/billing/account/${AccountUUID}/payment-methods`}
                    Icon={PaymentMethodIcon}
                    label={"Payment Method"}
                  />
                ) : null}
                {canViewUsers ? (
                  <MenuLink url={`/billing/account/${AccountUUID}/users`} Icon={AccountIcon} label={"Users"} />
                ) : null}
                {canViewUsage ? (
                  <MenuLink url={`/billing/account/${AccountUUID}/usage`} Icon={UsageIcon} label={"Cost & Usage"} />
                ) : null}
              </MenuList>
            )}
            {ParsedLocation.dashboard === "console" && (
              <>
                <MenuList title={"Manage GVC"}>
                  <GVCMenuLink />
                  {!!gvc && (
                    <>
                      <MenuLink
                        url={`/console/org/${org}/gvc/${gvc}/workload`}
                        Icon={WorkloadIcon}
                        label={"Workloads"}
                      />
                      {!UserData.isLoadingHasWorkload &&
                        !UserData.hasWorkload &&
                        !pathname.includes("/workload/-create") && (
                          <NGButton
                            variant="action"
                            style={{ width: "100%", paddingInline: 8, justifyContent: "start" }}
                            renderIcon={(hover, props) => (
                              <WorkloadIconAdd size={21} fill="var(--color-white)" {...props} />
                            )}
                            onClick={() => navigate(`/console/org/${org}/gvc/${gvc}/workload/-create`)}
                            iconPosition="left"
                            children={!UIData.isNavCollapsed && "Create a Workload"}
                          />
                        )}
                      <MenuLink
                        url={`/console/org/${org}/gvc/${gvc}/identity`}
                        Icon={IdentityIcon}
                        label={"Identities"}
                      />
                      <MenuLink
                        url={`/console/org/${org}/gvc/${gvc}/volumeset`}
                        Icon={VolumeSetIcon}
                        label={"Volume Sets"}
                      />
                    </>
                  )}
                </MenuList>
                <div className="menu-list-divider" />
                <MenuList title="Manage ORG">
                  <OrgMenuLink />
                  <MenuLink
                    url={`/console/org/${org}/repository`}
                    Icon={ImageIcon}
                    label={"Images"}
                    customIsActive={(pathname) => {
                      return (
                        pathname.startsWith(`/console/org/${org}/repository`) ||
                        pathname.startsWith(`/console/org/${org}/image`)
                      );
                    }}
                  />
                  <MenuLink url={`/console/org/${org}/location`} Icon={LocationIcon} label={"Locations"} />
                  <MenuLink url={`/console/org/${org}/domain`} Icon={DomainIcon} label={"Domains"} />
                  {IPSET_ENABLED ? (
                    <MenuLink url={`/console/org/${org}/ipset`} Icon={ImageIcon} label={"IP Sets"} />
                  ) : null}
                  <MenuLink url={`/console/org/${org}/agent`} Icon={AgentIcon} label={"Agents"} />
                  <MenuLink url={`/console/org/${org}/secret`} Icon={SecretIcon} label={"Secrets"} />
                  <MenuLink url={`/console/org/${org}/cloudaccount`} Icon={CloudAccountIcon} label={"Cloud Accounts"} />
                </MenuList>
                <div className="menu-list-divider" />
                <MenuList title="Bundles">
                  <MenuLink url={`/console/org/${org}/marketplace/release`} Icon={WorkloadIcon} label={"Apps"} />
                  <MenuLink
                    url={`/console/org/${org}/marketplace/template`}
                    Icon={WorkloadIcon}
                    label={"Marketplace"}
                  />
                  <MenuLink url={`/console/org/${org}/helmrelease`} Icon={WorkloadIcon} label={"Helm Releases"} />
                </MenuList>
                <div className="menu-list-divider" />
                <MenuList title="Analyze">
                  <MenuLink url={`/console/org/${org}/logs`} Icon={LogsIcon} label={"Logs"} />
                  <MetricsMenuLink />
                  <MenuLink url={`/console/org/${org}/usage`} Icon={UsageIcon} label={"Cost & Usage"} />
                  <MenuLink url={`/console/org/${org}/quota`} Icon={QuotaIcon} label={"Quotas"} />
                </MenuList>
                <div className="menu-list-divider" />
                <MenuList title="Services">
                  <MenuLink url={`/console/org/${org}/mk8s`} Icon={KubernetesIcon} label={`Kubernetes`} />
                </MenuList>
                <div className="menu-list-divider" />
                <MenuList
                  isOpen={isAccessControlExpanded}
                  isExpandable={true}
                  title="Access Control"
                  onClick={() => SidebarContext.toggleCategoryExpansion("ACCESS_CONTROL")}
                >
                  <MenuLink url={`/console/org/${org}/user`} Icon={UserIcon} label={"Users"} />
                  <MenuLink url={`/console/org/${org}/group`} Icon={GroupIcon} label={"Groups"} />
                  <MenuLink
                    url={`/console/org/${org}/serviceaccount`}
                    Icon={ServiceAccountIcon}
                    label={"Service Accounts"}
                  />
                  <MenuLink url={`/console/org/${org}/policy`} Icon={PolicyIcon} label={"Policies"} />
                  <MenuLink url={`/console/org/${org}/audittrail`} Icon={AuditTrailIcon} label={"Audit Trail"} />
                  <MenuLink url={`/console/org/${org}/auditctx`} Icon={AuditContextIcon} label={"Audit Contexts"} />
                </MenuList>
              </>
            )}
          </div>
        </div>
      </CustomScrollbars>
    </div>
  );
};

export const MenuLeft = observer(MenuLeftRaw);
