import * as React from "react";
import clsx from "clsx";
import { VolumesTableLabel } from "./volumesTableLabel";
import { VolumeSetMobx } from "../../mst/kinds/volumeset";
import { Tag } from "../../components/tag/tag";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { CommandModal_RestoreVolume, getSnapshotNames } from "./commands/commandModal_restoreVolume";
import { CommandModal_ExpandVolume } from "./commands/commandModal_expandVolume";
import { CommandModal_DeleteVolumeSnapshot } from "./commands/commandModal_deleteVolumeSnapshot";
import { CommandModal_DeleteVolume } from "./commands/commandModal_deleteVolume";
import { CommandModal_CreateVolumeSnapshot } from "./commands/commandModal_createVolumeSnapshot";
import { CommandLifecycleStage, VolumeSetCommandTypes } from "../../mst/kinds/command";
import { Button as AntButton, Dropdown, Space } from "antd";
import { VolumeSnapshotsTableLabel } from "./volumeSnapshotsTableLabel";
import { NGLabel } from "../../newcomponents/text/label";
import { NGCheckbox } from "../../newcomponents/checkbox";
import { NGLabelText } from "../../newcomponents/text/labelText";
import { request, resourceLink } from "../../services/cpln";
import { GVCMobx } from "../../mst/kinds/gvc";
import { ConsoleContext } from "../../mobxStores/consoleContext/consoleContext";
import { Tooltip } from "../../components/Tooltip";
import { DateString } from "../../components/dateString";
import { formatStrings } from "../../utils/time";
import { useCommandsContext } from "../../components/detail/commandsContext";

const STORAGE_KEY_VIEW_VOLUME_SNAPSHOT = "volumeset-volumes-view-snapshot";

interface Props {
  volumeSet: VolumeSetMobx;
}

const VolumesRaw: React.FC<Props> = ({ volumeSet }) => {
  const [gvcLocations, setGVCLocations] = React.useState<string[]>([]);

  const [commandLocation, setCommandLocation] = React.useState("");
  const [commandVolumeIndex, setCommandVolumeIndex] = React.useState(0);
  const [commandStorageCapacity, setCommandStorageCapacity] = React.useState(0);
  const [commandSnapshotName, setCommandSnapshotName] = React.useState("");
  const [commandType, setCommandType] = React.useState<VolumeSetCommandTypes | null>(null);

  const { commands, fetchCommands } = useCommandsContext();

  function getDefaultViewVolumeSnapshots() {
    let value = false;
    const localValue = window.localStorage.getItem(STORAGE_KEY_VIEW_VOLUME_SNAPSHOT);
    if (localValue && localValue === "true") {
      value = true;
    }
    return value;
  }
  const [viewVolumeSnapshots, setViewVolumeSnapshots] = React.useState(getDefaultViewVolumeSnapshots());

  React.useEffect(() => {
    fetchGVCLocations();
  }, []);

  async function fetchGVCLocations() {
    try {
      const { data } = await request({ url: resourceLink("gvc", ConsoleContext.gvc!) });
      const gvc: GVCMobx = data;
      const locationNames = gvc.spec.staticPlacement.locationLinks.map((link) => link.split("/").pop()!);
      setGVCLocations(locationNames);
    } catch (e) {
      setGVCLocations([]);
    }
  }

  React.useEffect(() => {
    window.localStorage.setItem(STORAGE_KEY_VIEW_VOLUME_SNAPSHOT, String(viewVolumeSnapshots));
  }, [viewVolumeSnapshots]);

  async function handleActionsClick({ key, volume, location, name = "" }: any) {
    setCommandLocation(location);
    setCommandVolumeIndex(volume.index);
    setCommandStorageCapacity(volume.currentSize);
    setCommandSnapshotName(name);
    setCommandType(key);
  }

  function getVolumeStatus(commandType: VolumeSetCommandTypes | undefined, snapshotName?: string) {
    switch (commandType) {
      case "restoreVolume":
        return snapshotName ? `Restoring Volume to ${snapshotName}` : "Restoring Volume";
      case "expandVolume":
        return "Expanding Volume";
      case "deleteVolume":
        return "Deleting Volume";
      default:
        return null;
    }
  }

  function getSnapshotStatus(commandType: VolumeSetCommandTypes | undefined, lifecycleStage?: CommandLifecycleStage) {
    if (lifecycleStage === "completed") return null;
    // TODO test failing commands and update logic for them
    switch (commandType) {
      case "restoreVolume":
        return "Restoring Volume";
      case "createVolumeSnapshot":
        return "Creating Snapshot";
      case "expandVolume":
        return "Expanding Volume";
      case "deleteVolumeSnapshot":
        return "Deleting Snapshot";
      default:
        return null;
    }
  }

  return (
    <div>
      <NGLabel>Volumes</NGLabel>
      <NGCheckbox
        id={"view-snapshots"}
        className="mb-4"
        checked={viewVolumeSnapshots}
        onChange={(value) => setViewVolumeSnapshots(value)}
      >
        <NGLabelText>View Snapshots</NGLabelText>
      </NGCheckbox>
      {volumeSet.status.locations.length < 1
        ? "No volumes are created yet"
        : volumeSet.status.locations.map(({ name: location, volumes }) => {
            const filteredVolumes = volumes.filter(
              (v) =>
                gvcLocations.includes(location) ||
                v.volumeSnapshots.length > 0 ||
                !!v.storageDeviceId ||
                !!v.currentSize,
            );
            return (
              <div key={location} className="mb-6">
                <NGLabel classID="mb-2">{location}</NGLabel>
                {filteredVolumes.length < 1 ? (
                  <div>No volumes found</div>
                ) : (
                  <div className="volumes-table">
                    <VolumesTableLabel />
                    {filteredVolumes
                      .slice()
                      .sort((va, vb) => va.index - vb.index)
                      .map((volume, volumeIndex) => {
                        const activeCommands = commands.filter(
                          (c) =>
                            c.spec.location === location &&
                            Number(c.spec.volumeIndex) === volume.index &&
                            c.lifecycleStage &&
                            !["completed", "failed"].includes(c.lifecycleStage),
                        );

                        function mergeNewSnapshots(existingSnapshots: any) {
                          return existingSnapshots.concat(
                            activeCommands
                              .filter(
                                (c) =>
                                  c.type === "createVolumeSnapshot" &&
                                  !existingSnapshots.some((snapshot: any) => snapshot.name === c.spec.snapshotName),
                              )
                              .map((c) => ({
                                name: c.spec.snapshotName,
                                created: c.created,
                                tags: c.spec.snapshotTags,
                                expires: c.spec.snapshotExpirationDate,
                                id: "",
                                size: 0,
                              })),
                          );
                        }

                        const snapshots = mergeNewSnapshots(volume.volumeSnapshots);

                        const activeDeleteCommand = activeCommands.find(
                          (ac) => (ac?.type as VolumeSetCommandTypes) === "deleteVolume",
                        );

                        const activeExpandCommand = activeCommands.find(
                          (ac) => (ac?.type as VolumeSetCommandTypes) === "expandVolume",
                        );

                        return (
                          <>
                            <div
                              key={`${volumeIndex}-${volume.index}`}
                              className={clsx("px-4 py-2 flex flex-col border-b")}
                            >
                              <div className="flex items-center">
                                <div className="w-4/12 flex items-center gap-1">
                                  <span>{volume.index}</span>
                                  {activeCommands.some((ac) =>
                                    ["restoreVolume", "expandVolume", "deleteVolume"].includes(ac.type),
                                  ) && <span style={{ opacity: "0.25" }}>|</span>}
                                  {
                                    activeCommands
                                      .filter((ac) =>
                                        ["restoreVolume", "expandVolume", "deleteVolume"].includes(ac.type),
                                      )
                                      .map((ac) => (
                                        <span style={{ color: "orange" }}>
                                          {getVolumeStatus(ac?.type as VolumeSetCommandTypes, ac?.spec.snapshotName)}
                                        </span>
                                      ))[0]
                                  }
                                </div>
                                <div className={`w-2/12 ${volume.lifecycle === "bound" ? "color-action" : ""}`}>
                                  {volume.lifecycle
                                    ? volume.lifecycle?.slice(0, 1).toUpperCase() + volume.lifecycle?.slice(1)
                                    : "-"}
                                </div>
                                <div className={`w-2/12`}>
                                  {activeDeleteCommand ? (
                                    "-"
                                  ) : volume.lifecycle === "bound" && volumeSet.status.usedByWorkload ? (
                                    <Link
                                      className="color-link"
                                      to={`/console${
                                        volumeSet.status.usedByWorkload
                                      }/deployment/${location}/replica/${encodeURI(
                                        `${volumeSet.status.usedByWorkload.split("/")[6]}-${volume.index}`,
                                      )}/replicaindex/${volume.index}`}
                                    >{`${volumeSet.status.usedByWorkload.split("/")[6]}-${volume.index}`}</Link>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="w-3/12">
                                  {activeDeleteCommand
                                    ? "-"
                                    : activeExpandCommand
                                    ? `${volume.currentSize}GiB → Expanding to ${activeExpandCommand.spec.newStorageCapacity}GiB`
                                    : `${volume.currentSize}GiB`}
                                </div>
                                <div className="w-1/12 flex justify-end">
                                  <Dropdown
                                    trigger={["click"]}
                                    menu={{
                                      onClick: ({ key }) => handleActionsClick({ key, volume, location }),
                                      items: [
                                        {
                                          key: "createVolumeSnapshot",
                                          label: `Create Volume Snapshot`,
                                        },
                                        {
                                          key: "restoreVolume",
                                          label: "Restore Volume To Snapshot",
                                          disabled: !getSnapshotNames(volumeSet, location, volume.index).length,
                                        },
                                        {
                                          key: "expandVolume",
                                          label: `Expand Volume`,
                                        },
                                        {
                                          key: "deleteVolume",
                                          label: `Delete Volume`,
                                          danger: true,
                                        },
                                      ],
                                    }}
                                  >
                                    <AntButton data-testid="detail-actions" type={"primary"} size="small">
                                      <Space>
                                        Actions
                                        <DownOutlined />
                                      </Space>
                                    </AntButton>
                                  </Dropdown>
                                </div>
                              </div>
                              {viewVolumeSnapshots && snapshots.length > 0 ? (
                                <div className="px-4 my-2">
                                  <NGLabel className="pl-4">Snapshots</NGLabel>
                                  <VolumeSnapshotsTableLabel />
                                  {snapshots.map((snapshot: any, snapshotIndex: number) => {
                                    const recentSnapshotCommand = activeCommands
                                      .filter((c) => c.lifecycleStage !== "failed")
                                      .find((c) => c.spec.snapshotName === snapshot.name);
                                    const snapshotStatus = getSnapshotStatus(
                                      recentSnapshotCommand?.type as VolumeSetCommandTypes,
                                      recentSnapshotCommand?.lifecycleStage,
                                    );

                                    return (
                                      <div key={snapshot.name} className={clsx("border-b")}>
                                        <div className={`flex items-center px-4 py-2`}>
                                          <div className="w-3/12 flex items-center gap-1 pr-2">
                                            <span className="truncate">{snapshot.name}</span>
                                            {snapshotStatus ? <span style={{ opacity: "0.25" }}>|</span> : null}
                                            <Tooltip
                                              title={
                                                !recentSnapshotCommand?.lifecycleStage
                                                  ? ""
                                                  : recentSnapshotCommand?.lifecycleStage === "completed"
                                                  ? ""
                                                  : `${recentSnapshotCommand?.status.stage}`
                                              }
                                              mouseEnterDelay={
                                                !recentSnapshotCommand?.lifecycleStage ||
                                                recentSnapshotCommand?.lifecycleStage === "completed"
                                                  ? 99999
                                                  : undefined
                                              }
                                            >
                                              <span style={{ color: "orange" }}>{snapshotStatus}</span>
                                            </Tooltip>
                                          </div>
                                          <div className="w-2/12 pr-2">
                                            {(recentSnapshotCommand?.type === "createVolumeSnapshot" ||
                                              recentSnapshotCommand?.type === "deleteVolumeSnapshot") &&
                                            recentSnapshotCommand?.lifecycleStage !== "completed"
                                              ? "-"
                                              : `${snapshot.size}GiB`}
                                          </div>
                                          <div className="w-3/12 pr-2">
                                            <DateString iso={snapshot.expires} utc format={formatStrings.log} />
                                          </div>
                                          <div className="w-2/12 pr-2">
                                            {(recentSnapshotCommand?.type === "createVolumeSnapshot" ||
                                              recentSnapshotCommand?.type === "deleteVolumeSnapshot") &&
                                            recentSnapshotCommand?.lifecycleStage !== "completed" ? (
                                              "-"
                                            ) : snapshot.tags.length > 0 ? (
                                              <div className={`flex items-center flex-grow`} style={{ minWidth: 300 }}>
                                                {snapshot.tags
                                                  .slice(0, 2)
                                                  .map((item: any) =>
                                                    Object.entries(item).map(([key, value]: any) => (
                                                      <Tag
                                                        key={key + value}
                                                        name={key}
                                                        value={value}
                                                        className="mr-1"
                                                      />
                                                    )),
                                                  )}
                                                {snapshot.tags.length > 3 && (
                                                  <Tooltip
                                                    title={
                                                      <div className="w-full flex flex-wrap">
                                                        {snapshot.tags
                                                          .slice(2)
                                                          .map((item: any) =>
                                                            Object.entries(item).map(([key, value]: any) => (
                                                              <Tag
                                                                key={key + value}
                                                                className="m-1"
                                                                name={key}
                                                                value={value}
                                                              />
                                                            )),
                                                          )}
                                                      </div>
                                                    }
                                                  >
                                                    <div className="cursor-pointer color-link-hover">
                                                      +{snapshot.tags.length - 2}
                                                    </div>
                                                  </Tooltip>
                                                )}
                                              </div>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                          <div className="w-2/12 pr-2 flex justify-end">
                                            <Dropdown
                                              className={
                                                (recentSnapshotCommand?.type === "createVolumeSnapshot" ||
                                                  recentSnapshotCommand?.type === "deleteVolumeSnapshot") &&
                                                recentSnapshotCommand?.lifecycleStage !== "completed"
                                                  ? "hidden"
                                                  : undefined
                                              }
                                              trigger={["click"]}
                                              menu={{
                                                onClick: ({ key }) =>
                                                  handleActionsClick({
                                                    key,
                                                    volume,
                                                    location,
                                                    name: snapshot.name,
                                                  }),
                                                items: [
                                                  {
                                                    key: "restoreVolume",
                                                    label: "Restore Volume To Snapshot",
                                                  },
                                                  {
                                                    key: "deleteVolumeSnapshot",
                                                    label: "Delete Volume Snapshot",
                                                    danger: true,
                                                  },
                                                ],
                                              }}
                                            >
                                              <AntButton data-testid="detail-actions" type={"primary"} size="small">
                                                <Space>
                                                  Actions
                                                  <DownOutlined />
                                                </Space>
                                              </AntButton>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
      {commandType === "createVolumeSnapshot" ? (
        <CommandModal_CreateVolumeSnapshot
          onClose={() => {
            setCommandType(null);
          }}
          onDone={() => {
            fetchCommands();
            setCommandType(null);
          }}
          visible={commandType === "createVolumeSnapshot"}
          volumeSet={volumeSet}
          initialLocation={commandLocation}
          initialVolumeIndex={String(commandVolumeIndex)}
        />
      ) : null}
      {commandType === "expandVolume" ? (
        <CommandModal_ExpandVolume
          onClose={() => {
            setCommandType(null);
          }}
          onDone={() => {
            fetchCommands();
            setCommandType(null);
          }}
          visible={commandType === "expandVolume"}
          volumeSet={volumeSet}
          initialLocation={commandLocation}
          initialVolumeIndex={String(commandVolumeIndex)}
          initialStorageCapacity={commandStorageCapacity}
        />
      ) : null}
      {commandType === "restoreVolume" ? (
        <CommandModal_RestoreVolume
          onClose={() => {
            setCommandType(null);
          }}
          onDone={() => {
            fetchCommands();
            setCommandType(null);
          }}
          visible={commandType === "restoreVolume"}
          volumeSet={volumeSet}
          initialLocation={commandLocation}
          initialVolumeIndex={String(commandVolumeIndex)}
          initialSnapshotName={commandSnapshotName}
        />
      ) : null}
      {commandType === "deleteVolume" ? (
        <CommandModal_DeleteVolume
          onClose={() => {
            setCommandType(null);
          }}
          onDone={() => {
            fetchCommands();
            setCommandType(null);
          }}
          visible={commandType === "deleteVolume"}
          volumeSet={volumeSet}
          initialLocation={commandLocation}
          initialVolumeIndex={String(commandVolumeIndex)}
        />
      ) : null}
      {commandType === "deleteVolumeSnapshot" ? (
        <CommandModal_DeleteVolumeSnapshot
          onClose={() => {
            setCommandType(null);
          }}
          onDone={() => {
            fetchCommands();
            setCommandType(null);
          }}
          visible={commandType === "deleteVolumeSnapshot"}
          volumeSet={volumeSet}
          initialLocation={commandLocation}
          initialVolumeIndex={String(commandVolumeIndex)}
          initialSnapshotName={commandSnapshotName}
        />
      ) : null}
    </div>
  );
};

export const Volumes = observer(VolumesRaw);
