import * as React from "react";
import { observer } from "mobx-react-lite";
import { mk8sDryRun } from "../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../mst/stores/mk8s.draft";
import { homeLink, request } from "../../../../services/cpln";
import { DryRunAlert } from "../../dryRunAlert";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { NGInput } from "../../../../newcomponents/input/input";
import { NGRadioGroup } from "../../../../newcomponents/radioGroup";
import { NGSelect } from "../../../../newcomponents/select/ngselect";
import { NGButton } from "../../../../newcomponents/button/Button";
import { mk8sMobx } from "../../../../mst/kinds/mk8s/mk8s";
import { NGProviderBrowser } from "../../ngProviderBrowser";
import { NGInputListMst } from "../../../../newcomponents/inputList/inputListMst";
import { NGFormElement } from "../../../../newcomponents/ngformelement/ngformelement";
import { NGFormLabel } from "../../../../newcomponents/text/formLabel";
import { AwsSsmIcon } from "../../awsSsmIcon";
import { getDryRunErrorText } from "../../getDryRunErrorText";

type RouteParams = "index";

interface Props {
  mk8s: mk8sMobx;
  mk8sDraft: Mk8sDraftMobx;
}
const ProviderAWSNodePoolRaw: React.FC<Props> = ({ mk8s, mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");

  const [recommendedAmis, setRecommendedAmis] = React.useState([]);

  React.useEffect(() => {
    fetchRecommendedAmis();
  }, []);

  async function fetchRecommendedAmis() {
    try {
      const { data } = await request({
        service: "api",
        url: homeLink("mk8s") + "/-discover",
        method: "post",
        body: {
          objectType: "recommended-amis",
          cluster: mk8sDraft.asObject,
        },
      });
      const res = data.recommended.map((r: any) => ({ label: r.name, value: r.name }));
      setRecommendedAmis(res);
    } catch (e) {
      setRecommendedAmis([]);
    }
  }

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const a = mk8sDraft.provider_aws;
  const np = a.nodePools.find((np) => np.index === index);

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `aws.nodePools[${index}].`;

  const instanceTypesDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.nodePools[${index}].instanceTypes`,
    paths: np.instanceTypes.items.map((_, idx) => `spec.provider.aws.nodePools[${index}].instanceTypes[${idx}]`),
  });
  const subnetIdsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.nodePools[${index}].subnetIds`,
    paths: np.subnetIds.items.map((_, idx) => `spec.provider.aws.nodePools[${index}].subnetIds[${idx}]`),
  });
  const extraSecurityGroupIdsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.nodePools[${index}].extraSecurityGroupIds`,
    paths: np.extraSecurityGroupIds.items.map(
      (_, idx) => `spec.provider.aws.nodePools[${index}].extraSecurityGroupIds[${idx}]`,
    ),
  });
  const labelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.nodePools[${index}].labels`,
    paths: np.labels.items.map((i) => `spec.provider.aws.nodePools[${index}].labels.${i.firstValue}`),
  });
  const taintsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.aws.nodePools[${index}].taints`,
    paths: [
      ...np.taints.items.map((_, idx) => `spec.provider.aws.nodePools[${index}].taints[${idx}]`),
      ...np.taints.items.map((_, idx) => `spec.provider.aws.nodePools[${index}].taints[${idx}].key`),
      ...np.taints.items.map((_, idx) => `spec.provider.aws.nodePools[${index}].taints[${idx}].value`),
      ...np.taints.items.map((_, idx) => `spec.provider.aws.nodePools[${index}].taints[${idx}].effect`),
    ],
  });

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!a.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.aws.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.aws.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGFormLabel
          name={np.overrideImage.type === "recommended" ? `${pre}image.recommended` : `${pre}image.exact`}
          invalid={np.overrideImage.type === "exact" && !np.overrideImage.exact.isValid}
        >
          Override Image
        </NGFormLabel>
        <NGRadioGroup
          options={[
            { label: "Recommended", value: "recommended" },
            { label: "Exact", value: "exact" },
          ]}
          value={np.overrideImage.type}
          onChange={(value) => np.overrideImage.setType(value as any)}
        />
        <div className="mb-4 flex items-start gap-2">
          {np.overrideImage.type === "recommended" ? (
            <NGSelect
              name={`${pre}image.recommended`}
              style={{ width: 450, minWidth: 450 }}
              value={np.overrideImage.recommended}
              onChange={np.overrideImage.setRecommended}
              allowEmpty
              options={recommendedAmis}
            />
          ) : np.overrideImage.type === "exact" ? (
            <NGInput
              name={`${pre}image.exact`}
              style={{ width: 450, minWidth: 450 }}
              value={np.overrideImage.exact.value}
              onChange={(e) => np.overrideImage.exact.setValue(e.target.value)}
            />
          ) : null}

          <DryRunAlert
            key={mk8sDryRun.key}
            canShow={true}
            dryRunResponse={mk8sDryRun.response}
            path={`spec.provider.aws.nodePools[${index}].overrideImage`}
            paths={[
              `spec.provider.aws.nodePools[${index}].overrideImage.exact`,
              `spec.provider.aws.nodePools[${index}].overrideImage.recommended`,
            ]}
          />
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}bootDiskSize`}
            label={np.bootDiskSize.label}
            value={np.bootDiskSize.value}
            onChange={np.bootDiskSize.setValue}
            required={np.bootDiskSize.isRequired}
            error={np.bootDiskSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.bootDiskSize.setValue}
              path={`spec.provider.aws.nodePools[${index}].bootDiskSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}onDemandBaseCapacity`}
            label={np.onDemandBaseCapacity.label}
            value={np.onDemandBaseCapacity.value}
            onChange={np.onDemandBaseCapacity.setValue}
            required={np.onDemandBaseCapacity.isRequired}
            error={np.onDemandBaseCapacity.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.onDemandBaseCapacity.setValue}
              path={`spec.provider.aws.nodePools[${index}].onDemandBaseCapacity`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}onDemandPercentageAboveBaseCapacity`}
            label={np.onDemandPercentageAboveBaseCapacity.label}
            value={np.onDemandPercentageAboveBaseCapacity.value}
            onChange={np.onDemandPercentageAboveBaseCapacity.setValue}
            required={np.onDemandPercentageAboveBaseCapacity.isRequired}
            error={np.onDemandPercentageAboveBaseCapacity.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.onDemandPercentageAboveBaseCapacity.setValue}
              path={`spec.provider.aws.nodePools[${index}].onDemandPercentageAboveBaseCapacity`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}spotAllocationStrategy`}
            as={"select"}
            label={np.spotAllocationStrategy.label}
            options={np.spotAllocationStrategy.options}
            value={np.spotAllocationStrategy.value}
            onChange={np.spotAllocationStrategy.setValue}
            required={true}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={true}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.spotAllocationStrategy.setValue}
              path={`spec.provider.aws.nodePools[${index}].spotAllocationStrategy`}
            />
          </div>
        </div>

        <NGInputListMst
          data={np.instanceTypes}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Instance Types"
          labelRender={() => {
            return (
              <>
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("instance-types")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (instanceTypesDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={instanceTypesDryRun.severity === "error"}
          error={instanceTypesDryRun.severity === "error" ? instanceTypesDryRun.message : ""}
          warning={instanceTypesDryRun.severity === "error" ? "" : instanceTypesDryRun.message}
        />
        <NGInputListMst
          data={np.subnetIds}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Subnet Ids"
          labelRender={() => {
            return (
              <>
                <AwsSsmIcon />
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("subnet-ids")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (subnetIdsDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={subnetIdsDryRun.severity === "error"}
          error={subnetIdsDryRun.severity === "error" ? subnetIdsDryRun.message : ""}
          warning={subnetIdsDryRun.severity === "error" ? "" : subnetIdsDryRun.message}
        />
        <NGInputListMst
          data={np.extraSecurityGroupIds}
          className="mb-8"
          styles={{ container: { width: 450, minWidth: 450 } }}
          label="Extra Security Group Ids"
          labelRender={() => {
            return (
              <>
                <AwsSsmIcon />
                <NGButton
                  className="ml-1"
                  size={"small"}
                  variant={"secondary"}
                  onClick={() => setBrowserKey("extra-security-group-ids")}
                >
                  Browse
                </NGButton>
              </>
            );
          }}
          firstInput={(i, index) => {
            let invalid = false;
            if (extraSecurityGroupIdsDryRun.message.split("[")[1]?.split("]")[0] === String(index)) {
              invalid = true;
            }

            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          invalid={extraSecurityGroupIdsDryRun.severity === "error"}
          error={extraSecurityGroupIdsDryRun.severity === "error" ? extraSecurityGroupIdsDryRun.message : ""}
          warning={extraSecurityGroupIdsDryRun.severity === "error" ? "" : extraSecurityGroupIdsDryRun.message}
        />
        <NGInputListMst
          data={np.labels}
          className="mb-8"
          label="Labels"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && labelsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Key"
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Value"
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={labelsDryRun.severity === "error"}
          error={labelsDryRun.severity === "error" ? labelsDryRun.message : ""}
          warning={labelsDryRun.severity === "error" ? "" : labelsDryRun.message}
        />
        <NGInputListMst
          data={np.taints}
          className="mb-8"
          label="Taints"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && taintsDryRun.message.includes(`[${index}].key`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Key"
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }
            if (i.secondValue && taintsDryRun.message.includes(`[${index}].value`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Value"
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          thirdInput={(i) => {
            let invalid = false;
            if (i.thirdValue && taintsDryRun.message.includes(`[${index}].effect`)) {
              invalid = true;
            }

            return (
              <NGSelect
                placeholder="Effect"
                invalid={invalid}
                value={i.thirdValue}
                onChange={(value) => i.setThirdValue(value)}
                options={[
                  { label: "NoSchedule", value: "NoSchedule" },
                  { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                  { label: "NoExecute", value: "NoExecute" },
                ]}
              />
            );
          }}
          invalid={taintsDryRun.severity === "error"}
          error={taintsDryRun.severity === "error" ? taintsDryRun.message : ""}
          warning={taintsDryRun.severity === "error" ? "" : taintsDryRun.message}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            a.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "instance-types" ? (
        <NGProviderBrowser
          width={1550}
          title="Browse AWS Instance Types"
          initialValue={np.instanceTypes.items.map((i) => i.firstValue)}
          distinctFilters={["CPU Type", "Cores", "Memory", "Hypervisor", "Arch", "GPU Type", "GPU Memory", "Storage"]}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: `instance-types-${mk8sDraft.provider_aws.region.value}`,
                cluster: mk8sDraft.asObject,
              },
            });
            let instanceTypes = data.data;
            instanceTypes = instanceTypes.sort((a: any, b: any) => {
              return a.price - b.price || a.cores - b.cores || a.memory - b.memory || a.instanceType - b.instanceType;
            });
            return instanceTypes;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (np.instanceTypes.items.some((i) => i.firstValue === value)) {
                continue;
              }
              np.instanceTypes.add();
              const i = np.instanceTypes.items[np.instanceTypes.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          rightAlign={["Cost per Month"]}
          labels={[
            "Name",
            "CPU Type",
            "Cores",
            "Memory",
            "GPU Type",
            "GPU Memory",
            "Storage",
            "Hypervisor",
            "Arch",
            "Cost per Month",
          ]}
          sortable
          getData={(item: any) => {
            return [
              item.instanceType,
              item.instanceType,
              item.cpuType,
              item.cores,
              [item.memory, `${item.memory}Gi`],
              [item.gpuType, item.gpuType || "-"],
              [item.gpuMemory, item.gpuMemory ? item.gpuMemory : "-"],
              item.storage,
              item.hypervisor,
              item.arch,
              [item.price, `$${item.price.toFixed(2)}`],
            ];
          }}
        />
      ) : null}
      {browserKey === "subnet-ids" ? (
        <NGProviderBrowser
          title="Browse AWS Subnets"
          initialValue={np.subnetIds.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-subnets",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.Subnets;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (np.subnetIds.items.some((i) => i.firstValue === value)) {
                continue;
              }
              np.subnetIds.add();
              const i = np.subnetIds.items[np.subnetIds.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Subnet ID", "Availability Zone", "CIDR"]}
          getData={(item: any) => {
            let name = "-";
            const nameTag = (item.Tags || []).find((t: any) => t.Key === "Name");
            if (nameTag) {
              name = nameTag.Value;
            }
            return [item.SubnetId, name, item.SubnetId, item.AvailabilityZone, item.CidrBlock];
          }}
        />
      ) : null}
      {browserKey === "extra-security-group-ids" ? (
        <NGProviderBrowser
          title="Browse AWS Security Groups"
          initialValue={np.extraSecurityGroupIds.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "ec2-securityGroups",
                cluster: mk8sDraft.asObject,
              },
            });
            return data.SecurityGroups;
          }}
          multipleChoice
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (np.extraSecurityGroupIds.items.some((i) => i.firstValue === value)) {
                continue;
              }
              np.extraSecurityGroupIds.add();
              const i = np.extraSecurityGroupIds.items[np.extraSecurityGroupIds.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          labels={["Name", "Description", "Security Group ID"]}
          getData={(item: any) => [item.GroupId, item.GroupName, item.Description, item.GroupId]}
        />
      ) : null}
    </>
  );
};

export const ProviderAWSNodePool = observer(ProviderAWSNodePoolRaw);
