import * as React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { mk8sDryRun } from "../../../../../mobxStores/dryRun/mk8s";
import { Mk8sDraftMobx } from "../../../../../mst/stores/mk8s.draft";
import { DryRunAlert } from "../../../dryRunAlert";
import { NGButton } from "../../../../../newcomponents/button/Button";
import { NGFormElement } from "../../../../../newcomponents/ngformelement/ngformelement";
import { MoreHorizontal } from "react-feather";
import { useNGFormContext } from "../../../../../reactContexts/ngFormContext";
import { NGInputListMst } from "../../../../../newcomponents/inputList/inputListMst";
import { NGInput } from "../../../../../newcomponents/input/input";
import { NGSelect } from "../../../../../newcomponents/select/ngselect";
import { NGProviderBrowser } from "../../../ngProviderBrowser";
import { homeLink, request } from "../../../../../services/cpln";
import { getDryRunErrorText } from "../../../getDryRunErrorText";

type RouteParams = "index";

interface Props {
  mk8sDraft: Mk8sDraftMobx;
}
const Mk8sCreateProviderTritonNodePoolRaw: React.FC<Props> = ({ mk8sDraft }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [browserKey, setBrowserKey] = React.useState("");
  const formData = useNGFormContext();

  const { index: indexStr } = useParams<RouteParams>();
  const index = Number(indexStr);

  const t = mk8sDraft.provider_triton;
  const np = t.nodePools.find((np) => np.index === index);

  const privateNetworkIdsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.nodePools[${index}].privateNetworkIds`,
    paths: np?.privateNetworkIds.items.map(
      (_, idx) => `spec.provider.triton.nodePools[${index}].privateNetworkIds[${idx}]`,
    ),
  });

  if (!np) {
    return <Navigate to={`${pathname.split("/-node-pools")[0]}/-node-pools`} />;
  }

  const pre = `triton.nodePools[${index}].`;

  const labelsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.nodePools[${index}].labels`,
    paths: np.labels.items.map((i) => `spec.provider.triton.nodePools[${index}].labels.${i.firstValue}`),
  });
  const taintsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.nodePools[${index}].taints`,
    paths: [
      ...np.taints.items.map((_, idx) => `spec.provider.triton.nodePools[${index}].taints[${idx}]`),
      ...np.taints.items.map((_, idx) => `spec.provider.triton.nodePools[${index}].taints[${idx}].key`),
      ...np.taints.items.map((_, idx) => `spec.provider.triton.nodePools[${index}].taints[${idx}].value`),
      ...np.taints.items.map((_, idx) => `spec.provider.triton.nodePools[${index}].taints[${idx}].effect`),
    ],
  });
  const tritonTagsDryRun = getDryRunErrorText({
    dryRunResponse: mk8sDryRun.response,
    path: `spec.provider.triton.nodePools[${index}].tritonTags`,
    paths: np.tritonTags.items.map((i) => `spec.provider.triton.nodePools[${index}].tritonTags.${i.firstValue}`),
  });

  return (
    <>
      <div>
        <NGFormElement
          name={`${pre}name`}
          label={np.name.label}
          value={np.name.value}
          onChange={np.name.setValue}
          required
          error={!t.isNodePoolNameValid(np.index) ? "Node pool names must be unique" : ""}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}packageId`}
            label={np.packageId.label}
            value={np.packageId.value}
            onChange={np.packageId.setValue}
            required={np.packageId.isRequired}
            error={np.packageId.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("packages"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}packageId`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.packageId.setValue}
              path={`spec.provider.triton.nodePools[${index}].packageId`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}overrideImageId`}
            label={np.overrideImageId.label}
            value={np.overrideImageId.value}
            onChange={np.overrideImageId.setValue}
            required={np.overrideImageId.isRequired}
            error={np.overrideImageId.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("images"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}overrideImageId`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.overrideImageId.setValue}
              path={`spec.provider.triton.nodePools[${index}].overrideImageId`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}publicNetworkId`}
            label={np.publicNetworkId.label}
            value={np.publicNetworkId.value}
            onChange={np.publicNetworkId.setValue}
            required={np.publicNetworkId.isRequired}
            error={np.publicNetworkId.error}
            innerButtons={[
              { title: "Browse", onClick: () => setBrowserKey("public-networks"), render: () => <MoreHorizontal /> },
            ]}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}publicNetworkId`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.publicNetworkId.setValue}
              path={`spec.provider.triton.nodePools[${index}].publicNetworkId`}
            />
          </div>
        </div>

        <NGInputListMst
          data={np.privateNetworkIds}
          styles={{ container: { width: 450 } }}
          labelRender={() => {
            return (
              <NGButton
                className="ml-1"
                size={"small"}
                variant={"secondary"}
                onClick={() => setBrowserKey("private-networks")}
              >
                Browse
              </NGButton>
            );
          }}
          firstInput={(i) => {
            let invalid = false;
            if (i.firstValue && privateNetworkIdsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }
            return <NGInput invalid={invalid} value={i.firstValue} onChange={(e) => i.setFirstValue(e.target.value)} />;
          }}
          label="Private Network Ids"
          invalid={privateNetworkIdsDryRun.severity === "error"}
          error={privateNetworkIdsDryRun.severity === "error" ? privateNetworkIdsDryRun.message : ""}
          warning={privateNetworkIdsDryRun.severity === "error" ? "" : privateNetworkIdsDryRun.message}
        />

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}minSize`}
            label={np.minSize.label}
            value={np.minSize.value}
            onChange={np.minSize.setValue}
            required={np.minSize.isRequired}
            error={np.minSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}minSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.minSize.setValue}
              path={`spec.provider.triton.nodePools[${index}].minSize`}
            />
          </div>
        </div>

        <div className="flex gap-2 items-start">
          <NGFormElement
            name={`${pre}maxSize`}
            label={np.maxSize.label}
            value={np.maxSize.value}
            onChange={np.maxSize.setValue}
            required={np.maxSize.isRequired}
            error={np.maxSize.error}
          />
          <div className="mt-6">
            <DryRunAlert
              key={mk8sDryRun.key}
              canShow={formData.get(`${pre}maxSize`).touched}
              dryRunResponse={mk8sDryRun.response}
              onFix={np.maxSize.setValue}
              path={`spec.provider.triton.nodePools[${index}].maxSize`}
            />
          </div>
        </div>
        <NGInputListMst
          data={np.labels}
          className="mb-8"
          label="Labels"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && labelsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Key"
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Value"
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={labelsDryRun.severity === "error"}
          error={labelsDryRun.severity === "error" ? labelsDryRun.message : ""}
          warning={labelsDryRun.severity === "error" ? "" : labelsDryRun.message}
        />
        <NGInputListMst
          data={np.taints}
          className="mb-8"
          label="Taints"
          firstInput={(i, index) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && taintsDryRun.message.includes(`[${index}].key`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Key"
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }
            if (i.secondValue && taintsDryRun.message.includes(`[${index}].value`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Value"
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          thirdInput={(i) => {
            let invalid = false;
            if (i.thirdValue && taintsDryRun.message.includes(`[${index}].effect`)) {
              invalid = true;
            }

            return (
              <NGSelect
                placeholder="Effect"
                invalid={invalid}
                value={i.thirdValue}
                onChange={(value) => i.setThirdValue(value)}
                options={[
                  { label: "NoSchedule", value: "NoSchedule" },
                  { label: "PreferNoSchedule", value: "PreferNoSchedule" },
                  { label: "NoExecute", value: "NoExecute" },
                ]}
              />
            );
          }}
          invalid={taintsDryRun.severity === "error"}
          error={taintsDryRun.severity === "error" ? taintsDryRun.message : ""}
          warning={taintsDryRun.severity === "error" ? "" : taintsDryRun.message}
        />
        <NGInputListMst
          data={np.tritonTags}
          className="mb-8"
          label="Triton Tags"
          firstInput={(i) => {
            let invalid = false;
            if (!i.firstValue) {
              invalid = true;
            }
            if (i.firstValue && tritonTagsDryRun.message.includes(`.${i.firstValue}`)) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Key"
                required
                invalid={invalid}
                value={i.firstValue}
                onChange={(e) => i.setFirstValue(e.target.value)}
              />
            );
          }}
          secondInput={(i) => {
            let invalid = false;
            if (!i.secondValue) {
              invalid = true;
            }

            return (
              <NGInput
                placeholder="Value"
                required
                invalid={invalid}
                value={i.secondValue}
                onChange={(e) => i.setSecondValue(e.target.value)}
              />
            );
          }}
          invalid={tritonTagsDryRun.severity === "error"}
          error={tritonTagsDryRun.severity === "error" ? tritonTagsDryRun.message : ""}
          warning={tritonTagsDryRun.severity === "error" ? "" : tritonTagsDryRun.message}
        />
        <NGButton
          style={{ width: 220 }}
          variant={"danger"}
          outlined
          onClick={() => {
            t.removeNodePoolAt(np.index);
            navigate(`${pathname.split("/-node-pools")[0]}/-node-pools`);
          }}
        >
          Delete Node Pool
        </NGButton>
      </div>
      {browserKey === "public-networks" ? (
        <NGProviderBrowser
          title="Browse Triton Public Networks"
          initialValue={np.publicNetworkId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "public-networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => {
            np.publicNetworkId.setValue(value as string);
            formData.set(`${pre}publicNetworkId`, { touched: true });
          }}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Description", "Subnet", "VLAN Id"]}
          getData={(item: any) => [item.id, item.name, item.id, item.description, item.subnet, item.vlan_id]}
        />
      ) : null}
      {browserKey === "private-networks" ? (
        <NGProviderBrowser
          title="Browse Triton Private Networks"
          initialValue={np.privateNetworkIds.items.map((i) => i.firstValue)}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "private-networks",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(_value) => {
            const values: string[] = _value as any;
            for (const value of values) {
              if (np.privateNetworkIds.items.some((i) => i.firstValue === value)) {
                continue;
              }
              np.privateNetworkIds.add();
              const i = np.privateNetworkIds.items[np.privateNetworkIds.items.length - 1];
              i.setFirstValue(value);
            }
          }}
          onClose={() => setBrowserKey("")}
          sortable
          multipleChoice
          labels={["Name", "Id", "Description", "Subnet", "VLAN Id"]}
          getData={(item: any) => [item.id, item.name, item.id, item.description, item.subnet, item.vlan_id]}
        />
      ) : null}
      {browserKey === "images" ? (
        <NGProviderBrowser
          title="Browse Triton Images"
          initialValue={np.overrideImageId.value}
          fetchData={async () => {
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "images",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => {
            np.overrideImageId.setValue(value as string);
            formData.set(`${pre}overrideImageId`, { touched: true });
          }}
          onClose={() => setBrowserKey("")}
          sortable
          distinctFilters={["Type", "Brand"]}
          filteredColumns={["Name"]}
          labels={["Name", "Id", "Size", "Type", "Brand", "Version"]}
          getData={(item: any) => [
            item.id,
            item.name,
            item.id,
            [item.image_size || 0, item.image_size ? `${item.image_size / 1024}Gi` : "-"],
            item.type,
            item.requirements?.brand,
            item.version,
          ]}
        />
      ) : null}
      {browserKey === "packages" ? (
        <NGProviderBrowser
          title="Browse Triton Packages"
          initialValue={np.packageId.value}
          fetchData={async () => {
            // TODOMK8S paginate
            const { data } = await request({
              service: "api",
              url: homeLink("mk8s") + "/-discover",
              method: "post",
              body: {
                objectType: "packages",
                cluster: mk8sDraft.asObject,
              },
            });
            return data;
          }}
          onOk={(value) => {
            np.packageId.setValue(value as string);
            formData.set(`${pre}packageId`, { touched: true });
          }}
          onClose={() => setBrowserKey("")}
          sortable
          labels={["Name", "Id", "Brand", "Version", "Cores", "Memory", "Disk", "Description"]}
          distinctFilters={["Brand", "Cores", "Memory", "Disk"]}
          getData={(item: any) => {
            const memoryGi = item.memory / 1024;
            const diskGi = item.disk / 1024;

            return [
              //
              item.id,
              item.name,
              item.id,
              item.brand,
              item.version,
              item.vcpus,
              [memoryGi, `${memoryGi}Gi`],
              [diskGi, `${diskGi}Gi`],
              item.description,
            ];
          }}
        />
      ) : null}
    </>
  );
};

export const Mk8sCreateProviderTritonNodePool = observer(Mk8sCreateProviderTritonNodePoolRaw);
